import React from 'react'
import PdfRender from '@react-pdf/renderer'
import messages from './messages'
import paths from './paths'
import Inputmask from 'inputmask'
import AutoNumeric from 'autonumeric'
import { Capacitor } from '@capacitor/core'
import arrSort from 'arr-sort'
import StringMask from 'string-mask'
import moment from 'moment'
import { lookup } from 'country-data-list'

export default {
  reducePicture: (image, resolution) => {
    return new Promise((resolve, reject) => {
      let imgHTML = new Image()
      imgHTML.src = image
      imgHTML.onload = function(){
        const minWidth = 300
        const rangeItem = (imgHTML.width - minWidth)/100
        const IMG_WIDTH = minWidth + (rangeItem * resolution)
        const IMG_HEIGHT = (imgHTML.height * IMG_WIDTH)/imgHTML.width
        let canvas = document.createElement("canvas")
        canvas.width = IMG_WIDTH
        canvas.height = IMG_HEIGHT
        canvas.style = "display: none"
        document.body.appendChild(canvas)
        let context = canvas.getContext("2d")
        context.drawImage(imgHTML, 0, 0, IMG_WIDTH, IMG_HEIGHT)
        let newImg = canvas.toDataURL('image/jpeg', 1)
        resolve(newImg)
      }
    })
  },
  picturesToPDF: (pictures) => {
    const MyDoc = (
      <PdfRender.Document>
        {
          pictures.map((e, i) => {
            return (
              <PdfRender.Page key={i}>
                <PdfRender.View>
                  <PdfRender.Image src={e}></PdfRender.Image>
                </PdfRender.View>
              </PdfRender.Page>
            )
          })
        }

      </PdfRender.Document>
    );
    return new Promise((resolve, reject) => {
      PdfRender.pdf(MyDoc).toBlob().then(blob => {
        resolve(blob)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getBase64(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function(error) {
        reject(error);
      };
    })
  },
  b64toBlob(dataURI, type) {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  },
  async getPdfBase64 (images) {
    let pdf
    try {
      if (Capacitor.getPlatform() === "web") {
        pdf = await this.picturesToPDF(images)
        pdf = await this.getBase64(pdf)
      } else {
        const reportUris = this.filesToUris(images)
        pdf = await this.picturesToPDF(reportUris)
        pdf = await this.getBase64(pdf)
      }
      return pdf
    } catch (error) {
      console.log(error);
    }
  },
  uriToNameFile(uri){
    // eslint-disable-next-line
    return uri.toString().replace(/^.*[\\\/]/, '')
  },
  filesToUris(arrFilePath){
    return arrFilePath.map(element => {
      return Capacitor.convertFileSrc(element)
    })
  },
  downloadFile: (name, blob) => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = window.URL.createObjectURL(blob)
    a.download = name;
    a.click();
  },
  validate: (properties, value) => {
    const validate = properties.validate || {}
    const datePicker = properties.datePicker || {}
    //console.log(validate);
    let val = value
    let regExp = new RegExp(validate.pattern)
    if (properties.type === 'number') {
      val = val.toString().replace(properties.prefixuNm, '')
      val = val.toString().replace(properties.suffixNum, '')
      val = val.toString().replaceAll(properties.thousandsSeparator, '')
      if (properties.decimalSymbol) val = val.toString().replace(properties.decimalSymbol, '.')
    }
    if (val === null || val === undefined || val === '') {
      if (validate.required) {
        return messages.inputs.required()
      }else{
        return ''
      }
    }else if (validate.maxLength > 0 && val.length > validate.maxLength) {
      return messages.inputs.maxLength(validate.maxLength)
    }else if (validate.minLength >=0 && val.length < validate.minLength) {
      return messages.inputs.minLength(validate.minLength)
    }else if (validate.max > 0 && val > validate.max) {
      return messages.inputs.max(validate.max)
    }else if (validate.min >=0 && val < validate.min) {
      return messages.inputs.min(validate.min)
    }else if (!regExp.test(val)) {
      return validate.patternMessage || messages.inputs.pattern()
    }else if (datePicker.minDate){
      const x = new Date(datePicker.minDate);
      const y = new Date(val);
      if (x.getTime() > y.getTime()) {
        return messages.inputs.min(datePicker.minDate)
      }
    }else if (datePicker.maxDate){
      const x = new Date(datePicker.maxDate);
      const y = new Date(val);
      if (x.getTime() < y.getTime()) {
        return messages.inputs.max(datePicker.maxDate)
      }
    }
    return ''
  },
  unumMaskApply(mask, data){
    if (data === undefined || data === null || data === '') return ''
    const dataType = mask.type
    const format = mask.format
    const value = mask.value
    let maskedValue = data
    if (dataType === 'string') {
      if (format) {
        const maskO = new StringMask(format)
        maskedValue = maskO.apply(data)
      }else if(mask.stringOptions){
        if (mask.stringOptions === 'L') {
          maskedValue = data.toString().toLowerCase()
        } else if(mask.stringOptions === 'U') {
          maskedValue = data.toString().toUpperCase()
        }
      }
    } else if (dataType === 'number') {
      const settings = mask.settings
      const thousandsSeparator = settings.thousandsSeparator || '.'
      const decimalSymbol = settings.decimalSymbol || ','
      const decimalLimit = Number.isInteger(settings.decimalLimit) && settings.decimalLimit > 0?settings.decimalLimit:2
      const prefixNum = settings.prefixNum || ''
      const suffixNum = settings.suffixNum || ''
      maskedValue = AutoNumeric.format(maskedValue, { 
        digitGroupSeparator: thousandsSeparator,
        decimalCharacter: decimalSymbol,
        decimalPlaces: decimalLimit
      })
      if (prefixNum) {
        maskedValue = `${prefixNum} ${maskedValue}`
      }
      if (suffixNum) {
        maskedValue = `${maskedValue} ${suffixNum}`
      }
    } else if (dataType === 'date') {
      const dateFormat = mask.dateFormat
      if (format && dateFormat) {
        maskedValue = moment(maskedValue, dateFormat).format(format)
      }else if (format) {
        maskedValue = moment(maskedValue).format(format)
      }else if(dateFormat){
        maskedValue = moment(maskedValue).format(dateFormat)
      } 
    } else if (dataType === 'img'){
      maskedValue = `data:image/jpeg;base64,${data}`
    } else if(dataType === 'prefix' && value){
      maskedValue = `${value} ${maskedValue}`
    } else if(dataType === 'suffix' && value){
      maskedValue = `${maskedValue} ${value}`
    }
    return maskedValue
  },
  maskApply(mask, str){
    if (!mask) return str
    return Inputmask.format(str, { mask })
  },
  Inputmask,
  moment,
  getAlpha2Country(data) {
    if (!data || typeof data !== 'string') return null
    const flag = lookup.countries({ name: data })[0] || lookup.countries({ alpha2: data })[0] || lookup.countries({ alpha3: data })[0]
    if (!flag.alpha2) return null
    return flag.alpha2
  },
  transpose(a) {
    // Calculate the width and height of the Array
    var w = a.length || 0;
    var h = a[0] instanceof Array ? a[0].length : 0;
    // In case it is a zero matrix, no transpose routine needed.
    if(h === 0 || w === 0) { return []; }
    /**
     * @var {Number} i Counter
     * @var {Number} j Counter
     * @var {Array} t Transposed data is stored in this array.
     */
    var i, j, t = [];
    // Loop through every item in the outer array (height)
    for(i=0; i<h; i++) {
      // Insert a new row (array)
      t[i] = [];
      // Loop through every item per item in outer array (width)
      for(j=0; j<w; j++) {
        // Save transposed data.
        t[i][j] = a[j][i];
      }
    }
    return t;
  },
  AutoNumeric,
  StringMask,
  messages,
  paths,
  arrSort,
}