import React from 'react';
import ReactDOM from "react-dom/client";
import getApp from './App';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import * as serviceWorker from './serviceWorker';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const init = async () => {
    let App = await getApp()
    root.render(<App />);
    // Call the element loader after the app has been rendered the first time
    defineCustomElements(window);
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}

init()