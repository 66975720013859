import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote
} from '@ionic/react';
import {
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import 'react-quill/dist/quill.snow.css'
import {
  informationCircleOutline,
  colorPalette
} from 'ionicons/icons';
import { inputChange, executeButton, refreshOthersApply, completeComponentAction } from '../../../../../store/unumApp/actions/index'
import { IState, IUnumColorPicker, IUnumSolution } from '../../../../../utilities/types'
import utilities from '../../../../../utilities'
import { COLOR_HEX_FORMAT, COLOR_RGB_FORMAT } from '../../../../../store/unumApp/utilities/avancedComponentsUtils';
import { SET_FOCUS_ACTION, SHOW_VALIDATION_ERROR_ACTION } from '../../../../../store/unumApp/utilities';

const Colorpiker = (props: any) => {
  const [properties, setProperties] = useState<IUnumColorPicker>(props.inputState.properties || {})
  const [errorStyle, setErrorStyle] = useState<any>(props.inputState.errorStyle || {})
  const [initialStyle, setInitialStyle] = useState<any>(props.inputState.initialStyle || {})
  const dispatch = useDispatch()
  const [style, setStyle] = useState<any>(props.inputState.initialStyle)
  const [value, setValue] = useState<any>(props.inputState.value)
  const [message, setMessage] = useState<string>('')
  const [displayColorpicker, setDisplayColorpicker] = useState<boolean>(false)
  const [primaryColor, setPrimaryColor] = useState<string>('')
  //const [secundaryColor, setSecundaryColor] = useState<string>('')

  useEffect(() => {
    const unumSolution: IUnumSolution = props.unumSolution
    setPrimaryColor(unumSolution.cdPrincipalColor || '')
    //setSecundaryColor(unumSolution.cdSecundaryColor || '#fff')
  }, [props.unumSolution])

  useEffect(() => {
    const actions = props.inputState.actions
    if (actions && actions[SHOW_VALIDATION_ERROR_ACTION] && actions[SHOW_VALIDATION_ERROR_ACTION].active) {
      setStyle(errorStyle)
      setMessage(actions[SHOW_VALIDATION_ERROR_ACTION].data)
      dispatch(completeComponentAction(props.cdProject, props.cdScreen, props.info.key, SHOW_VALIDATION_ERROR_ACTION))
    } else if(actions && actions[SET_FOCUS_ACTION] && actions[SET_FOCUS_ACTION].active) {
      setDisplayColorpicker(true)
      dispatch(completeComponentAction(props.cdProject, props.cdScreen, props.info.key, SET_FOCUS_ACTION))
    }
  }, [props.inputState.actions, props.info.key, props.cdProject, props.cdScreen, dispatch, errorStyle])


  useEffect(() => {
    setValue(props.inputState.value)
  }, [props.inputState.value])

  useEffect(() => {
    if (props.inputState.errorStyle) {
      setErrorStyle(props.inputState.errorStyle)
    }
  }, [props.inputState.errorStyle])

  useEffect(() => {
    if (props.inputState.initialStyle) {
      setInitialStyle(props.inputState.initialStyle)
    }
  }, [props.inputState.initialStyle])

  useEffect(() => {
    if (props.inputState.properties) {
      setProperties(props.inputState.properties)
    }
  }, [props.inputState.properties])

  const handleInputChange = (val: any) => {
    //en caso de ser rgb debe de convertirse el json en un string con el formato rgb(x,y,z,q)
    const format = properties.format || COLOR_HEX_FORMAT
    let inputValue, isValid = true
    if (format === COLOR_RGB_FORMAT) {
      inputValue = `rgb(${val[format].r}, ${val[format].g}, ${val[format].b}, ${val[format].a})`
    }
    if (inputValue === value) {
      return
    }
    //Validacion
    if (properties.validateOn === "submit" && style === errorStyle) {
      setStyle(initialStyle)
      setMessage('')
      //setShowMessage(false)
    }
    if (properties.validateOn === "change") {
      const validationError = utilities.validate(properties, inputValue)
      if (validationError) {
        setMessage(validationError)
        setStyle(errorStyle)
        isValid = false
      } else {
        setMessage('')
        setStyle(initialStyle)
        isValid = true
        //setShowMessage(false)
      }
    }
    //Seteo de valor
    setValue(inputValue)
    props.dispatch(
      inputChange(
        props.cdProject,
        props.cdScreen,
        properties.key,
        inputValue,
        isValid
      )
    )
    //refreshOthers
    const refreshOthers = properties.refreshOthers || []
    if (refreshOthers.length > 0) {
      props.dispatch(refreshOthersApply(
        props.cdProject,
        props.cdScreen,
        refreshOthers
      ))
    }
    //onTriggerChange
    const onTriggerChange = properties.onTriggerChange || ''
    if (onTriggerChange) {
      props.dispatch(executeButton(
        props.cdProject,
        props.cdScreen,
        onTriggerChange
      ))
    }
  }

  const viewRequestColor = (
    <div
      onClick={() => { setDisplayColorpicker(!displayColorpicker) }}
      className="border border-dark border-dashed px-3 py-2"
    >
      <div className="d-flex align-items-center unum-font-size-small">
        <IonIcon 
          icon={colorPalette} 
          size='large' 
          style={{
            color: primaryColor
          }}
          className="mr-2" />
        <span>Selecciona un color.</span>
        <div
          className="p-3 ml-2 border"
          style={{
            backgroundColor: value
          }}
        />
      </div>
    </div>
  )

  const popover = (
    <Popover id={`popover-${properties.key}`} className="unum-font-size-normal">
      <Popover.Body>
        {properties.tooltip}
      </Popover.Body>
    </Popover>
  );

  return (
    <IonItem id={properties.key} lines="none" className="mb-2">
      {
        !properties.hidden ? (
          <div
            className="d-flex w-100 flex-column justify-content-center pb-1"
          >
            <div
              className="d-flex flex-column w-100"
              style={style?.item}
            >
              <span
                className="d-flex w-100"
              >
                <IonLabel
                  className="d-flex align-items-center white-space-normal w-100 unum-font-size-normal"
                  style={style?.label}
                >
                  <span>{`${properties.label} ${properties.validate?.required ? '*' : ''}`}</span>
                </IonLabel>
                {
                  properties.tooltip ? (
                    <div style={{ zIndex: 10 }} className="px-1 d-flex align-items-center">
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={popover}
                        rootClose={true}
                      >
                        <div className="d-flex align-items-center">
                          <IonIcon icon={informationCircleOutline} size="small" />
                        </div>
                      </OverlayTrigger>
                    </div>
                  ) : null
                }
              </span>
              <div>
                {
                  displayColorpicker ? (
                    <div className="d-flex flex-column align-items-center">
                      <SketchPicker
                        color={value}
                        onChangeComplete={handleInputChange}
                      />
                      <IonButton
                        className="unum-font-size-small"
                        style={{
                          '--background': primaryColor,
                          color: '#fff'
                        }}
                        onClick={() => { setDisplayColorpicker(false) }}
                      >
                        <span className="px-3">Guardar</span>
                      </IonButton>
                    </div>
                  ) : viewRequestColor
                }
              </div>
            </div>
            {
              !!message ? (
                <IonNote>{message}</IonNote>
              ) : null
            }
          </div>
        ) : null
      }
    </IonItem>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen
    return isSameProject && isSameScreen
  })
  const screen = screens[screenIndex] || {}
  const components = screen.components || {}
  const solution = state.solutions[state.app.currentSolution]
  return {
    inputState: components[ownProps.info.key] || {},
    unumSolution: solution?.unumSolution || {}
  }
}

export default connect(mapStateToProps)(Colorpiker)