import React, { useState, useEffect } from 'react';
import UnumComponent from '../UnumComponent'

const Column = (props: any) => {
  const [properties, setProperties] = useState<any>(props.info)

  useEffect(() => {
    setProperties(props.info)
  }, [props.info])

  return (
    <div>
      {
        Array.isArray(properties.components)?(
        properties.components.map((item: any, index: any) => {
          return (
            <UnumComponent
              key={index}
              level={props.level + 1}
              info={item}
              cdProject={props.cdProject}
              cdScreen={props.cdScreen}
            />
          )
        })):null
      }
    </div>
  );
};

export default Column
