import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import UnumComponent from "./components/UnumComponent";
import Buttons from "./components/Buttons";
import Alert from "./components/Alert";
import {
  executeButton,
  clearScreenMessages,
  setShowWindow,
} from "../../store/unumApp/actions";
import {
  IButton,
  IScreen,
  IUnumShowWindow,
  IUnumSolution,
} from "../../utilities/types";
import WindowViewer from "./components/WindowViewer";

const Screen = (props: any) => {
  const [rootRender, setRootRender] = useState<Array<any>>(props.rootRender);
  const [buttons, setButtons] = useState<Array<IButton>>(props.buttons);
  const [messages, setMessages] = useState<any>(props.messages);
  const [cdProject, setCdProject] = useState<string>(props.cdProject);
  const [cdScreen, setCdScreen] = useState<string>(props.cdScreen);
  const [showWindowParams, setShowWindowParams] = useState<IUnumShowWindow>(
    props.showWindow
  );
  const [unumSolution, setUnumSolution] = useState<IUnumSolution>(
    props.unumSolution || {}
  );

  useEffect(() => {
    setUnumSolution(props.unumSolution);
  }, [props.unumSolution]);

  useEffect(() => {
    setCdProject(props.cdProject);
  }, [props.cdProject]);

  useEffect(() => {
    setCdScreen(props.cdScreen);
  }, [props.cdScreen]);

  useEffect(() => {
    setButtons(props.buttons);
  }, [props.buttons]);

  useEffect(() => {
    setRootRender(props.rootRender);
  }, [props.rootRender]);

  useEffect(() => {
    setMessages(props.messages);
  }, [props.messages]);

  useEffect(() => {
    setShowWindowParams(props.showWindow);
  }, [props.showWindow]);

  const handleExecuteButton = (btnIndex: any) => {
    props.dispatch(
      executeButton(cdProject, cdScreen, buttons[btnIndex].properties.idButton)
    );
  };

  const handleClearScreenMessages = () => {
    setMessages([]);
    props.dispatch(clearScreenMessages(cdProject, cdScreen));
  };

  const handleHideShowWindow = () => {
    props.dispatch(setShowWindow(cdProject, cdScreen, {}));
  };

  return (
    <>
      <div className="px-2">
        {Array.isArray(rootRender)
          ? rootRender.map((item: any, index: any) => {
              return (
                <UnumComponent
                  key={index}
                  level={0}
                  info={item}
                  cdProject={cdProject}
                  cdScreen={cdScreen}
                />
              );
            })
          : null}
      </div>
      {showWindowParams &&
      showWindowParams.cdProject &&
      showWindowParams.cdScreen ? (
        <WindowViewer
          cdProject={showWindowParams.cdProject}
          cdScreen={showWindowParams.cdScreen}
          hideShowWindow={handleHideShowWindow}
        />
      ) : null}
      {Array.isArray(messages) && messages.length ? (
        <Alert
          messages={messages}
          unumSolution={unumSolution}
          onClearMessages={handleClearScreenMessages}
        />
      ) : null}
      <Buttons
        buttons={buttons}
        onClick={(btnIndex: any) => {
          handleExecuteButton(btnIndex);
        }}
        unumSolution={unumSolution}
      />
    </>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  const screens = state.screens;
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject;
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen;
    return isSameProject && isSameScreen;
  });
  const screen: IScreen = screens[screenIndex] || {};
  const solution = state.solutions[state.app.currentSolution];

  return {
    screens,
    screen: screen,
    messages: screen.messages,
    buttons: screen.buttons,
    showWindow: screen.showWindow,
    rootRender: screen.rootRender,
    unumSolution: solution?.unumSolution || {},
  };
};

export default connect(mapStateToProps)(Screen);
