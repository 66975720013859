import { IScreen, IState, IUnumTab } from "../../../../utilities/types"
import { SET_TAB_INDEX_COMPONENT_ACTION } from "../../utilities/containersUtils"
import { addComponentAction } from "./transversalComponentsActions"

export const setContainerComponents = (cdProject: string, cdScreen: string, componentsForRefresh: Array<any>) => {
  return {
    type: 'SET_CONTAINER_COMPONENTS',
    cdProject,
    cdScreen,
    componentsForRefresh
  }
}

export const setTabIndexByTabKey = (cdProject: string, cdScreen: string, tabKey: string) => {
  return (dispatch: any, getState: any) => {
    const state: IState = getState()
    const screens: { [id: string]: IScreen; } = state.screens
    const screenIndex = Object.keys(screens).findIndex((item) => {
      const isSameProject = screens[item].cdProject === cdProject
      const isSameScreen = screens[item].cdScreen === cdScreen
      return isSameProject && isSameScreen
    })
    const screen = screens[screenIndex]
    const components = screen.components
    Object.keys(components).forEach((compKey: string) => {
      if (components[compKey].properties.type === 'tabs') {
        const compTab: IUnumTab = components[compKey].properties
        compTab.components.forEach((tab, index) => {
          if (tab.key === tabKey) {
            dispatch(addComponentAction(cdProject, cdScreen, tab.key, SET_TAB_INDEX_COMPONENT_ACTION, index))
          }
        })
      }
    });
  }
}
