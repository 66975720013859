import { IScreen } from "../../../../utilities/types"
import { REFRESH_COMPONENT_ACTION } from "../../utilities/avancedComponentsUtils"
import { executeGetQueryResult } from "./avancedComponentsActions"

export const addComponentAction = (cdProject: string, cdScreen: string, componentKey: string, action: string, data: any) => {
  return {
    type: 'ADD_COMPONENT_ACTION',
    cdProject,
    cdScreen,
    key: componentKey,
    action,
    data
  }
}

export const completeComponentAction = (cdProject: string, cdScreen: string, componentKey: string, action: string) => {
  return {
    type: 'COMPLETE_COMPONENT_ACTION',
    cdProject,
    cdScreen,
    key: componentKey,
    action
  }
}

export const updateComponentProperty = (cdProject: string, cdScreen: string, componentKey: string, attributes: any) => {
  return {
    type: 'UPDATE_COMPONENT_PROPERTY',
    cdProject,
    cdScreen,
    key: componentKey,
    attributes
  }
}

export const refreshOthersApply = (cdProject: string, cdScreen: string, keys: Array<string>) => {
  return (dispatch: any, getState: any) => {
    const state = getState()
    const screens: { [id: string]: IScreen; } = state.screens
    const screenIndex = Object.keys(screens).findIndex((item) => {
      const isSameProject = screens[item].cdProject === cdProject
      const isSameScreen = screens[item].cdScreen === cdScreen
      return isSameProject && isSameScreen
    })
    keys.forEach(key => {
      const comp = screens[screenIndex].components[key]
      if (comp) {
        dispatch(executeGetQueryResult(cdProject, cdScreen, key, comp.properties.query, comp.properties.input, comp.properties.type))
        dispatch(addComponentAction(cdProject, cdScreen, key, REFRESH_COMPONENT_ACTION, null))
      }
    });
  }
}

export const inputChange = (cdProject: string, cdScreen: string, inputKey: string, value: any, isValid: boolean) => {
  return {
    type: 'INPUT_CHANGE',
    cdProject,
    cdScreen,
    inputKey,
    value,
    isValid
  }
}

export const setInputValueState = (cdProject: string, cdScreen: string, key: string, value: any) => {
  return {
    type: 'SET_INPUT_VALUE_STATE',
    cdProject,
    cdScreen,
    key,
    value
  }
}