import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IScreen, IState } from '../../../../utilities/types';
import UnumComponent from '../UnumComponent'

const Columns = (props: any) => {
  const [properties, setProperties] = useState<any>(props.properties)

  useEffect(() => {
    setProperties(props.properties)
  }, [props.properties])

  return (
    <div id={properties.key}>
      {
        Array.isArray(properties.columns)?(
          properties.columns.map((item: any, index: any) => {
            return (<div key={index}>
                <UnumComponent
                  level={props.level + 1}
                  info={item}
                  cdProject={props.cdProject}
                  cdScreen={props.cdScreen}
                />
                { properties.columns.length - 1 > index?<hr />:null }
              </div>
            )
          })
        ):null
      }
    </div>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen
    return isSameProject && isSameScreen
  })
  const screen:IScreen = screens[screenIndex] || {}
  const comp = screen.components[ownProps.info.key] || {}
  const properties = comp.properties || {}
  
  return {
    properties
  }
}

export default connect(mapStateToProps)(Columns)
