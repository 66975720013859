import React, { useEffect, useState } from 'react';
import Draft from 'draft-js';
import './RichTextArea.css'
import {stateToHTML} from 'draft-js-export-html';
import { IonButton } from '@ionic/react';
import htmlToDraft from 'html-to-draftjs';

const { Editor, EditorState, RichUtils, ContentState } = Draft;

const StyleSelect = (props: any) => {
  const [value, setValue] = useState<any>(props.blockType)

  const onChange = (e: any) => {
    e.preventDefault();
    props.onToggle(e.target.value);
  };

  useEffect(() => {
    if (props.blockType) {
      setValue(props.blockType)
    }
  }, [props.blockType])

  return (
    <select value={value} className="custom-select mr-2" onChange={onChange}>
      <option value={'unstyled'}>-</option>
      {
        props.options.map((item:any, index: number) => {
          return <option key={index} value={item.style}>{item.label}</option>
        })
      }
    </select>
  );
}

const StyleButton = (props: any) => {
  const onToggle = (e: any) => {
    e.preventDefault();
    props.onToggle(props.style);
  };

  let className = 'RichEditor-styleButton bg-transparent';
  if (props.active) {
    className += ' RichEditor-activeButton bg-transparent  fw-bold';
  }
  return (
    <IonButton className={className} onMouseDown={onToggle}>
      {props.label}
    </IonButton>
  );
}

const BLOCK_TYPES = [
  {
    type: 'select', 
    options: [
      { label: 'Title 1', style: 'header-one' },
      { label: 'Title 2', style: 'header-three' },
      { label: 'Title 3', style: 'header-five' }
    ]
  },
  { type: 'button', label: '•', style: 'unordered-list-item' },
  { type: 'button', label: '1.', style: 'ordered-list-item' },
];

const BlockStyleControls = (props: any) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {
        BLOCK_TYPES.map((item, index) => {
          switch (item.type) {
            case 'select':
              return (
                <StyleSelect
                  key={index}
                  blockType={blockType}
                  options={item.options}
                  onToggle={props.onToggle}
                />
              )
            case 'button':
              return (
                <StyleButton
                  key={index}
                  active={item.style === blockType}
                  label={item.label}
                  onToggle={props.onToggle}
                  style={item.style}
                />
              )
            default:
              return (
                <StyleButton
                  key={index}
                  active={item.style === blockType}
                  label={item.label}
                  onToggle={props.onToggle}
                  style={item.style}
                />
              )
          }
        })
      }
    </div>
  );
};

var INLINE_STYLES = [
  { label: 'B', style: 'BOLD' },
  { label: 'I', style: 'ITALIC' },
  { label: 'U', style: 'UNDERLINE' }
];

const InlineStyleControls = (props: any) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div>
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};


const RichTextArea = React.forwardRef((props: any, editorRef: any) => {
  const blocksFromHtml = htmlToDraft(props.value);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const initialContentState:any = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const [value, setValue] = useState(props.value)
  const [editorState, setEditorState] = useState(EditorState.createWithContent(initialContentState))

  useEffect(() => {
    if (props.value !== value) {
      const blocksFromHtml = htmlToDraft(props.value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const initialContentState:any = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(initialContentState))
      setValue(props.value)
    }
  }, [props.value, value])

  const onChange = (editorState: any) => {
    setEditorState(editorState)
    const newValue = stateToHTML(editorState.getCurrentContent())
    setValue(newValue)
    props.onChange(newValue)
  };

  const toggleBlockType = (type: any) => _toggleBlockType(type);
  const toggleInlineStyle = (style: any) => _toggleInlineStyle(style);

  const _toggleBlockType = (blockType: any) => {
    const val: any = RichUtils.toggleBlockType(
      editorState,
      blockType
    )
    onChange(val)
  }

  const _toggleInlineStyle = (inlineStyle: any) => {
    const val: any = RichUtils.toggleInlineStyle(
      editorState,
      inlineStyle
    )
    onChange(val)
    
  }

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let className = 'RichEditor-editor';
  var contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  return (
    <div className="RichEditor-root">
      <BlockStyleControls
        editorState={editorState}
        onToggle={toggleBlockType}
      />
      <InlineStyleControls
        editorState={editorState}
        onToggle={toggleInlineStyle}
      />
      <div className={className}>
        <Editor
          editorState={editorState}
          onChange={onChange}
          ref={editorRef}
          spellCheck={true}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          onBlur={() => {
            props.onBlur()
          }}
        />
      </div>
    </div>
  );
})


export default RichTextArea