import React, { useEffect, useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { Modal } from 'react-bootstrap'
import {
  bugOutline
} from 'ionicons/icons';
import grayLogoSRC from '../../../assets/grayLogo.png'
import { IUnumSolution } from '../../../utilities/types';

const Alert = (props: any) => {
  const ICONS:any = {
    error: bugOutline
  }
  const [messages, setMessages] = useState<any>(props.messages)

  const [bgColor, setBgColor] = useState<string>('')
  //const [color, setColor] = useState<string>('')
  const color = '#fff'

  useEffect(() => {
    const unumSolution: IUnumSolution = props.unumSolution
    setBgColor(unumSolution.cdPrincipalColor || '')
    //setColor(unumSolution.cdSecundaryColor || '#fff')
  }, [props.unumSolution])

  const hideMessage = (index:any) => {
    const newMessages = messages.filter((i: any, ind: any) => index !== ind)
    if (!newMessages.length) {
      props.onClearMessages()
    }
    setMessages(newMessages)
  }

  return (
    <>
      {
        Array.isArray(messages)?(
          messages.map((item: any, index: any) => {
            if (item.timer) {
              let i = setInterval(() => {
                setMessages(messages.filter((i: any, ind: any) => index !== ind))
                clearInterval(i)
              }, item.timer)
            }
            return (
              <Modal 
                key={index}
                show={true} 
                backdrop={item.allowOutsideClick}
                onHide={() => { hideMessage(index) }}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="d-flex align-items-center">
                    {
                      ICONS[item.icon]?
                      (<IonIcon className="mr-2" slot="icon-only" icon={ICONS[item.icon]} />):null
                    }
                    <span className="unum-font-size-large">{item.title}</span>
                    {
                      !ICONS[item.icon] && !item.title?(
                        <div style={{width: '3em'}}>
                          <img src={grayLogoSRC} className="w-100 h-auto" alt="unum" />
                        </div>
                      ):null
                    }
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="unum-font-size-normal">{item.html ? <div dangerouslySetInnerHTML={{__html: item.html}} /> : item.text}</Modal.Body>
                <Modal.Footer>
                  {
                    item.showCloseButton ?(
                    <IonButton
                      style={{
                        backgroundColor: bgColor,
                        '--background': bgColor,
                        color
                      }}
                      className="unum-font-size-normal"
                      onClick={() => { hideMessage(index) }}
                    >
                      Cerrar
                    </IonButton>
                    ):null
                  }
                  {
                    item.actions?(
                      item.actions.map((item: any, index: any) => {
                        return (
                          <IonButton 
                            key={index} 
                            className="unum-font-size-normal"
                            style={{
                              backgroundColor: bgColor,
                              '--background': bgColor,
                              color
                            }}
                            onClick={() => { item.run(); hideMessage(index); }}>
                            {item.label}
                          </IonButton>
                        )
                      })
                    ):null
                  }
                </Modal.Footer>
              </Modal>
            )
          })
        ):null
      }
    </>
  )
}

export default Alert