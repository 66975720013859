import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonProgressBar
} from '@ionic/react';
import {
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css'
import {
  informationCircleOutline,
  caretDownOutline,
  closeCircle
} from 'ionicons/icons';
import { inputChange, executeButton, executeGetQueryResult, refreshOthersApply, completeComponentAction, formatMapWith } from '../../../../../store/unumApp/actions/index'
import { IState, IUnumSelect, IUnumSolution } from '../../../../../utilities/types'
import utilities from '../../../../../utilities'
import { SET_FOCUS_ACTION, SHOW_VALIDATION_ERROR_ACTION } from '../../../../../store/unumApp/utilities';
import ComponentLoading from '../../generals/ComponentLoading';
import { SET_IS_LOADING_COMPONENT_ACTION } from '../../../../../store/unumApp/utilities/avancedComponentsUtils';
import ModalSelect from './ModalSelect'

const styleSelectValue = {
  minHeight: '3.3em'
}

const Select = (props: any) => {
  const dispatch = useDispatch()
  const [properties, setProperties] = useState<IUnumSelect>(props.inputState.properties || {})
  const [errorStyle, setErrorStyle] = useState<any>(props.inputState.errorStyle || {})
  const [initialStyle, setInitialStyle] = useState<any>(props.inputState.initialStyle || {})
  const [style, setStyle] = useState<any>(props.inputState.initialStyle)
  const [queryOptions, setQueryOptions] = useState<Array<any>>(props.inputState.data || [])
  const [value, setValue] = useState<any>(props.inputState.value)
  const [message, setMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  let select1Ref = useRef<any>(null)
  let select2Ref = useRef<any>(null)
  const [primaryColor, setPrimaryColor] = useState<string>('')

  useEffect(() => {
    const unumSolution: IUnumSolution = props.unumSolution
    setPrimaryColor(unumSolution.cdPrincipalColor || '')
    //setSecundaryColor(unumSolution.cdSecundaryColor || '#fff')
  }, [props.unumSolution])

  useEffect(() => {
    if (properties.query) {
      setIsLoading(true)
      dispatch(executeGetQueryResult(props.cdProject, props.cdScreen, properties.key, properties.query, properties.input, properties.type))
    }
  }, [dispatch, properties.key, properties.query, properties.input, properties.type, props.cdProject, props.cdScreen])

  useEffect(() => {
    const actions: any = props.inputState.actions
    if (actions && actions[SHOW_VALIDATION_ERROR_ACTION] && actions[SHOW_VALIDATION_ERROR_ACTION].active) {
      setStyle(errorStyle)
      setMessage(actions[SHOW_VALIDATION_ERROR_ACTION].data)
      dispatch(completeComponentAction(props.cdProject, props.cdScreen, props.info.key, SHOW_VALIDATION_ERROR_ACTION))
    } else if (actions && actions[SET_FOCUS_ACTION] && actions[SET_FOCUS_ACTION].active) {
      dispatch(completeComponentAction(props.cdProject, props.cdScreen, props.info.key, SET_FOCUS_ACTION))
    } else if (actions && actions[SET_IS_LOADING_COMPONENT_ACTION] && actions[SET_IS_LOADING_COMPONENT_ACTION].active) {
      setIsLoading(!!actions[SET_IS_LOADING_COMPONENT_ACTION].data)
      dispatch(completeComponentAction(props.cdProject, props.cdScreen, props.info.key, SET_IS_LOADING_COMPONENT_ACTION))
    }
  }, [props.inputState.actions, props.info.key, props.cdProject, props.cdScreen, dispatch, errorStyle, select1Ref, select2Ref])


  useEffect(() => {
    setValue(props.inputState.value)
  }, [props.inputState.value])

  useEffect(() => {
    if (Array.isArray(props.inputState.data)) {
      const opts = props.inputState.data.map((opt: any, optIndex: any) => {
        return {
          label: opt[properties.fieldToDisplay],
          value: opt[properties.fieldValue]
        }
      })
      setQueryOptions(opts)
    }
    setIsLoading(false)
  }, [props.inputState.data, properties.fieldToDisplay, properties.fieldValue])

  useEffect(() => {
    if (props.inputState.errorStyle) {
      setErrorStyle(props.inputState.errorStyle)
    }
  }, [props.inputState.errorStyle])

  useEffect(() => {
    if (props.inputState.initialStyle) {
      setInitialStyle(props.inputState.initialStyle)
    }
  }, [props.inputState.initialStyle])

  useEffect(() => {
    if (props.inputState.properties) {
      setProperties(props.inputState.properties)
    }
  }, [props.inputState.properties])

  const handleInputChange = (val: any) => {
    let inputValue = val, isValid = true
    if (inputValue === value) {
      return
    }
    //Validacion
    if (properties.validateOn === "submit" && style === errorStyle) {
      setStyle(initialStyle)
      setMessage('')
      //setShowMessage(false)
    }
    if (properties.validateOn === "change") {
      const validationError = utilities.validate(properties, inputValue)
      if (validationError) {
        setMessage(validationError)
        setStyle(errorStyle)
        isValid = false
      } else {
        setMessage('')
        setStyle(initialStyle)
        isValid = true
        //setShowMessage(false)
      }
    }
    //Seteo de valor
    setValue(inputValue)
    props.dispatch(
      inputChange(
        props.cdProject,
        props.cdScreen,
        properties.key,
        inputValue,
        isValid
      )
    )
    props.dispatch(formatMapWith(inputValue, props.cdProject, props.cdScreen, props.inputState, properties));
    //refreshOthers
    const refreshOthers = properties.refreshOthers || []
    if (refreshOthers.length > 0) {
      props.dispatch(refreshOthersApply(
        props.cdProject,
        props.cdScreen,
        refreshOthers
      ))
    }
    //onTriggerChange
    const onTriggerChange = properties.onTriggerChange || ''
    if (onTriggerChange) {
      props.dispatch(executeButton(
        props.cdProject,
        props.cdScreen,
        onTriggerChange
      ))
    }
  }

  /*const handleRefreshData = () => {
    setIsLoading(true)
    dispatch(executeGetQueryResult(props.cdProject, props.cdScreen, properties.key, properties.query, properties.input, properties.type))
  }*/

  const popover = (
    <Popover id={`popover-${properties.key}`}>
      <Popover.Body className="unum-font-size-normal">
        {properties.tooltip}
      </Popover.Body>
    </Popover>
  );

  const title = `${properties.label} ${properties.validate.required ? '*' : ''}`

  const isModeQuery = properties.query && properties.fieldToDisplay && properties.fieldValue

  const valueRender = () => {
    if (isModeQuery && Array.isArray(queryOptions)) {
      const indexValue = queryOptions.findIndex((item) => {
        return item.value === value
      })
      if (indexValue < 0) return null
      return <span>{queryOptions[indexValue].label}</span>
    } else if (Array.isArray(properties?.data?.values)) {
      const indexValue = properties?.data?.values.findIndex((item) => {
        return item.value === value
      })
      if (indexValue < 0) return null
      return <span>{properties?.data?.values[indexValue].label}</span>
    }
  }




  return (
    <IonItem id={properties.key} lines="none" className="mb-2">
      {
        !properties.hidden ? (
          <div
            className="d-flex w-100 flex-column justify-content-center position-relative"
          >
            <div
              className="d-flex flex-column w-100"
              style={style.item}
            >
              <span
                className="d-flex w-100"
              >
                <IonLabel
                  className="d-flex align-items-center white-space-normal w-100 unum-font-size-normal"
                  style={style.label}
                  
                >
                  <span>{title}</span>
                </IonLabel>
                {
                  properties.tooltip ? (
                    <div style={{ zIndex: 10 }} className="px-1 d-flex align-items-center">
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={popover}
                        rootClose={true}
                      >
                        <div className="d-flex align-items-center">
                          <IonIcon icon={informationCircleOutline} size="small" />
                        </div>
                      </OverlayTrigger>
                    </div>
                  ) : null
                }
              </span>
              <div
                className="unum-font-size-small border d-flex align-items-center pl-1"
                style={styleSelectValue}
                
                onClick={() => { setIsOpen(!isOpen) }}
              >

                {valueRender()}
                <div className="ml-auto mr-1 d-flex align-items-center">
                  {
                    value ? (
                      <IonIcon
                        onClick={(e) => { e.stopPropagation(); handleInputChange('') }}
                        icon={closeCircle}
                        size="small"
                      />
                    ) : (
                      <IonIcon icon={caretDownOutline} size="small" />
                    )
                  }
                  
                </div>
              </div>
              {
                isModeQuery ?
                  (
                    isOpen ? (
                      <ModalSelect
                        value={value}
                        disabled={properties.disabled}

                        options={queryOptions}
                        onChange={(val: any) => {
                          if (val !== null && val !== undefined && val !== value) {
                            handleInputChange(val)
                          }
                        }}
                        onHide={() => {
                          setIsOpen(false)
                        }}
                        title={title}
                        primaryColor={primaryColor}
                      />
                    ) : null
                  ) : (
                    isOpen ? (
                      <ModalSelect
                        value={value}
                        options={properties?.data?.values}
                        disabled={properties.disabled}

                        onChange={(val: any) => {
                          if (val !== null && val !== undefined && val !== value) {
                            handleInputChange(val)
                          }
                        }}
                        onHide={() => {
                          setIsOpen(false)
                        }}
                        title={title}
                        primaryColor={primaryColor}
                      />
                    ) : null
                  )
              }

            </div>
            {
              isLoading ?
                (
                  <div>
                    <IonProgressBar type="indeterminate"></IonProgressBar>
                    <ComponentLoading />
                  </div>
                ) : null
            }
            {
              !!message ? (
                <IonNote>{message}</IonNote>
              ) : null
            }
          </div>
        ) : null
      }
    </IonItem>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen
    return isSameProject && isSameScreen
  })
  const screen = screens[screenIndex] || {}
  const components = screen.components || {}
  const solution = state.solutions[state.app.currentSolution]
  return {
    inputState: components[ownProps.info.key] || {},
    unumSolution: solution?.unumSolution || {}
  }
}

export default connect(mapStateToProps)(Select)