import { IonContent, IonIcon, IonModal } from '@ionic/react';
import React from 'react'
import {
  closeOutline
} from 'ionicons/icons'
import grayLogoSRC from '../../../assets/grayLogo.png';
import Screen from '../Screen'

const WindowViewer = (props: any) => {

  const handleHideShowWindow = () => {
    props.hideShowWindow()
  }

  return (
    <IonModal
      isOpen={true}
      onDidDismiss={handleHideShowWindow}
      animated={true}
    >
      <IonContent>
        <div className="border-bottom pt-1 pb-2 px-2 d-flex justify-content-between">
          <div style={{ width: '3em' }}>
            <img src={grayLogoSRC} className="w-100 h-auto" alt="unum" />
          </div>
          <div onClick={handleHideShowWindow}>
            <IonIcon icon={closeOutline} size="large" />
          </div>
        </div>
        <Screen
          cdProject={props.cdProject}
          cdScreen={props.cdScreen}
        />
      </IonContent>
    </IonModal>
  )
}
export default WindowViewer