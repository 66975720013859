import React, { useEffect, useState } from 'react'
import { IonItem, IonIcon, IonLabel, IonMenuToggle, IonList } from '@ionic/react';
import {
  caretForwardOutline,
  caretDownOutline,
  layersOutline
} from 'ionicons/icons';
import './SubMenu.css';

const SubMenu: React.FunctionComponent<any> = (props:any) => {
  const [isActive, setIsActive] = useState<boolean>(!props.hidden)
  const [options, setOptions] = useState<Array<any>>(props.options)
  const [cdSolution, setCdSolution] = useState<string>(props.cdSolution)
  const [childrenActive, setChildrenActive] = useState<boolean>(props.isRoot?false:true)

  useEffect(() => {
    if (props.cdSolution !== cdSolution) {
      if (props.isRoot) {
        setChildrenActive(true)
      }
      setCdSolution(props.cdSolution)
    }
  }, [props.hidden, props.isRoot, props.cdSolution, cdSolution])

  useEffect(() => {
    if (props.cdSolution !== cdSolution) {
      setIsActive(!props.hidden)
    }
  }, [props.hidden, props.cdSolution, cdSolution])

  useEffect(() => {
    setOptions(props.options)
  }, [props.options])

  return (
    <IonList className="py-0 bg-transparent" lines="none">
      {
        //Opcion que contiene un submenu
        props.isRoot ? null :
          (<IonItem
            className={props.label ? "itemMenu bg-transparent" : "d-none"}
            onClick={() => { setIsActive(!isActive) }}
            lines="none"
            detail={false}
          >
            <div className="d-flex align-items-center mr-2">
              <IonIcon
                icon={props.icon ? props.icon : layersOutline}
                size="small"
                className=""
              />
            </div>
            <IonLabel>{props.label}</IonLabel>
            {
              isActive ?
                (<IonIcon size="small" slot="end" color="white" icon={caretDownOutline} />) :
                (<IonIcon size="small" slot="end" color="white" icon={caretForwardOutline} />)
            }
          </IonItem>)
      }
      <div className={!props.isRoot ? 'secundaryItem' : ''}>
        {
          //Contenido del submenu
          isActive ?
            (<div>
              {options.map((opt: any, index: any) => {
                if (opt.condition !== undefined && opt.condition !== null && !opt.condition) {
                  return null
                }
                if (opt.type === 'urls' || (opt.items && opt.items.length)) {
                  //Opcion con submenu
                  return (
                    <SubMenu
                      key={index}
                      options={opt.items}
                      label={opt.label}
                      icon={opt.icon}
                      isRoot={false}
                      hidden={!childrenActive}
                      cdSolution={cdSolution}  
                    />
                  );
                } else {
                  //Opcion de enrutar o accion
                  return (
                    <IonMenuToggle key={index} autoHide={false}>
                      {
                        !opt.type || opt.type === 'url' ?
                          (<IonItem
                            className="itemMenu bg-transparent "
                            routerLink={opt.url ? opt.url : `/views/${cdSolution}/${opt.project}/${opt.cdItem}`}
                          >
                            <div className="d-flex align-items-center mr-2">
                              <IonIcon
                                icon={opt.icon || layersOutline}
                                size="small"
                                className=""
                              />
                            </div>
                            <IonLabel>{opt.label}</IonLabel>
                          </IonItem>) :
                          (null)
                      }
                      {
                        opt.type === 'action' ?
                          (<IonItem
                            className="itemMenu bg-transparent "
                            onClick={(e) => {
                              e.preventDefault()
                              opt.action()
                            }}
                          >
                            <div className="d-flex align-items-center mr-2">
                              <IonIcon
                                icon={opt.icon || layersOutline}
                                size="small"
                                className=""
                              />
                            </div>
                            <IonLabel>{opt.label}</IonLabel>
                          </IonItem>) : null
                      }
                    </IonMenuToggle>
                  );
                }
              })}
            </div>) :
            null
        }
      </div>

    </IonList>
  )
}

export default SubMenu