import {
    IonList
  } from '@ionic/react';
  import React, { useEffect, useState } from 'react';
  import { connect } from 'react-redux';
  import { IScreen, IState } from '../../../../utilities/types';
  import UnumComponent from '../UnumComponent'
  
  const Fieldset = (props: any) => {
    const [properties, setProperties] = useState<any>(props.properties)
  
    useEffect(() => {
      setProperties(props.properties)
    }, [props.properties])
  
    if (properties.hidden) {
      return null
    }
    return (
      <div id={properties.key} className="d-flex flex-column">
        <div className="bg-transparent px-3 pb-2">
          <span className="unum-font-size-normal text-black">{properties.legend}</span>
        </div>
        <div className="px-0 bg-transparent">
          <IonList className="py-0 bg-transparent">
            {
              Array.isArray(properties.components)?(
              properties.components.map((item: any, index: any) => {
                return (
                  <UnumComponent
                    key={index}
                    level={props.level + 1}
                    info={item}
                    cdProject={props.cdProject}
                    cdScreen={props.cdScreen}
                  />
                )
              })):null
            }
          </IonList>
        </div>
      </div>
    );
  };
  
  const mapStateToProps = (state: IState, ownProps: any) => {
    const screens = state.screens
    const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
      const isSameProject = screens[item].cdProject === ownProps.cdProject
      const isSameScreen = screens[item].cdScreen === ownProps.cdScreen
      return isSameProject && isSameScreen
    })
    const screen:IScreen = screens[screenIndex] || {}
    const comp = screen.components[ownProps.info.key] || {}
    const properties = comp.properties || {}
  
    return {
      properties
    }
  }
  
  export default connect(mapStateToProps)(Fieldset)
  
  