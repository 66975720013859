import LoggedRoute from './utilities/router/LoggedRoute'
import UnloggedRoute from './utilities/router/UnloggedRoute'
import Menu from './components/header/menu/Menu';
import PageLogin from './pages/PageLogin'
import PageView from './pages/PageView'
import PageLobby from './pages/pageLobby/PageLobby'

import React, { useEffect } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
//import { Route } from 'react-router-dom';
import { setupIonicReact } from '@ionic/react';

import { Provider } from 'react-redux';
import getStore from './store/index'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import 'bootstrap/dist/css/bootstrap.min.css';

/* Custom styles */
import './theme/customStyles.scss'

import { onMountedAppForUserLogged } from './store/unumApp/actions'

setupIonicReact({
  mode: 'md'
});
export default async () => {
  let store = await getStore()

  const App: React.FC = () => {
    //const dispatch:any = useDispatch()

    useEffect(() => {
      const isLogged = store.getState().providerServices.isLogged()
      if (isLogged) {
        store.dispatch(onMountedAppForUserLogged())
      }
    }, [])

    return (
      <Provider store={store}>
        <IonApp>
          <IonReactRouter>{/* Router de Ionic/React */}
            <IonSplitPane contentId="main">{/* Componente para aparecer menu a traves del boton del header y en tamaño tablet */}
              <Menu />
              <IonRouterOutlet id="main">{/* Controla transiciones entre paginas y el estado de las mismas paginas */}
                <UnloggedRoute path="/login" component={PageLogin} exact={true} />
                <LoggedRoute path="/views/:cdSolution/:projectId" component={PageView} exact={true} />
                <LoggedRoute path="/views/:cdSolution/:projectId/:itemId" component={PageView} exact={true} />
                <LoggedRoute path="/" component={PageLobby} exact={true} />
                {/*<Route path="/" component={PageLobby} exact={true} />*/}
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
      </Provider>

    );
  };

  return App;
}