import React from 'react'

const ComponentLoading = () => {//El tamano de este componente depende de un padre con position:relative 
  return (
    <div
      style={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#00000082',
        zIndex: 10
      }}
      className="position-absolute"
    />
  )
}


export default ComponentLoading