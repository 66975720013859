import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux';
import defaultConfiguration from '../theme/defaultConfiguration'
import ProviderServices from '../providerServices/index';
import utilities from '../utilities'
import { getCheckboxValue } from './unumApp/utilities/basicInputUtils'
import { IButton, IScreen, ISolution, IUnumMenuItem, IUnumTabButton } from './../utilities/types'
import lodash from 'lodash'

export default async () => {
  const providerServices = await ProviderServices.build()
  const initialState = {
    providerServices,
    utilities,
    defaultConfiguration,
    screens: {},
    solutions: {},
    app: {
      currentSolution: '',
      currentProject: '',
      currentScreen: ''
    }
  }
  const unumReducerScreens = (screens: { [id: string]: IScreen } = {}, action: any) => {
    let buttons: any
    const screenIndex = Object.keys(screens).findIndex((item) => {
      const isSameProject = screens[item].cdProject === action.cdProject
      const isSameScreen = screens[item].cdScreen === action.cdScreen
      return isSameProject && isSameScreen
    })
    switch (action.type) {
      case 'ADD_SCREEN':
        if (screenIndex >= 0) {
          return {
            ...screens,
            [screenIndex]: action.screen
          }
        }
        return {
          ...screens,
          [Object.keys(screens).length]: action.screen
        }

      case 'INPUT_CHANGE':
        /*inputKey,value,isAction,cdProject,cdScreen,*/
        if (screenIndex >= 0 && screens[screenIndex].components[action.inputKey]) {
          const componentProps = screens[screenIndex].components[action.inputKey].properties
          const checkVal = getCheckboxValue(
            componentProps.type,
            action.value,
            componentProps.valueOn,
            componentProps.valueOff
          )
          let newScreens: { [id: string]: IScreen } = {
            ...screens,
            [screenIndex]: {
              ...screens[screenIndex],
              components: {
                ...screens[screenIndex].components,
                [action.inputKey]: {
                  ...screens[screenIndex].components[action.inputKey],
                  value: checkVal !== undefined ? checkVal : action.value,
                  isInitial: false,
                  isValid: action.isValid
                }
              }
            }
          }
          //Refresh To - When: la logica del refreshWhen se traslada al inicio para refreshTo
          const refreshToWhen = screens[screenIndex].components[action.inputKey].properties.refreshTo || []
          const refreshElement = (elementKey: string) => {
            const input = screens[screenIndex].components[elementKey]
            if (input) {
              let inputValidationMessage = ''
              if (input.properties.validateOn === 'change') {
                inputValidationMessage = utilities.validate(input.properties, input.properties.defaultValue)
              }
              newScreens = {
                ...newScreens,
                [screenIndex]: {
                  ...newScreens[screenIndex],
                  components: {
                    ...newScreens[screenIndex].components,
                    [elementKey]: {
                      ...input,
                      value: input.properties.defaultValue,
                      valueState: [],
                      isInitial: false,
                      isValid: !inputValidationMessage
                    }
                  }
                }
              }
              const elementRefreshTo = input.properties.refreshTo || []
              elementRefreshTo.forEach(refreshElement)
            } else {
              newScreens = {
                ...newScreens,
                [screenIndex]: {
                  ...newScreens[screenIndex],
                  components: {
                    ...newScreens[screenIndex].components,
                    [elementKey]: {
                      properties: {
                        key: elementKey
                      },
                      value: '',
                      valueState: [],
                      isInitial: false,
                      isValid: true,
                      actions: {}
                    }
                  }
                }
              }
            }
          }
          refreshToWhen.forEach(refreshElement)
          return newScreens
        }
        return screens

      case 'SET_INPUT_VALUE_STATE':
        if (screenIndex >= 0 && screens[screenIndex].components[action.key]) {
          let newScreens = {
            ...screens,
            [screenIndex]: {
              ...screens[screenIndex],
              components: {
                ...screens[screenIndex].components,
                [action.key]: {
                  ...screens[screenIndex].components[action.key],
                  valueState: action.value
                }
              }
            }
          }
          return newScreens
        }
        return screens

      case 'UPDATE_COMPONENTS':
        return {
          ...screens,
          [screenIndex]: {
            ...screens[screenIndex],
            components: {
              ...screens[screenIndex].components,
              ...action.components
            }
          }
        }

      case 'SET_MESSAGES':
        return {
          ...screens,
          [screenIndex]: {
            ...screens[screenIndex],
            messages: action.messages
          }
        }

      case 'CLEAR_MESSAGES':
        return {
          ...screens,
          [screenIndex]: {
            ...screens[screenIndex],
            messages: []
          }
        }
      case 'SHOW_BUTTONS':
        let showAll = false
        const showButtons: Array<IUnumTabButton> = action.buttons
        if (showButtons?.length === 0) showAll = true
        buttons = screens[screenIndex].buttons.map((element: IButton) => {
          const btnInd = showButtons.findIndex(item => {
            return item.idButton === element.properties.idButton
          })
          if (btnInd !== -1 || showAll) {
            return {
              ...element,
              isActive: true
            }
          }
          return {
            ...element,
            isActive: false
          }
        })
        return {
          ...screens,
          [screenIndex]: {
            ...screens[screenIndex],
            buttons
          }
        }

      case 'HIDE_BUTTONS':
        buttons = screens[screenIndex].buttons.map((element: IButton) => {
          return {
            ...element,
            isActive: false
          }
        })
        return {
          ...screens,
          [screenIndex]: {
            ...screens[screenIndex],
            buttons
          }
        }

      case 'HIDE_BUTTON':
        buttons = screens[screenIndex].buttons.map((element: IButton) => {
          if (element.properties.idButton === action.buttonId) {
            return {
              ...element,
              isActive: false
            }
          }
          return {
            ...element
          }
        })   
        return {
          ...screens,
          [screenIndex]: {
            ...screens[screenIndex],
            buttons
          }
        }

        case 'SHOW_BUTTON':
          buttons = screens[screenIndex].buttons.map((element: IButton) => {
            if (element.properties.idButton === action.buttonId) {
              return {
                ...element,
                isActive: true
              }
            }
            return {
              ...element
            }
          })
          return {
            ...screens,
            [screenIndex]: {
              ...screens[screenIndex],
              buttons
            }
          }

      case 'SET_CONTAINER_COMPONENTS'://action.cdProject - action.cdScreen - action.componentsForRefresh
        const getUpdatedComponentsReducer = (result: any, currentItem: any) => {
          const components = currentItem.components
          const container = currentItem.container
          const screenComponents = currentItem.screenComponents
          if (screens[screenIndex].components[container]) {
            return {
              components: {
                ...result.components,
                ...screenComponents,
                [container]: {
                  ...screens[screenIndex].components[container],
                  properties: {
                    ...screens[screenIndex].components[container].properties,
                    components: components
                  }
                }
              }
            }
          }
          return {
            components: {
              ...result.components
            }
          }
        }
        let updatedComponents = action.componentsForRefresh.reduce(getUpdatedComponentsReducer, {})
        return {
          ...screens,
          [screenIndex]: {
            ...screens[screenIndex],
            components: {
              ...screens[screenIndex].components,
              ...updatedComponents.components
            }
          }
        }

      case 'SET_COMPONENT_DATA':
        if (screens[screenIndex].components[action.key]) {
          return {
            ...screens,
            [screenIndex]: {
              ...screens[screenIndex],
              components: {
                ...screens[screenIndex].components,
                [action.key]: {
                  ...screens[screenIndex].components[action.key],
                  data: action.data
                }
              }
            }
          }
        }
        return screens
        

      case 'UPDATE_COMPONENT_PROPERTY':
        if (screens[screenIndex].components[action.key]) {
          const properties = lodash.merge(screens[screenIndex].components[action.key].properties, action.attributes)
          return {
            ...screens,
            [screenIndex]: {
              ...screens[screenIndex],
              components: {
                ...screens[screenIndex].components,
                [action.key]: {
                  ...screens[screenIndex].components[action.key],
                  properties: properties
                }
              }
            }
          }
        }
        return screens

      case 'UPDATE_BASIC_INPUT_STYLE':
        if (screens[screenIndex].components[action.key]) {
          return {
            ...screens,
            [screenIndex]: {
              ...screens[screenIndex],
              components: {
                ...screens[screenIndex].components,
                [action.key]: {
                  ...screens[screenIndex].components[action.key],
                  inputStyle: action.styles
                }
              }
            }
          }
        }
        return screens

      case 'ADD_COMPONENT_ACTION':
        if (screens[screenIndex].components[action.key]) {
          return {
            ...screens,
            [screenIndex]: {
              ...screens[screenIndex],
              components: {
                ...screens[screenIndex].components,
                [action.key]: {
                  ...screens[screenIndex].components[action.key],
                  actions: {
                    ...screens[screenIndex].components[action.key].actions,
                    [action.action]: {
                      active: true,
                      data: action.data
                    }
                  }
                }
              }
            }
          }
        }
        return screens
        

      case 'COMPLETE_COMPONENT_ACTION':
        if (screens[screenIndex].components[action.key]) {
          return {
            ...screens,
            [screenIndex]: {
              ...screens[screenIndex],
              components: {
                ...screens[screenIndex].components,
                [action.key]: {
                  ...screens[screenIndex].components[action.key],
                  actions: {
                    ...screens[screenIndex].components[action.key].actions,
                    [action.action]: {
                      active: false,
                      data: undefined
                    }
                  }
                }
              }
            }
          }
        }
        return screens
        
      case 'SET_SHOW_WINDOW':
        return {
          ...screens,
          [screenIndex]: {
            ...screens[screenIndex],
            showWindow: action.showWindow
          }
        }
      default:
        return screens;
    }
  }

  const unumReducerSolutions = (solutions: { [id: string]: ISolution } = {}, action: any) => {
    let newSolutions: { [id: string]: ISolution } = {}
    switch (action.type) {
      case 'SET_SOLUTIONS':
        Object.keys(action.solutions).forEach(element => {
          let menu: Array<IUnumMenuItem> = solutions[element]?.menu
          if (!Array.isArray(menu)) {
            menu = []
          }
          newSolutions[element] = {
            menu,
            unumSolution: action.solutions[element].unumSolution
          }
        })
        return newSolutions

      case 'SET_SOLUTION_MENU'://action.cdSolution, action.menu
        newSolutions = {
          ...solutions,
          [action.cdSolution]: {
            ...solutions[action.cdSolution],
            menu: action.menu
          }
        }
        return newSolutions
      default:
        return solutions
    }
  }

  const appReducer = (app: any, action: any) => {
    switch (action.type) {
      case 'SET_CURRENT_SOLUTION':
        return {
          ...app,
          currentSolution: action.cdSolution
        }
      case 'SET_CURRENT_PROJECT':
        return {
          ...app,
          currentProject: action.cdProject
        }
      case 'SET_CURRENT_SCREEN':
        return {
          ...app,
          currentScreen: action.cdScreen
        }
      default:
        return app
    }
  }

  const defaultReducer = (state: any, action: any) => {
    return state
  }

  return createStore(
    (state: any = {}, action: any) => {
      return {
        screens: unumReducerScreens(state.screens, action),
        providerServices: defaultReducer(state.providerServices, action),
        utilities: defaultReducer(state.utilities, action),
        defaultConfiguration: defaultReducer(state.defaultConfiguration, action),
        solutions: unumReducerSolutions(state.solutions, action),
        app: appReducer(state.app, action)
      }
    },
    initialState,
    applyMiddleware(
      thunkMiddleware
    ))
}