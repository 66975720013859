import React, { useEffect, useState } from 'react'
import { onMountedScreen, setCurrentSolution, menuItemsByUserAndSolution, setCurrentProject, setCurrentScreen } from '../store/unumApp/actions/index'
import {
  IonContent,
  IonPage,
  IonProgressBar
} from '@ionic/react'
import { connect, useDispatch } from 'react-redux';
import HeaderUnun from '../components/header/HeaderUnun'
import Screen from '../components/unumApp/Screen'
import { IState,
IUnumSolution} from '../utilities/types';
import { Capacitor } from '@capacitor/core';
import { menuController } from '@ionic/core/components'

const PageView: React.FC = (props: any) => {
  const dispatch = useDispatch()

  const [screen, setScreen] = useState<any>(props.screen || {})
  const [unumSolution, setUnumSolution] = useState<IUnumSolution>(props.unumSolution || {})
  const [isLoading, setIsLoading] = useState<any>(false)

  useEffect(() => {
    if (Capacitor.isNative) {
      const listener = (ev: any) => {
        ev.detail.register(1, () => {
          menuController.open()
        })
      }
      document.addEventListener('ionBackButton', listener)
      return () => {
        document.removeEventListener('ionBackButton', listener)
      }
    }
  }, [])

  useEffect(() => {
    if (screen.cdProject !== props.screen.cdProject || screen.cdScreen !== props.screen.cdScreen) {
      setIsLoading(true)
      let i = setInterval(() => {
        setScreen(props.screen)
        setIsLoading(false)
        clearInterval(i)
      }, 1000);
    }
  }, [props.screen, screen])

  useEffect(() => {
    setUnumSolution(props.unumSolution)
  }, [props.unumSolution])

  useEffect(() => {
    const paramsLocation = props.location.pathname.split('/')
    if (paramsLocation[1] === 'views' && paramsLocation[2] && paramsLocation[3] && paramsLocation[4]) {
      const cdSolution = paramsLocation[2]
      const cdProjectAux = paramsLocation[3]
      const cdScreenAux = paramsLocation[4]
      if (cdSolution && cdProjectAux && cdScreenAux) {
        dispatch(setCurrentSolution(cdSolution))
        dispatch(setCurrentProject(cdProjectAux))
        dispatch(setCurrentScreen(cdScreenAux))
        dispatch(menuItemsByUserAndSolution(cdSolution))
        dispatch(onMountedScreen(cdSolution, cdProjectAux, cdScreenAux))
      }
    }
  }, [dispatch, props.location.pathname])


  return (
    <IonPage>
      <HeaderUnun title={screen.dsScreen} unumSolution={unumSolution} />
      <IonContent>
        {
          !isLoading?(
            <Screen
              cdProject={screen.cdProject}
              cdScreen={screen.cdScreen}
            />
          ):(
            <IonProgressBar color="medium" type="indeterminate"></IonProgressBar>
          )
        }
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: IState) => {
  const screens = state.screens
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === state.app.currentProject
    const isSameScreen = screens[item].cdScreen === state.app.currentScreen
    return isSameProject && isSameScreen
  })
  const screen = screens[screenIndex] || {}
  const solution = state.solutions[state.app.currentSolution]

  return {
    screen: {
      cdProject: screen.cdProject,
      cdScreen: screen.cdScreen,
      dsScreen: screen.dsScreen,
    },
    unumSolution: solution?.unumSolution || {}
  }
}
export default connect(mapStateToProps)(PageView);