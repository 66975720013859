export default {
    inputs: {
        required: () => {
            return 'Debes de ingresar todos los campos marcados con (*).'
        },
        pattern: () => {
            return 'Debes de ingresar todos los campos en el formato indicado.'
        },
        maxLength: (maxLength) => {
            return `Debes de ingresar máximo ${maxLength} caracteres.`
        },
        minLength: (minLength) => {
            return `Debes de ingresar mínimo ${minLength} caracteres.`
        },
        max: (max) => {
            return `Debes de ingresar máximo ${max}.`
        },
        min: (min) => {
            return `Debes de ingresar mínimo ${min}.`
        },
    },
    form: {
        invalidInput: () => {
            return 'Las entradas marcadas deben de tener el formato indicado.'
        },
        confirm: () => {
            return 'Esta seguro que desea realizar la accion?.'
        },
        confirmLabel: () => {
            return 'Confirmar'
        },
    }
}