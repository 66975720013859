import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  IonButton,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonNote
} from '@ionic/react';
import {
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css'
import {
  informationCircleOutline,
  fileTrayFullOutline,
  save,
  trashOutline,
  imagesOutline
} from 'ionicons/icons';
import { inputChange, executeButton, refreshOthersApply, setInputValueState, setMessages, completeComponentAction } from '../../../../store/unumApp/actions/index'
import { IState, IUnumFile, IUnumSolution } from '../../../../utilities/types'
import {
  Capacitor
} from '@capacitor/core'
import utilities from '../../../../utilities';
import bytes from 'bytes'
import { SET_FOCUS_ACTION, SHOW_VALIDATION_ERROR_ACTION } from '../../../../store/unumApp/utilities';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem'

const File = (props: any) => {
  const [properties, setProperties] = useState<IUnumFile>(props.inputState.properties || {})
  const [errorStyle, setErrorStyle] = useState<any>(props.inputState.errorStyle || {})
  const [initialStyle, setInitialStyle] = useState<any>(props.inputState.initialStyle || {})
  const [style, setStyle] = useState<any>(props.inputState.initialStyle || {})
  let dispatch = useDispatch()
  const [valueState, setValueState] = useState<any>(props.inputState.valueState)
  const [value, setValue] = useState<any>(props.inputState.value)
  const [message, setMessage] = useState<string>('')
  let inputRef = useRef<any>(null)
  const [primaryColor, setPrimaryColor] = useState<string>('')
  //const [secundaryColor, setSecundaryColor] = useState<string>('')

  console.log('FILEPROPS',props)
  useEffect(() => {
    const unumSolution: IUnumSolution = props.unumSolution
    setPrimaryColor(unumSolution.cdPrincipalColor || '')
    //setSecundaryColor(unumSolution.cdSecundaryColor || '#fff')
  }, [props.unumSolution])

  useEffect(() => {
    const actions = props.inputState.actions
    if (actions && actions[SHOW_VALIDATION_ERROR_ACTION] && actions[SHOW_VALIDATION_ERROR_ACTION].active) {
      setStyle(errorStyle)
      setMessage(actions[SHOW_VALIDATION_ERROR_ACTION].data)
      dispatch(completeComponentAction(props.cdProject, props.cdScreen, props.info.key, SHOW_VALIDATION_ERROR_ACTION))
    } else if (actions && actions[SET_FOCUS_ACTION] && actions[SET_FOCUS_ACTION].active) {
      /*if (!properties.convertPdf) {
        inputRef.current.click()
      } else {
        //handleRequestPicture()
      }*/
      dispatch(completeComponentAction(props.cdProject, props.cdScreen, props.info.key, SET_FOCUS_ACTION))
    }
  }, [props.inputState.actions, props.info.key, props.cdProject, props.cdScreen, dispatch, errorStyle])


  useEffect(() => {
    setValue(props.inputState.value)
  }, [props.inputState.value])

  useEffect(() => {
    setValueState(props.inputState.valueState)
  }, [props.inputState.valueState])

  useEffect(() => {
    if (props.inputState.errorStyle) {
      setErrorStyle(props.inputState.errorStyle)
    }
  }, [props.inputState.errorStyle])

  useEffect(() => {
    if (props.inputState.initialStyle) {
      setInitialStyle(props.inputState.initialStyle)
    }
  }, [props.inputState.initialStyle])

  useEffect(() => {
    if (props.inputState.properties) {
      setProperties(props.inputState.properties)
    }
  }, [props.inputState.properties])

  const handleFileChange = () => {
    var file = inputRef.current.files[0];
    const isValid = validateInput(file)
    if (file) {
      const maxSize = bytes(properties.fileMaxSize)
      const minSize = bytes(properties.fileMinSize)
      if (file.size > maxSize) {
        showMessage('No se adjunto el archivo pues se excedió el peso máximo')
        return
      } else if (file.size < minSize) {
        showMessage('No se adjunto el archivo pues no supero peso mínimo')
        return
      }
    }
    props.dispatch(
      inputChange(
        props.cdProject,
        props.cdScreen,
        properties.key,
        file,
        isValid
      )
    )
    props.dispatch(
      setInputValueState(
        props.cdProject,
        props.cdScreen,
        properties.key,
        file
      )
    )
    //onTriggerChange
    const onTriggerChange = properties.onTriggerChange || ''
    if (onTriggerChange) {
      props.dispatch(executeButton(
        props.cdProject,
        props.cdScreen,
        onTriggerChange
      ))
    }
  }

  const handleRefreshOthersApply = () => {
    const refreshOthers = properties.refreshOthers || []
    if (refreshOthers.length > 0) {
      props.dispatch(refreshOthersApply(
        props.cdProject,
        props.cdScreen,
        refreshOthers
      ))
    }
  }

  const showMessage = (msg: string) => {
    const messages = [{
      title: '',
      showCloseButton: true,
      allowOutsideClick: true,
      text: msg
    }]
    props.dispatch(setMessages(
      props.cdProject,
      props.cdScreen,
      messages
    ))
  }

  const validateInput = (val: any) => {
    //Validacion
    if (properties.validateOn === "submit" && style === errorStyle) {
      setStyle(initialStyle)
      setMessage('')
      //setShowMessage(false)
    } else if (properties.validateOn === "change") {
      const validationError = utilities.validate(properties, val)
      if (validationError) {
        setMessage(validationError)
        setStyle(errorStyle)
        return false
      } else {
        setMessage('')
        setStyle(initialStyle)
        return true
        //setShowMessage(false)
      }
    } else {
      setMessage('')
    }
    return true
  }

  const refreshReport = async (newReportState: any) => {
    const pdfBase64 = properties.imgCapture.camera ? await utilities.reducePicture(newReportState, properties.resolution) : await utilities.getPdfBase64(newReportState)
    const pdfBlob = await utilities.b64toBlob(pdfBase64, 'application/pdf')
    const maxSize = bytes(properties.fileMaxSize)
    const isValid = validateInput(pdfBlob)
    if (pdfBlob.size > maxSize) {
      showMessage('No se adjunto la imagen pues excedió el peso máximo')
      return
    }
    props.dispatch(
      inputChange(
        props.cdProject,
        props.cdScreen,
        properties.key,
        pdfBlob,
        isValid
      )
    )
    props.dispatch(
      setInputValueState(
        props.cdProject,
        props.cdScreen,
        properties.key,
        newReportState
      )
    )
  }

  const handleRemoveImage = (imageIndex: any) => {
    setMessage('')
    if (Capacitor.getPlatform() !== "web") {
      const path = utilities.uriToNameFile(valueState[imageIndex])
      Filesystem.deleteFile({
        path: path,
        directory: Directory.Documents
      });
    }
    refreshReport(valueState.filter((item: any, index: any) => index !== imageIndex))
  }

  const handleCancelPicturesRequest = () => {
    const isValid = validateInput(undefined)
    if (Capacitor.getPlatform() !== "web") {
      valueState.forEach((imageUri: any) => {
        const path = utilities.uriToNameFile(imageUri)
        Filesystem.deleteFile({
          path: path,
          directory: Directory.Documents
        });
      });
    }
    props.dispatch(
      inputChange(
        props.cdProject,
        props.cdScreen,
        properties.key,
        undefined,
        isValid
      )
    )
    props.dispatch(
      setInputValueState(
        props.cdProject,
        props.cdScreen,
        properties.key,
        []
      )
    )
  }

  // capacitor camera sin conversion
  const handleRequestPicture = async () => {
    setMessage('')
    const path = `${Date.now()}.jpeg`
    try {
      const originalPhoto = await Camera.getPhoto({
        allowEditing: false,
        saveToGallery: false,
        resultType: CameraResultType.DataUrl,
        source: properties.imgCapture.camera && properties.imgCapture.storedFiles ? CameraSource.Prompt : properties.imgCapture.camera ? CameraSource.Camera : CameraSource.Photos
      })
      const img = await utilities.reducePicture(originalPhoto.dataUrl, properties.resolution)
      let savedPhotoFile
      if (Capacitor.getPlatform() === "web") {
        savedPhotoFile = img;
      } else {
        await Filesystem.writeFile({
          path: path,
          data: img,
          directory: Directory.Documents
        })
        const res = await Filesystem.getUri({
          path: path,
          directory: Directory.Documents
        })
        savedPhotoFile = Capacitor.convertFileSrc(res.uri);
      }
      const newReportState = valueState.concat([savedPhotoFile])
      refreshReport(newReportState)
    } catch (error) {
      console.log(error);
    }
  }
  const popover = (
    <Popover id={`popover-${properties.key}`} className="unum-font-size-normal">
      <Popover.Body>
        {properties.tooltip}
      </Popover.Body>
    </Popover>
  );

  const attachedFiles = (
    valueState && !properties.convertPdf ? (
      <div>
        {valueState.name}
      </div>
    ) : null
  )


  const viewRequestFile = (
    <div
      onClick={() => {
        inputRef.current.click()
      }}
      className="border border-dark border-dashed px-3 py-2 d-flex flex-column"
    >
      <div className="d-flex align-items-center">
        <IonIcon
          style={{
            color: primaryColor
          }}
          icon={fileTrayFullOutline}
          size='large'
          className="mr-2"
        />
        <span className="unum-font-size-small">Selecciona un archivo.</span>
        {
          value ? (
            <div
              onClick={(e) => {
                e.stopPropagation()
              }}
              className="border ml-2 d-flex justify-content-center align-items-center">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                rootClose={true}
                overlay={
                  <Popover id={`popover-${properties.key}`} className="unum-font-size-small">
                    <Popover.Header as="h3">Archivos adjuntos</Popover.Header>
                    <Popover.Body>
                      {attachedFiles}
                    </Popover.Body>
                  </Popover>
                }
              >
                <IonIcon
                  icon={save}
                  size='large'
                  style={{
                    color: primaryColor
                  }}
                />
              </OverlayTrigger>
            </div>
          ) : (
            <div
              className="p-3 ml-2 border"
            />
          )
        }
      </div>
    </div>
  )

  const viewRequestPicture = properties.imgCapture ? (
    <div className="d-flex flex-column">
      <div>
        {
          Array.isArray(valueState) ? (
            valueState.map((e: any, i: any) => {
              return (
                <IonItem key={i} className="py-1 position-relative bg-transparent">
                  <IonButton
                    color="danger"
                    className="position-absolute top-0 rigth-0"
                    onClick={() => { handleRemoveImage(i) }}
                  >
                    <IonIcon slot="icon-only" icon={trashOutline} />
                  </IonButton>
                  <IonImg src={Capacitor.convertFileSrc(e)} className="mx-auto mb-2"></IonImg>
                </IonItem>
              )
            })
          ) : null
        }
      </div>
      <div
        onClick={handleRequestPicture}
        className="border border-dark border-dashed px-3 py-2">
        <div className="d-flex align-items-center">
          <IonIcon
            style={{
              color: primaryColor
            }}
            icon={imagesOutline}
            size="large"
            className="mr-2"
          />
          <div>
            <span className="unum-font-size-small">Cargar fotos.</span>
          </div>
        </div>
      </div>
      {
        Array.isArray(valueState) ? (
          valueState.length > 0 ? (
            <IonButton
              expand="block"
              className="unum-font-size-large"
              color="danger"
              onClick={handleCancelPicturesRequest}
            >
              Cancelar
            </IonButton>
          ) : null

        ) : null
      }

    </div>
  ) : null

  return (
    <IonItem id={properties.key} lines="none" className="mb-2">
      {
        !properties.hidden ? (
          <div
            className="d-flex w-100 flex-column justify-content-center"
          >
            <div
              className="d-flex flex-column"
              style={style.item}
            >
              <span
                className="d-flex mw-100"
              >
                <IonLabel
                  className="d-flex align-items-center white-space-normal w-100 unum-font-size-normal"
                  style={style.label}
                >
                  <span>{`${properties.label} ${properties.validate?.required ? '*' : ''}`}</span>
                </IonLabel>
                {
                  properties.tooltip ? (
                    <div style={{ zIndex: 10 }} className="px-1 d-flex align-items-center">
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={popover}
                        rootClose={true}
                      >
                        <div className="d-flex align-items-center">
                          <IonIcon icon={informationCircleOutline} size="small" />
                        </div>
                      </OverlayTrigger>
                    </div>
                  ) : null
                }
              </span>
              {
                properties.imgCapture ? (viewRequestPicture) : properties.convertPdf ? (viewRequestFile) : (viewRequestPicture)
              }
              <input
                ref={inputRef}
                style={style}
                className="d-none"
                placeholder={properties.placeholder}
                disabled={properties.disabled}
                readOnly={properties.readOnly}
                type="file"
                onChange={handleFileChange}
                onBlur={() => {
                  handleRefreshOthersApply()
                }}
                accept={Array.isArray(properties.filePattern) ? properties.filePattern.join() : ''}
              />
            </div>
            {
              !!message ? (
                <IonNote>{message}</IonNote>
              ) : null
            }
          </div>
        ) : null
      }
    </IonItem>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen
    return isSameProject && isSameScreen
  })
  const screen = screens[screenIndex] || {}
  const components = screen.components || {}
  const solution = state.solutions[state.app.currentSolution]
  return {
    inputState: components[ownProps.info.key] || {},
    unumSolution: solution?.unumSolution || {}
  }
}

export default connect(mapStateToProps)(File)