import { IBasicInputProperties, IUnumBasicInput } from '../../../utilities/types'
import utilities from '../../../utilities'
import { SHOW_VALIDATION_ERROR_ACTION } from '.'
import moment from 'moment'

export const labelPositions: any = {
  'left-right': 'stacked',//fixed
  'left-left': 'stacked',
  'top': 'stacked',
  'bottom': 'floating'
}
export const checkLabelPositions: any = {
  left: 'start',
  right: 'end'
}

export const BASIC_INPUT_TYPES: Array<string> = ['textfield', 'textarea', 'number', 'datetime', 'checkbox']

const getDate = (datePicker: any) => {
  switch (datePicker.operator) {
    case '+':
      return moment().add(datePicker.value, datePicker.time).format('YYYY-MM-DD')
    case '-':
      return moment().subtract(datePicker.value, datePicker.time).format('YYYY-MM-DD')
    case 'cus':
      const wrap = () => `{ return function( moment ){ return ${datePicker.custom}.format(${datePicker.format}) } };`
      // eslint-disable-next-line no-new-func
      const func = new Function(wrap());
      return func.call(null).call(null, moment)
    default:
      return null;
  }
}

export function formatDataPiker(datePicker: any) {
  if (!datePicker) return
  let res = datePicker
  if (datePicker.maxDate)
    res.maxDate = getDate(datePicker.maxDate)
  if (datePicker.minDate)
    res.minDate = getDate(datePicker.minDate)
  return res
}


export function getInputProps(customProps: any, configuration: any) {
  let inputProps: any = {}
  const customComponentProps = configuration.components.inputs[customProps.type] ? configuration.components.inputs[customProps.type][customProps.customComponentId] ? configuration.components.inputs[customProps.type][customProps.customComponentId] : {} : {}
  const defaultUNUMTypeProps = configuration.components.inputs[customProps.type] ? configuration.components.inputs[customProps.type].default ? configuration.components.inputs[customProps.type].default : {} : {}
  const defaultInputTypeProps = configuration.components.inputs.default[customProps.inputType] ? configuration.components.inputs.default[customProps.inputType] : {}
  const defaultProps = configuration.components.inputs.default.default
  const reqProps = ['key', 'type', 'input']
  const confPropByTheme = ['debounce']
  const confPropByUNUMType = [
    'labelPosition', 'validateOn', 'inputType',
    'autoComplete', 'inputMode', 'labelStyle', 'fieldStyle',
    'validate',
    'mask',
    'inputMask',/* text */
    'editor', 'rows',/* textarea */
    'format',/* datetime */
    'datePicker',/* datetime */
    'prefixuNm', 'suffixNum', 'decimalLimit', 'decimalSymbol', 'thousandsSeparator', /* number */
    'valueOff', 'valueOn'/* checkbox */
  ]
  const confPropByType = [
    'clearInput',
    'min', 'max'/* Numbers */
  ]
  const confPropByCustomComponent = [
    'label', 'placeholder', 'tooltip', 'defaultValue',
    'enterKeyHint', 'autoCapitalize', 'autoCapitalize'
  ]
  const confPropByCustom = [
    'hidden', 'disabled', 'autofocus', 'readOnly', 'onTriggerChange',
    'refresWhen', 'refreshTo', 'refreshOthers'
  ]
  //inputProps['validate'] = Object.keys(customProps['validate'] || {}).length ? customProps['validate'] : Object.keys(customComponentProps['validate'] || {}).length ? customComponentProps['validate'] : defaultInputTypeProps.validate ? defaultInputTypeProps.validate : {}
  reqProps.forEach(prop => {
    inputProps[prop] = customProps[prop]
  })
  confPropByTheme.forEach(prop => {
    inputProps[prop] = customProps[prop] !== undefined && customProps[prop] !== null ? customProps[prop] :
      customComponentProps[prop] !== undefined && customComponentProps[prop] !== null ? customComponentProps[prop] :
        defaultInputTypeProps[prop] !== undefined && defaultInputTypeProps[prop] !== null ? defaultInputTypeProps[prop] :
          defaultUNUMTypeProps[prop] !== undefined && defaultUNUMTypeProps[prop] !== null ? defaultUNUMTypeProps[prop] :
            defaultProps[prop]
  })
  confPropByUNUMType.forEach(prop => {
    inputProps[prop] = customProps[prop] !== undefined && customProps[prop] !== null && customProps[prop] !== '' ? customProps[prop] :
      customComponentProps[prop] !== undefined && customComponentProps[prop] !== null && customComponentProps[prop] !== '' ? customComponentProps[prop] :
        defaultInputTypeProps[prop] !== undefined && defaultInputTypeProps[prop] !== null && defaultInputTypeProps[prop] !== '' ? defaultInputTypeProps[prop] :
          defaultUNUMTypeProps[prop]
  })
  confPropByType.forEach(prop => {
    inputProps[prop] = customProps[prop] !== undefined && customProps[prop] !== null ? customProps[prop] :
      customComponentProps[prop] !== undefined && customComponentProps[prop] !== null ? customComponentProps[prop] :
        defaultInputTypeProps[prop]
  })
  confPropByCustomComponent.forEach(prop => {
    inputProps[prop] = customProps[prop] !== undefined && customProps[prop] !== null ? customProps[prop] :
      customComponentProps[prop]
  })
  confPropByCustom.forEach(prop => {
    inputProps[prop] = customProps[prop]
  })
  if (inputProps.defaultValue === undefined) {
    inputProps.defaultValue = ''
  }
  //DateTime
  inputProps.datePicker = formatDataPiker(inputProps.datePicker)
  if (inputProps.format) {
    inputProps.format = inputProps.format.toString().replaceAll('y', 'Y').replaceAll('d', 'D').replaceAll('H', 'h').replaceAll('S', 's')
  }
  if (inputProps.type === 'number') {
    inputProps.inputMode = 'tel'
    inputProps.inputType = 'text'
    /*inputProps.inputMask = createNumberMask({
      prefix: inputProps.prefixuNm,
      suffix: inputProps.suffixNum
    })*/

  }
  const isCheckbox = inputProps.type === 'checkbox'
  if (!isCheckbox) {
    inputProps.labelPosition = 'top'
  }
  return inputProps
}

export function getErrorStyle(properties: IBasicInputProperties) {
  const labelStyle: any = properties.labelStyle
  const fieldStyle: any = properties.fieldStyle
  const type = properties.type
  const maxWidth = !['top', 'bottom'].includes(properties.labelPosition?.split('-')[0]) ? properties.type === 'checkbox' ? '100%' : '50%' : 'unset'
  const isDisabled = properties.readOnly || properties.disabled
  const justifyContent = labelStyle?.alignment === 'right' ? 'flex-end' : labelStyle?.alignment
  return {
    item: {
      '--highlight-color-focused': 'red',
      '--color-focused': 'red',
      '--border-color': 'red'
    },
    label: {
      textDecoration: labelStyle?.underlined ? 'underline' : '',
      fontStyle: labelStyle?.italics ? 'italic' : '',
      fontSize: labelStyle?.size ? `${labelStyle?.size}px` : '',
      fontFamily: labelStyle?.font,
      color: labelStyle?.color || '#000',
      justifyContent,
      maxWidth
    },
    input: {
      textDecoration: fieldStyle?.underlined ? 'underline' : '',
      fontStyle: fieldStyle?.italics ? 'italic' : '',
      fontSize: fieldStyle?.size ? `${labelStyle?.size}px` : '',
      fontFamily: fieldStyle?.font,
      textAlign: fieldStyle?.alignment,
      color: 'red',

      backgroundColor: isDisabled ? '#efefef' : undefined,
      border: 'solid thin red',
      width: type === 'checkbox' ? '' : '100%',
      '--padding-start': '0.3em',
      '--padding-end': '0.3em',
    }
  }
}

export function getCorrectStyle(properties: IBasicInputProperties) {
  const labelStyle: any = properties.labelStyle
  const fieldStyle: any = properties.fieldStyle
  //const lbPosition = labelPositions[properties.labelPosition]
  const type = properties.type
  const maxWidth = !['top', 'bottom'].includes(properties.labelPosition?.split('-')[0]) ? properties.type === 'checkbox' ? '100%' : '50%' : 'unset'
  const isDisabled = properties.readOnly || properties.disabled
  const inputColor = isDisabled ? '#000' : fieldStyle?.color || '#000'
  const justifyContent = labelStyle?.alignment === 'right' ? 'flex-end' : labelStyle?.alignment
  return {
    item: {
      '--highlight-color-focused': labelStyle?.color || '#000',
      '--color-focused': labelStyle?.color || '#000'
    },
    label: {
      textDecoration: labelStyle?.underlined ? 'underline' : '',
      fontStyle: labelStyle?.italics ? 'italic' : '',
      fontSize: labelStyle?.size ? `${labelStyle?.size}px` : '',
      fontFamily: labelStyle?.font,
      color: labelStyle?.color || '#000',
      justifyContent,
      maxWidth
    },
    input: {
      textDecoration: fieldStyle?.underlined ? 'underline' : '',
      fontStyle: fieldStyle?.italics ? 'italic' : '',
      fontSize: fieldStyle?.size ? `${fieldStyle?.size}px` : '',
      fontFamily: fieldStyle?.font,
      textAlign: fieldStyle?.alignment,
      color: inputColor,

      backgroundColor: isDisabled ? '#efefef' : undefined,
      border: 'solid thin #b3b3b3',
      width: type === 'checkbox' ? '' : '100%',
      '--padding-start': '0.3em',
      '--padding-end': '0.3em',
    }
  }
}

export function validateInputs(button: any, components: { [id: string]: any; }) {
  let isValid = true
  let newInputs: any = {}
  //Verificacion de entradas validateOn === "change" && "submit"
  //En caso de no haber renderizado inputs,  este metodo se encarga de setear los valores predeterminados
  Object.keys(components).forEach((inputComponentKey: string) => {
    const inputComponent: any = components[inputComponentKey]
    if (!inputComponent || !inputComponent.properties || !inputComponent.properties.input) return
    if (inputComponent.properties.type === 'file') {
      newInputs = {
        ...newInputs,
        [inputComponentKey]: inputComponent
      }
    } else {
      let newInput: any = {
        ...inputComponent,
        isInitial: false
      }
      let inputValue = components[inputComponent.properties.key].value === undefined || components[inputComponent.properties.key].value === null ? inputComponent.properties.defaultValue : components[inputComponent.properties.key].value
      if (inputComponent.properties.type === 'number') {
        inputValue = inputValue.toString().replace(inputComponent.properties.suffixNum, '')
        inputValue = inputValue.toString().replaceAll(inputComponent.properties.thousandsSeparator, '')
        inputValue = inputValue.toString().replace(inputComponent.properties.decimalSymbol, '.')
        inputValue = Number.parseFloat(inputValue)
        if (Number.isInteger(inputValue)) {
          inputValue = Number.parseInt(inputValue)
        }
        newInput = {
          ...newInput,
          value: inputValue
        }
      }
      if (button.buttonMap.validate) {
        const validationError = utilities.validate(inputComponent.properties, inputValue)
        newInput = {
          ...newInput,
          value: inputValue,
          isValid: !validationError
        }
        if (validationError) {
          isValid = false
          newInput.actions = {
            ...newInput.actions,
            [SHOW_VALIDATION_ERROR_ACTION]: {
              active: true,
              data: validationError
            }
          }
        }
      }
      newInputs = {
        ...newInputs,
        [inputComponentKey]: newInput
      }
    }
  })
  return {
    isValid: isValid,
    inputs: newInputs
  }
}

export function getCheckboxValue(type: string, value: any, valueOn: any, valueOff: any) {
  let checkVal = undefined
  if (type === 'checkbox') {
    if (value === valueOn || value === valueOff) {
      return value
    }
    if (value && valueOn !== undefined && valueOn !== '') {
      checkVal = valueOn
    } else if (!value && valueOff !== undefined && valueOff !== '') {
      checkVal = valueOff
    }
  }
  return checkVal
}

export function getBasicInputState(unumProps: IUnumBasicInput, defaultConfiguration: any) {
  let properties: IBasicInputProperties = getInputProps(unumProps, defaultConfiguration)
  const defaultValue: any = properties.defaultValue
  const initialStyle: any = getCorrectStyle(properties)
  const errorStyle: any = getErrorStyle(properties)
  let isValid = true
  if (properties.validateOn === "change") {
    const validationError = utilities.validate(properties, defaultValue)
    if (validationError) {
      isValid = false
    } else {
      isValid = true
    }
  }
  let newInputValue: any = defaultValue
  const checkVal = getCheckboxValue(
    properties.type,
    newInputValue,
    properties.valueOn,
    properties.valueOff
  )
  if (checkVal !== undefined) {
    newInputValue = checkVal
  }
  if (properties.type === 'file') {
    newInputValue = undefined
  }
  return {
    properties,
    value: newInputValue,
    valueState: undefined,
    errorStyle: errorStyle,
    initialStyle: initialStyle,
    isInitial: true,
    isValid,
    actions: {},
    inputStyle: {}
  }
}

export function getAutonumericParams(properties: any): any {
  return {
    digitGroupSeparator: properties.thousandsSeparator || '.',
    decimalCharacter: properties.decimalSymbol || ',',
    decimalPlaces: properties.decimalLimit,
    currencySymbol: typeof properties.suffixNum !== 'string' ? '' : properties.suffixNum,
    currencySymbolPlacement: 's'
  }
}

export function getInputMaskParams(properties: any): any {
  return {
    mask: properties.inputMask,
    positionCaretOnTab: false,
    jitMasking: true,//invisibilidad de la mascara
    greedy: false,//muestra la mascara mas grande
  }
}

export function getFormattedValue(val: any, properties: any) {
  let res = val
  if ((properties.inputMask || properties.type === 'number')) {
    if (properties.type === 'number') {
      res = utilities.AutoNumeric.format(res, getAutonumericParams(properties))
    } else {
      res = utilities.Inputmask.format(res, getInputMaskParams(properties))
    }
  }
  return res
}