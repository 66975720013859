import Axios from "axios";
import CryptoJS from "crypto-js";
import { Preferences as Storage } from "@capacitor/preferences";
import { Toast } from "@capacitor/toast";
import { Preferences } from "@capacitor/preferences";

/*let messages = require('./responseCodes.js')*/
const DEFAULT_ERROR_MSG = {
  message: "No se pudo ejecutar la acción solicitada.",
};

export default class ProviderService {
  constructor(initialAuthInfo) {
    this.initializeRootAPI();
    /*     this.rootAPI = process.env.REACT_APP_UNUN_HOST
     */ //this.removeSession()
    this.minimumRefreshTokenAttemptTime =
      process.env.REACT_APP_UNUN_MINIMUM_REFRESH_TOKEN_ATTEMPT_TIME;
    this.axios = Axios.create();
    this.isTokenRefresh = false;
    this.sessionInfo = initialAuthInfo;

    if (this.sessionInfo && !this.isLogged()) {
      return this.logout();
    }

    this.axios.interceptors.request.use(async (config) => {
      if (this.sessionInfo && !this.isLogged()) {
        this.removeSession();
        return this.logout();
      }
      if (this.isLogged()) {
        let timeAux = new Date(this.sessionInfo.tokenInitialTime);
        timeAux = new Date(this.sessionInfo.tokenInitialTime);
        timeAux.setMilliseconds(this.minimumRefreshTokenAttemptTime);
        const minimumRefreshTokenAttemptTime = timeAux.getTime();
        const timeNow = new Date(Date.now()).getTime();
        if (minimumRefreshTokenAttemptTime <= timeNow && !this.isTokenRefresh) {
          this.isTokenRefresh = true;
          try {
            await this.refreshToken();
            this.isTokenRefresh = false;
          } catch (error) {
            return this.logout();
          }
        }
        config.headers.Authorization = `${this.sessionInfo.tokenType} ${this.sessionInfo.securityToken}`;
      }
      return config;
    });

    this.axios.interceptors.response.use(
      async (response) => {
        if (response?.data?.error && response?.data?.error?.code) {
          return await this.interceptorResponseError(response);
        }
        return response;
      },
      async (err) => {
        const response = err.response;
        if (response?.data?.error && response?.data?.error?.code) {
          return await this.interceptorResponseError(response);
        }
        return err;
      }
    );
  }

  async initializeRootAPI() {
    try {
      const result = await Preferences.get({ key: "connectionURL" });
      this.rootAPI = result.value; /* || process.env.REACT_APP_UNUN_HOST; */
    } catch (error) {
      console.log("Error:", error);
      /*       this.rootAPI = process.env.REACT_APP_UNUN_HOST;
       */
    }
  }

  static async build() {
    try {
      const authInfo = await this.getSession();
      return new ProviderService(authInfo);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async interceptorResponseError(response) {
    if (
      response.data.error &&
      (response.data.error.code === 602 || response.data.error.code === 604)
    ) {
      const originalReq = response.config;
      if (this.isLogged()) {
        if (!this.isTokenRefresh) {
          this.isTokenRefresh = true;
          try {
            await this.refreshToken();
            this.isTokenRefresh = false;
            return this.axios(originalReq);
          } catch (error) {
            return this.logout();
          }
        } else {
          if (originalReq.url.toString().includes("/auth/token")) {
            return this.logout();
          }
          return new Promise((resolve, reject) => {
            setTimeout(async () => {
              const x = await this.axios(originalReq);
              if (x.data?.error?.code === 602 || x.data?.error?.code === 604) {
                return this.logout();
              }
              return x;
            }, 2000);
          });
        }
      }
    }
    return response;
  }

  responseToSessionInfo(response) {
    let sessionInfo = response;
    let timeAux = new Date(Date.now());
    sessionInfo.tokenInitialTime = timeAux.getTime();
    timeAux.setMilliseconds(sessionInfo.tokenExpiration);
    sessionInfo.tokenExpiration = timeAux.getTime();
    timeAux = new Date(Date.now());
    timeAux.setMilliseconds(sessionInfo.refreshTokenExpiration);
    sessionInfo.refreshTokenExpiration = timeAux.getTime();
    return sessionInfo;
  }

  tokenExpirationTime() {
    return this.sessionInfo.tokenExpiration - new Date(Date.now()).getTime();
  }

  refreshTokenExpirationTime() {
    return (
      this.sessionInfo.refreshTokenExpiration - new Date(Date.now()).getTime()
    );
  }

  isLogged() {
    //Retorna si se puede realizar un refresh token
    if (!this.sessionInfo) {
      return false;
    }
    return !!this.sessionInfo.securityToken;
  }

  deleteModel(route) {
    return this.axios.delete(`${this.rootAPI}/${route}`);
  }

  postModel(route, data = undefined) {
    this.initializeRootAPI();
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: `${this.rootAPI}${route}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.data) {
            return reject(DEFAULT_ERROR_MSG);
          }
          if (!response.data.success) {
            return reject(
              response.data && response.data.error
                ? response.data.error
                : DEFAULT_ERROR_MSG
            );
          }
          return resolve(response);
        })
        .catch((error) => {
          return reject(
            error.response?.data && error.response?.data?.error
              ? error.response.data.error
              : DEFAULT_ERROR_MSG
          );
        });
    });
  }

  postModelFormData(route, bodyFormData) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: `${this.rootAPI}${route}`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.data) {
            return reject(DEFAULT_ERROR_MSG);
          }
          if (!response.data.success) {
            return reject(response.data.error || DEFAULT_ERROR_MSG);
          }
          return resolve(response);
        })
        .catch((error) => {
          return reject(error.response.data.error || DEFAULT_ERROR_MSG);
        });
    });
  }

  patchModel(route, data) {
    return this.axios.patch(`${this.rootAPI}${route}`, data);
  }

  putModel(route, data) {
    return this.axios.patch(`${this.rootAPI}${route}`, data);
  }

  async getModel(route, query = undefined) {
    await this.initializeRootAPI();
    let filter =
      query === null || query === undefined
        ? ""
        : `/?filter=${JSON.stringify(query)}`;
        console.log('THIS ROOT GETMODEL',this.rootAPI)
    return await this.axios.get(`${this.rootAPI}${route}${filter}`);
  }

  async logout() {
    await Toast.show({
      text: "Se va a cerrar la sesión.",
      duration: "short",
      position: "top",
    });
    setInterval(() => {
      this.removeSession();
      return window.location.reload();
    }, 2000);
  }

  async existProject() {
    const resul = await this.axios({
      method: "get",
      url: `https://backend.missingpets.art/UnumWebP/config/existProject`,
      headers: { "Content-Type": "application/json" },
    });
  }
  async loginTest() {
    const resul = await this.axios({
      method: "POST",
      url: `https://backend.missingpets.art/UnumWebP/auth/login`,
      headers: { "Content-Type": "application/json" },
      data: {
        username: "ldelgado",
        password: "Lpa23291",
      },
    });
  }
  async login(username, password, isRememberPassword) {
    this.initializeRootAPI();
    return new Promise((resolve, reject) => {
      this.axios
        .post(`${this.rootAPI}/auth/login`, {
          username,
          password,
        })
        .then((response) => {
          if (!response.data.success)
            return reject(response.data.error || DEFAULT_ERROR_MSG);
          let sessionInfo = this.encrypt(
            this.responseToSessionInfo(response.data)
          );
          this.saveSession(sessionInfo)
            .then(() => {
              const rememberProcess = async () => {
                if (isRememberPassword) {
                  await Storage.set({
                    key: "rememberCredentials",
                    value: this.encrypt({ username, password }),
                  });
                } else {
                  await Storage.remove({ key: "rememberCredentials" });
                }
              };
              rememberProcess().then(() => {
                window.location.reload();
                resolve();
              });
            })
            .catch((error) => {
              console.log(error);
              reject(DEFAULT_ERROR_MSG);
            });
        })
        .catch((error) => {
          console.log(error);
          reject(error.response?.data?.error || DEFAULT_ERROR_MSG);
        });
    });
  }

  async getRememberCredentials() {
    try {
      const { value } = await Storage.get({ key: "rememberCredentials" });
      return this.decrypt(value);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  saveSession(authInfo) {
    return Storage.set({
      key: "auth",
      value: authInfo,
    });
  }

  static async getSession() {
    try {
      const { value } = await Storage.get({ key: "auth" });
      return this.decrypt(value);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  removeSession() {
    this.isTokenRefresh = false;
    this.sessionInfo = null;
    return Storage.remove({ key: "auth" });
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      if (!this.isLogged()) {
        reject();
      }
      this.postModel("/auth/token", {
        token: `${this.sessionInfo.tokenType} ${this.sessionInfo.refreshToken}`,
      })
        .then((response) => {
          if (!response?.data) {
            reject(DEFAULT_ERROR_MSG);
            return;
          }
          const authInfo = this.responseToSessionInfo(response.data);
          this.sessionInfo = authInfo;
          this.saveSession(this.encrypt(authInfo))
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  encrypt(data, key = process.env.REACT_APP_UNUN_SECRET_KEY) {
    if (!data) {
      return null;
    }
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  }

  static decrypt(data, key = process.env.REACT_APP_UNUN_SECRET_KEY) {
    if (!data) {
      return null;
    }
    return JSON.parse(
      CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8)
    );
  }

  decrypt(data, key = process.env.REACT_APP_UNUN_SECRET_KEY) {
    if (!data) {
      return null;
    }
    return JSON.parse(
      CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8)
    );
  }
}
