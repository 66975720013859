import { forIn, isEmpty } from "lodash";
import {
  IUnumSolution,
  ISolution,
  IState,
  IUnumMenuItem,
  IUnumScreen,
} from "../../../utilities/types";
import { getInitialScreenState } from "../utilities";
import { PANEL_COMPONENT_TYPE } from "../utilities/containersUtils";
import { inputChange } from "./screenComponentsActions/transversalComponentsActions";

export function setSolutions(solutions: { [id: string]: ISolution }) {
  return {
    type: "SET_SOLUTIONS",
    solutions,
  };
}

export function onMountedAppForUserLogged() {
  return (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      const state: IState = getState();
      const solutions = state.solutions;
      const providerServices = state.providerServices;
      if (Object.keys(solutions).length === 0) {
        try {
          const response = await providerServices.getModel(
            "/solution/solutionsByUser" /*"/project/projectsByUser"*/
          );
          const unumSolutions: Array<IUnumSolution> = response?.data
            ? response.data.solutions
            : [];
          let newSolutions: { [id: string]: ISolution } = unumSolutions.reduce(
            (
              accumulator: { [id: string]: ISolution },
              currentValue: IUnumSolution
            ) => {
              return {
                ...accumulator,
                [currentValue.cdSolution]: {
                  unumSolution: currentValue,
                  menu: [],
                },
              };
            },
            {}
          );
          dispatch(setSolutions(newSolutions));
          resolve(null);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      }
    });
  };
}

export function setSolutionMenu(
  cdSolution: string,
  menu: Array<IUnumMenuItem>
) {
  return {
    type: "SET_SOLUTION_MENU",
    cdSolution,
    menu,
  };
}

export function setCurrentSolution(cdSolution: string) {
  return {
    type: "SET_CURRENT_SOLUTION",
    cdSolution,
  };
}

export function setCurrentProject(cdProject: string) {
  return {
    type: "SET_CURRENT_PROJECT",
    cdProject,
  };
}

export function setCurrentScreen(cdScreen: string) {
  return {
    type: "SET_CURRENT_SCREEN",
    cdScreen,
  };
}

export const addScreen = (
  cdSolution: string,
  cdProject: string,
  cdScreen: string,
  screenParam: IUnumScreen,
  defaultConfiguration: any
) => {
  let screen = {
    ...screenParam,
  };
  if (screen.screenMap.components[0].type === PANEL_COMPONENT_TYPE) {
    screen.screenMap.components[0].title = "";
    screen.screenMap.components[0].label = "";
  }
  const initialScreenState = getInitialScreenState(
    cdSolution,
    cdProject,
    cdScreen,
    screen,
    defaultConfiguration
  );
  return {
    type: "ADD_SCREEN",
    cdProject,
    cdScreen,
    screen: initialScreenState,
  };
};

export function onMountedScreen(
  cdSolution: string,
  cdProject: string,
  cdScreen: string
) {
  return (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      const state: IState = getState();
      const defaultConfiguration = state.defaultConfiguration;
      const providerServices = state.providerServices;
      try {
        const { data } = await providerServices.postModel(
          "/screen/getScreenByScreenNameAndProject",
          {
            cdProject,
            cdScreen,
            ip: "",
            params: "",
          }
        );
        const screen = data.screen;
        const { dataForm } = data.scriptResponse;
        dispatch(
          addScreen(
            cdSolution,
            cdProject,
            cdScreen,
            screen,
            defaultConfiguration
          )
        );
        if (!isEmpty(dataForm)) {
          forIn(dataForm, (value, key) => {
            dispatch(inputChange(cdProject, cdScreen, key, value, true));
          });
        }
        return resolve(null);
      } catch (error) {
        console.log(error);
        reject(error);
      }
      resolve(null);
    });
  };
}

export function menuItemsByUserAndSolution(cdSolution: string) {
  return (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      const state: IState = getState();
      const solution: ISolution = state.solutions[cdSolution];
      const providerServices = state.providerServices;
      if (
        !solution ||
        !Array.isArray(solution.menu) ||
        solution.menu.length === 0
      ) {
        try {
          const response = await providerServices.postModel(
            "/menuItems/menuItemsByUserAndSolution",
            { cdSolution }
          );
          const unumMenuItems: Array<IUnumMenuItem> = response.data.menuItems;
          dispatch(setSolutionMenu(cdSolution, unumMenuItems));
          resolve(null);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      }
      resolve(null);
    });
  };
}
