
import {
  IUnumScreen,
  IUnumButton,
  IButton,
  IUnumTab
} from '../../../utilities/types'
import {
  BASIC_INPUT_TYPES,
  getBasicInputState
} from '../utilities/basicInputUtils'
import {
  COLUMNS_COMPONENT_TYPE,
  COLUMN_COMPONENT_TYPE,
  CONTAINER_TYPES, TABS_COMPONENT_TYPE
} from '../utilities/containersUtils'
import {
  AVANCED_COMPONENTS_TYPES, getAvancedComponentState, getAvancedComponentInputState, FILE_COMPONENT_TYPE
} from '../utilities/avancedComponentsUtils'

export const SHOW_VALIDATION_ERROR_ACTION = 'showValidationError'
export const SET_FOCUS_ACTION = 'setFocus'

export function extractInfoRender(components:Array<any>) {
  return components.map((currentItem: any) => {
    return {
      key: currentItem.key,
      type: currentItem.type
    }
  })
}

export function getScreenComponents(components:Array<any>, defaultConfiguration:any): { [id:string]: any }{
  const getScreenComponentsReducer = (result:{ [id:string]: any }, currentItem: any) => {
    let response = result
    if (CONTAINER_TYPES.includes(currentItem.type)) {
      if (currentItem.type === TABS_COMPONENT_TYPE) {
        const components = currentItem.components.map((item: IUnumTab) => {
          return {
            ...item,
            components: Array.isArray(item.components)?extractInfoRender(item.components):[]
          }
        })
        response = {
          ...result,
          [currentItem.key]: {
            properties: {
              key: currentItem.key,
              type: currentItem.type,
              components
            },
            actions: {}
          }
        }
      } else if(currentItem.type === COLUMNS_COMPONENT_TYPE) {
        const columns = currentItem.columns.map((item: any) => {
          return {
            ...item,
            components: Array.isArray(item.components)?extractInfoRender(item.components):[]
          }
        })
        response = {
          ...result,
          [currentItem.key]: {
            properties: {
              key: currentItem.key,
              type: currentItem.type,
              columns
            }
          }
        }
      } else if(currentItem.type !== COLUMN_COMPONENT_TYPE){
        const components = Array.isArray(currentItem.components)?extractInfoRender(currentItem.components):[]
        const columns = Array.isArray(currentItem.columns)?extractInfoRender(currentItem.columns):[]
        const container = {
          properties: {
            ...currentItem,
            components,
            columns
          }
        }
        response = {
          ...result,
          [currentItem.key]: container
        }
      }
    }else if (currentItem.input) {
      let newInput:any
      if (BASIC_INPUT_TYPES.includes(currentItem.type)) {
        newInput = getBasicInputState(currentItem, defaultConfiguration)
      }else if(AVANCED_COMPONENTS_TYPES.includes(currentItem.type)){
        newInput = getAvancedComponentInputState(currentItem, defaultConfiguration)
      }else{
        newInput = {
          properties: currentItem,
          value: '',
          errorStyle: {},
          initialStyle: {},
          isInitial: true,
          isValid: true,
          actions: {}
        }
      }
      return {
        ...response,
        [currentItem.key]: newInput
      }
    } else if(AVANCED_COMPONENTS_TYPES.includes(currentItem.type)) {
      return {
        ...response,
        [currentItem.key]: getAvancedComponentState(currentItem, {})
      }
    }
    if (Array.isArray(currentItem.components)) {
      const newRes = currentItem.components.reduce(getScreenComponentsReducer, {})
      if (!newRes) return response
      return {
        ...response,
        ...newRes
      }
    } else if (Array.isArray(currentItem.columns)) {
      const newRes = currentItem.columns.reduce(getScreenComponentsReducer, {})
      if (!newRes) return response
      return {
        ...response,
        ...newRes
      }
    } else {
      return {
        ...response,
        [currentItem.key]: {
          properties: currentItem
        }
      }
    }
  }
  const res = components.reduce(getScreenComponentsReducer, {})
  return res
}


function transformRefreshwhenToRefreshtoReducer(accumulator: { [id: string]: any; }, currentItemKey: string) {
  let res = {
    ...accumulator
  }
  if (accumulator[currentItemKey].properties && Array.isArray(accumulator[currentItemKey].properties.refresWhen)) {
    accumulator[currentItemKey].properties.refresWhen.forEach((inputKey: string) => {
      const isRefreshto = Array.isArray(res[inputKey].properties.refreshTo)
      const isIncluded = res[inputKey].properties.refreshTo.includes(accumulator[currentItemKey].properties.key)
      if (isRefreshto) {
        if (!isIncluded) {
          res = {
            ...res,
            [inputKey]: {
              ...res[inputKey],
              properties: {
                ...res[inputKey].properties,
                'refreshTo': res[inputKey].properties.refreshTo.concat([accumulator[currentItemKey].properties.key])
              }

            }
          }
        }
      } else {
        res = {
          ...res,
          [inputKey]: {
            ...res[inputKey],
            properties: {
              ...res[inputKey].properties,
              'refreshTo': [accumulator[currentItemKey].properties.key]
            }
          }
        }
      }
    });
  }
  return res
}

export function getInitialScreenState(cdSolution:string, cdProject: string, cdScreen: string, screen: IUnumScreen, defaultConfiguration: any) {
  if (!Array.isArray(screen.screenMap?.components)) {
    return {
      screenMap: screen.screenMap,
      buttons: [],
      cdSolution,
      cdProject,
      cdScreen,
      messages: [],
      isUseFile: false,
      components: {},
      dsScreen: screen.dsScreen,
      showWindow: {}
    }
  }
  let components: any = getScreenComponents(screen.screenMap.components, defaultConfiguration)
  const screenMap = screen.screenMap
  const rootRender = extractInfoRender(screen.screenMap.components)
  const keys: Array<string> = Object.keys(components)
  components = keys.reduce(transformRefreshwhenToRefreshtoReducer, components)
  const buttons:Array<IButton> = screenMap.buttons.map((element: IUnumButton) => {
    return {
      properties: element,
      isActive: !element.buttonMap.hidden
    }
  })
  return {
    screenMap,
    buttons,
    cdProject,
    cdSolution,
    cdScreen,
    messages: [],
    isUseFile: false,
    components,
    dsScreen: screen.dsScreen,
    rootRender,
    showWindow: {}
  }
}

export function getInputsValues(components: any) {
  return Object.keys(components).reduce((result:any, currentInputKey:any) => {
    const comp:any = components[currentInputKey]
    if (!comp.properties?.input) return result
    return {
      ...result,
      [currentInputKey]: comp.value
    }
  }, {})
}

export function sendExecuteButton(cdProject: string, cdScreen: string, button: any, inputs: { [id: string]: any; }, avancedComponentsInputs: any, providerServices: any) {
  return new Promise(async (resolve: any, reject: any) => {
    let request: any = {
      cdProject: cdProject,
      cdScreen: cdScreen,
      idButton: button.idButton,
      modal: "",
      ip: ""
    }
    let isUseFile = false
    let jsonInputs: any = {}
    let files: any = {}


    const voidFile = new File([], "")
    for (const key in inputs) {
      if (inputs[key].properties.type === FILE_COMPONENT_TYPE){
        isUseFile = true
        if (inputs[key].value) {
          files[key] = inputs[key].value
        } else {
          files[key] = voidFile
        }
      } else {
        jsonInputs[key] = inputs[key].value
      }
    }
    try {
      let response
      if (isUseFile) {
        let formData = new FormData()
        for (const key in request) {
          formData.append(key, request[key]);
        }
        for (const key in files) {
          formData.append(key, files[key]);
        }
        formData.append('params', JSON.stringify({ ...jsonInputs, ...avancedComponentsInputs }));
        response = await providerServices.postModelFormData('/button/executeButton', formData)
      } else {
        request.params = JSON.stringify({ ...jsonInputs, ...avancedComponentsInputs })
        response = await providerServices.postModel('/button/executeButton', request)
      }
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
}








