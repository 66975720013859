import React, { useState } from "react";
import { IonIcon, IonList, IonChip } from "@ionic/react";
import "./Slides.css";
import { trash } from "ionicons/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Virtual } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.scss";
export const Slides = ({
  indexItems,
  selectedUrl,
  slider,
  emptySlider,
  setIsOpen,
  isOpen,
  eliminarObjeto,
}) => {
  const [showTrashOpt, setShowTrashOpt] = useState(false);
  const [activeIndex, setActiveIndex] = useState(slider.length - 1);

  if (slider.length >= 1) {
    return (
      <Swiper
        style={{ height: "100%" }}
        navigation
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination, Scrollbar, A11y, Virtual]}
        onSlideChange={() => console.log("slide change")}
        onRealIndexChange={(e) => setActiveIndex(e.activeIndex)}
        initialSlide={activeIndex}
      >
        {slider.map((one, key) => {
          return (
            <SwiperSlide key={key}>
              <div
                style={{ textAlign: "center", height: "100%", width: "100%" }}
              >
                <IonIcon
                  icon={trash}
                  className="trashSlideIcon"
                  onClick={() => {
                    setShowTrashOpt(!showTrashOpt);
                  }}
                ></IonIcon>
                {showTrashOpt === true ? (
                  <IonList>
                    <IonChip
                      className="chipTrashSlide"
                      onClick={() => {
                        setShowTrashOpt(false)
                        eliminarObjeto(activeIndex);
                      }}
                    >
                      Quitar este
                    </IonChip>
                    <IonChip
                      className="chipTrashSlide"
                      onClick={() => {
                        emptySlider();
                        setIsOpen(false);
                      }}
                    >
                      Quitar todos
                    </IonChip>
                  </IonList>
                ) : null}

                <p> {one.cdNode}</p>
                <p>
                  {" "}
                  {activeIndex + 1}/ {slider.length}
                </p>

                <iframe
                  src={one.url}
                  title="Embedded Content"
                  width="100%"
                  height="100%"
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  }
};
