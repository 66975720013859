import React, { useEffect, useState } from 'react'
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList } from '@ionic/react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  filterOutline,
  caretUpOutline,
  caretDownOutline
} from 'ionicons/icons';
import { IUnumGridColumnFormat } from '../../../../../utilities/types';


const GridConfig = (props: any) => {
  const [columns, setColumns] = useState<Array<any>>(props.columns)
  const [columnIndexFilter, setColumnIndexFilter] = useState<number>(props.columnIndexFilter)
  const [filterDirecton, setFilterDirection] = useState<boolean>(props.filterDirecton)
  const defaultRows = props.defaultRows || 5
  const [rows, setRows] = useState<number>(defaultRows)
  const [primaryColor, setPrimaryColor] = useState<string>(props.primaryColor)
  //const [secundaryColor, setSecundaryColor] = useState<string>(props.secundaryColor)
  const secundaryColor = '#fff'

  useEffect(() => {
    setPrimaryColor(props.primaryColor || '')
  }, [props.primaryColor])

  /*useEffect(() => {
    setSecundaryColor(props.secundaryColor || '#fff')
  }, [props.secundaryColor])*/


  useEffect(() => {
    setColumns(props.columns)
  }, [props.columns])

  useEffect(() => {
    setColumnIndexFilter(props.columnIndexFilter)
  }, [props.columnIndexFilter])

  useEffect(() => {
    setFilterDirection(props.filterDirecton)
  }, [props.filterDirecton])

  useEffect(() => {
    setRows(props.rows)
  }, [props.rows])

  const handleHide = () => {
    props.onHide()
  }

  const handleConfigApply = () => {
    const rowsExported = rows ? rows : defaultRows
    props.onConfigApply({ columns, columnIndexFilter, filterDirecton, rows: rowsExported })
  }

  const changeFilter = (columnIndex: number) => {
    if (columnIndex === columnIndexFilter) {
      setFilterDirection(!filterDirecton)
      return
    }
    setColumnIndexFilter(columnIndex)
    setFilterDirection(false)
  }

  return (
    <Modal
      show={true}
      onHide={handleHide}
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex flex-column">
          <span>{props.title}</span>
          <span className="text-muted font-size-medium">Configuración</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <IonList>
            <IonItem>
              <IonLabel>
                Filas por pagina:
              </IonLabel>
              <IonInput
                type="number"
                value={rows}
                onIonChange={e => setRows(parseInt(e.detail.value!, 10))}
              />
            </IonItem>
          </IonList>
          <div className="d-flex flex-column">
              <div className="d-flex">
                <div className="mr-auto">
                  <IonLabel 
                    style={{
                      color: primaryColor
                    }}
                    className="font-size-large font-weight-bold">Campo</IonLabel>
                </div>
                <div
                  style={{
                    width: '3em'
                  }}
                className="d-flex justify-content-center align-items-center ml-auto">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-eye">
                        Orden
                    </Tooltip>
                    }
                  >
                    <IonIcon icon={filterOutline} size="large" style={{
                      color: primaryColor
                    }} />
                  </OverlayTrigger>
                </div>
              </div>
              <div style={{ maxHeight: '50vh', overflowY: 'scroll' }}>
                {
                  Array.isArray(columns)?(
                    columns.map((column: IUnumGridColumnFormat, index: any) => {
                      if (column.visible === false || columns[index]?.mask?.type === 'img') return null
                      return (
                        <div key={index} onClick={() => { changeFilter(index) }} className="d-flex py-1 border-bottom">
                          <div className="mr-auto">
                            <IonLabel>{column.title}</IonLabel>
                          </div>
                          <div
                            style={{
                              width: '3em'
                            }}
                            className="ml-auto" 
                          >
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <IonIcon 
                                style={{
                                  color: index === columnIndexFilter && filterDirecton ? primaryColor : 'gray'
                                }}
                                icon={caretUpOutline}
                              />
                              <IonIcon 
                                style={{
                                  color: index === columnIndexFilter && !filterDirecton ? primaryColor : 'gray'
                                }}
                                icon={caretDownOutline} 
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ):null
                }
              </div>
          </div>
          <IonButton
            onClick={handleConfigApply}
            expand="block"
            className="mt-3"
            style={{
              backgroundColor: primaryColor,
              '--background': primaryColor,
              color: secundaryColor
            }}
          >
            Guardar
          </IonButton>
        </div>

      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  )
}

export default GridConfig