import React, { useState, useEffect } from "react";
import {
  IonButtons,
  IonButton,
  IonModal,
  IonContent,
  IonIcon,
  IonItem,
  IonToggle,
  IonTextarea,
} from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import { Preferences } from "@capacitor/preferences";
import "./PageConfigModal.css";
function UnumConfig({ unumConfig, setUnumConfig }) {
  const [isOpen, setIsOpen] = useState(true);
  const [configuredUrl, setConfiguredUrl] = useState("");

  useEffect(() => {
    if (isOpen === false) {
      setUnumConfig(false);
    }
    dataUrl();
  }, [isOpen, setUnumConfig]);
  useEffect(() => {
    if (unumConfig === false) {
      setIsOpen(false);
    }
  }, []);
  const dataUrl = async () => {
    const result = await Preferences.get({ key: "connectionURL" });
    const url = result.value;
    setConfiguredUrl(url);
  };
  const toggleDarkModeHandler = () => document.body.classList.toggle("dark");
  const handleUrlChange = (e) => {
    setConfiguredUrl(e.target.value);
  };
  const handleUpdateUrl = async () => {
    try {
      await Preferences.set({
        key: "connectionURL",
        value: configuredUrl,
      });
      setIsOpen(false);
    } catch (error) {
      console.error("Error al almacenar la URL:", error);
    }
  };
  return (
    <IonModal isOpen={isOpen}>
      <IonButtons>
        <IonItem
          style={{ borderColor: "0px solid" }}
          className="itemConfigUnum"
        >
          <IonIcon
            style={{ fontSize: "1.5rem" }}
            onClick={() => {
              setIsOpen(false);
            }}
            icon={closeCircle}
            slot="end"
          >
            Cerrar
          </IonIcon>
        </IonItem>
      </IonButtons>
      <IonContent>
        <IonItem className="">
          Tema oscuro
          <IonToggle
            slot="end"
            name="darkMode"
            onIonChange={toggleDarkModeHandler}
            color="primary"
          />
        </IonItem>
        <IonItem className="itemConfigUnum">URL</IonItem>
        <IonItem>
          <IonTextarea
            style={{
              color: "primary",
              backgroundColor: "primary",
              border: "solid thin #b3b3b3",
              width: "100%",
            }}
            className={"native-input sc-ion-input-md"}
            onIonChange={handleUrlChange}
            value={configuredUrl}
            autoGrow={true}
          />
        </IonItem>
        <IonItem className="itemConfigUnum">
          <IonButton onClick={() => setIsOpen(false)} slot="end">
            Cancelar
          </IonButton>
          <IonButton onClick={handleUpdateUrl} slot="end">
            Aceptar
          </IonButton>
        </IonItem>
      </IonContent>
    </IonModal>
  );
}

export default UnumConfig;
