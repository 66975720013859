import {
  IonList,
  IonMenu,
  IonIcon,
} from "@ionic/react";
import {
  logInOutline,
  logOutOutline,
  albumsOutline,
} from "ionicons/icons";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
//import { menuItemsByUserAndSolution } from './../../../../store/unumApp/actions'
import "./Menu.scss";
import SubMenu from "./subMenu/SubMenu";
import { ISolution, IState } from "../../../utilities/types";
import { menuController } from "@ionic/core/components";
import { menu, settingsOutline } from "ionicons/icons";
import PageConfig from "../../../pages/PageConfigModal";

const Menu: React.FunctionComponent<any> = (props) => {
  let providerService = props.providerServices;
  const [solution, setSolution] = useState<ISolution>(props.solution);
  const [cdSolution, setCdSolution] = useState<string>(props.currentSolution);
  const [unumConfig, setUnumConfig] = useState<boolean>(false);
  const isLogged = providerService.isLogged();

  const defaultMenu: any[] = [
    {
      type: "url",
      label: "Iniciar sesion",
      url: "/login",
      icon: logInOutline,
      condition: !isLogged,
    },
    {
      type: "url",
      label: "Ver soluciones",
      url: "/",
      icon: albumsOutline,
      condition: isLogged,
    },
    {
      type: "action",
      label: "Configuración",
      icon: settingsOutline,
      action: () => {
        setUnumConfig(!unumConfig);
      },
    },
    {
      type: "action",
      label: "Cerrar sesion",
      icon: logOutOutline,
      url: "",
      action: () => {
        providerService.logout();
      },
      condition: isLogged,
    },
  ];

  const handleOpenMenu = () => {
    menuController.open();
  };
  handleOpenMenu();
  useEffect(() => {
    if (props.solution) {
      setSolution(props.solution);
    }
  }, [props.solution]);

  useEffect(() => {
    setCdSolution(props.currentSolution);
  }, [props.currentSolution]);
  return (
    <>
      {isLogged ? null : (
        <div className="d-flex justify-content-start px-3">
          <IonIcon
            onClick={handleOpenMenu}
            icon={menu}
            color="light"
            size="large"
            style={{ zIndex: "1" }}
          />
        </div>
      )}

      <IonMenu contentId="main" type="overlay" disabled={false}>
        <div className="px-2 overflow-auto">
          <IonList className="bg-transparent" lines="none" id="inbox-list">
            <div className="pl-2 unum-font-size-normal">
              <SubMenu
                options={(Array.isArray(solution?.menu)
                  ? solution.menu
                  : []
                ).concat(defaultMenu)}
                hidden={false}
                isRoot={true}
                cdSolution={cdSolution}
              />
            </div>
          </IonList>
        </div>
      </IonMenu>
      {unumConfig === true ? (
        <PageConfig unumConfig={unumConfig} setUnumConfig={setUnumConfig}></PageConfig>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    solution: state.solutions[state.app.currentSolution],
    providerServices: state.providerServices,
    currentSolution: state.app.currentSolution,
  };
};

export default connect(mapStateToProps)(Menu);
