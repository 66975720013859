import lodash, { forIn, isEmpty, includes } from "lodash"
import utilities from "../../../../utilities"
import { IScreen, IState, IUnumScriptResponse, IUnumShowWindow } from "../../../../utilities/types"
import { extractInfoRender, getScreenComponents, sendExecuteButton, SET_FOCUS_ACTION } from "../../utilities"
import { getInputAvancedComponentsAndInputValues } from "../../utilities/avancedComponentsUtils"
import { validateInputs } from "../../utilities/basicInputUtils"
import { onMountedScreen } from "../generalActions"
import { executeGetQueryResult, setComponentData } from "./avancedComponentsActions"
import { updateBasicInputStyle } from "./basicComponentsActions"
import { setContainerComponents, setTabIndexByTabKey } from "./designComponentsActions"
import { addComponentAction, inputChange, updateComponentProperty } from "./transversalComponentsActions"

export const updateComponents = (cdProject: string, cdScreen: string, components: { [id: string]: any; }) => {
  return {
    type: 'UPDATE_COMPONENTS',
    cdProject,
    cdScreen,
    components
  }
}

export function hideButtons(cdProject: string, cdScreen: string) {
  return {
    type: 'HIDE_BUTTONS',
    cdProject,
    cdScreen
  }
}

export function showButtons(cdProject: string, cdScreen: string, buttons: Array<any>) {
  return {
    type: 'SHOW_BUTTONS',
    cdProject,
    cdScreen,
    buttons
  }
}

export function hideButton(cdProject: string, cdScreen: string, buttonId: any) {
  return {
    type: 'HIDE_BUTTON',
    cdProject,
    cdScreen,
    buttonId
  }
}

export function showButton(cdProject: string, cdScreen: string, buttonId: any) {
  return {
    type: 'SHOW_BUTTON',
    cdProject,
    cdScreen,
    buttonId
  }
}

export const setMessages = (cdProject: string, cdScreen: string, messages: Array<any>) => {
  return {
    type: 'SET_MESSAGES',
    cdProject,
    cdScreen,
    messages
  }
}

export const clearScreenMessages = (cdProject: string, cdScreen: string) => {
  return {
    type: 'CLEAR_MESSAGES',
    cdProject,
    cdScreen
  }
}

export const executeButton = (cdProject: string, cdScreen: string, btnKey: string) => {
  return (dispatch: any, getState: any) => {
    const state: IState = getState()
    const defaultConfiguration = state.defaultConfiguration
    const providerServices = state.providerServices
    const screens: { [id: string]: IScreen; } = state.screens
    const screenIndex = Object.keys(screens).findIndex((item) => {
      const isSameProject = screens[item].cdProject === cdProject
      const isSameScreen = screens[item].cdScreen === cdScreen
      return isSameProject && isSameScreen
    })
    const button: any = screens[screenIndex].screenMap.buttons.find((item) => {
      return item.idButton === btnKey
    })
    const components = screens[screenIndex].components
    const validInputs = validateInputs(button, components)
    const avancedComponentsInputs = getInputAvancedComponentsAndInputValues(components)
    const currentCdProject = state.app.currentProject
    const currentCdScreen = state.app.currentScreen

    if (!validInputs.isValid && button.buttonMap.validate) {//Refrezca inputs con las acciones respectivas
      const messages = [{
        title: '',
        showCloseButton: true,
        allowOutsideClick: true,
        text: utilities.messages.form.invalidInput()
      }]
      dispatch(updateComponents(
        cdProject,
        cdScreen,
        validInputs.inputs
      ))
      dispatch(setMessages(
        cdProject,
        cdScreen,
        messages
      ))
      return
    }
    const sender = () => {
      sendExecuteButton(
        cdProject,
        cdScreen,
        button,
        validInputs.inputs,
        avancedComponentsInputs.value,
        providerServices
      ).then((response: any) => {
        const scriptResponse: IUnumScriptResponse = response.data.scriptResponse
        const msg = scriptResponse.messages
        const refreshScreen = scriptResponse.refreshScreen
        const viewersKeysForRefresh = Object.keys(scriptResponse.viewer)
        const showTab = scriptResponse.showTab
        const showWindowParams: IUnumShowWindow = scriptResponse.showWindow
        const setFocus = scriptResponse.setFocus
        const inputValue = Object.values(validInputs.inputs)
        const compIndex = Object.values(components)

        const componentsForRefresh = refreshScreen.map((element: any, index: any) => {
          const comps = Array.isArray(element.component) ? element.component : [element.component]
          const screenComponents: any = getScreenComponents(comps, defaultConfiguration)
          const containerComponents = extractInfoRender(comps)
          return {
            screenComponents,
            container: element.container,
            components: containerComponents
          }
        })
        const setProperties = scriptResponse.setProperties
        const close = scriptResponse.close
        const setStyles = scriptResponse.setStyles
        const clearForm = scriptResponse.clearform
        const disableFields = scriptResponse.disableFields

        if (!isEmpty(scriptResponse.dataForm)) {
          forIn(scriptResponse.dataForm, (value, key) => {
            dispatch(inputChange(cdProject, cdScreen, key, value, true))
          });
        }
        if (Array.isArray(clearForm)) {
          inputValue.forEach((element: any) => {
            if (!includes(clearForm, element.properties.key)) {
              dispatch(inputChange(cdProject, cdScreen, element.properties.key, null, true))
            }
          })
        }
        if (Array.isArray(disableFields)) {
          Object.values(components).forEach((element: any) => {
            if (!includes(disableFields, element.properties.key)) {
              dispatch(updateComponentProperty(
                cdProject,
                cdScreen,
                element.key,
                element.properties.disabled = true
              )
              )
            }
          })
        }
        if (Array.isArray(setStyles)) {
          setStyles.forEach((element: any) => {
            let styles: any = {}
            Object.keys(element.styles).forEach(styleKey => {
              styles[lodash.camelCase(styleKey)] = element.styles[styleKey]
            });
            dispatch(updateBasicInputStyle(
              cdProject,
              cdScreen,
              element.key,
              styles
            ))
          });
        }
        if (Array.isArray(setProperties)) {
          setProperties.forEach(element => {
            dispatch(updateComponentProperty(
              cdProject,
              cdScreen,
              element.key,
              element.attributes
            ))
          });
        }
        if (setFocus) {
          let content: any = document.querySelector("ion-content");
          content.scrollToPoint(0, 0).then(() => {
            let y: any = document.getElementById(`${setFocus}`)?.getBoundingClientRect().top
            content.scrollToPoint(0, y - 100);
          })
          dispatch(addComponentAction(
            cdProject,
            cdScreen,
            setFocus,
            SET_FOCUS_ACTION,
            null
          ))
        }
        if (showWindowParams && showWindowParams.cdProject && showWindowParams.cdScreen) {
          dispatch(showWindow(
            cdProject,
            cdScreen,
            showWindowParams,
            validInputs.inputs,
            avancedComponentsInputs.components
          ))
        }
        if (close) {
          dispatch(setShowWindow(
            currentCdProject,
            currentCdScreen,
            {}
          ))
        }
        if (msg.length > 0) {
          dispatch(setMessages(
            cdProject,
            cdScreen,
            msg
          ))
        }
        if (componentsForRefresh.length > 0) {
          dispatch(setContainerComponents(
            cdProject,
            cdScreen,
            componentsForRefresh
          ))
        }
        if (showTab) {
          dispatch(setTabIndexByTabKey(
            cdProject,
            cdScreen,
            showTab
          ))
        }

        if (Array.isArray(scriptResponse.reloadData)) {
          const reloadData = scriptResponse.reloadData
          const currentScreenIndex = Object.keys(screens).findIndex((item) => {
            const isSameProject = screens[item].cdProject === currentCdProject
            const isSameScreen = screens[item].cdScreen === currentCdScreen
            return isSameProject && isSameScreen
          })

          if (Array.isArray(reloadData)) {
            if (reloadData.length < 1) {
              compIndex.forEach((element: any) => {
                if (!includes(reloadData, element.properties.key)) {
                  if (element.properties.query) {
                    dispatch(executeGetQueryResult(
                      cdProject,
                      cdScreen,
                      element.properties.key,
                      element.properties.query,
                      element.properties.input,
                      element.properties.type
                    ))
                  }
                }
              })
            }
          }
          reloadData.forEach((element: any) => {
            const avancedComponent: any = components[element]
            if (avancedComponent && avancedComponent.properties) {
              dispatch(executeGetQueryResult(
                cdProject,
                cdScreen,
                element,
                avancedComponent.properties.query,
                avancedComponent.properties.input,
                avancedComponent.properties.type
              ))
            }
            if (currentScreenIndex !== screenIndex) {
              const currentScreen: IScreen = state.screens[currentScreenIndex]
              const comp = currentScreen.components[element]
              if (comp && comp.properties) {
                dispatch(executeGetQueryResult(
                  currentCdProject,
                  currentCdScreen,
                  element,
                  comp.properties.query,
                  comp.properties.input,
                  comp.properties.type
                ))
              }
            }
          })
        }
        if (viewersKeysForRefresh.length > 0) {
          viewersKeysForRefresh.forEach((viewerKey: string) => {
            dispatch(setComponentData(
              cdProject,
              cdScreen,
              viewerKey,
              scriptResponse.viewer[viewerKey]
            ))
          })
        }
      }).catch((error: any) => {
        console.log(error);

        const msg = [{
          title: '',
          showCloseButton: true,
          allowOutsideClick: true,
          text: error.message,
        }]
        dispatch(setMessages(
          cdProject,
          cdScreen,
          msg
        ))
      })
    }
    //Mensaje de confirmacion
    if (button.buttonMap.msgConfirm) {
      const messages = [{
        title: '',
        showCloseButton: true,
        allowOutsideClick: false,
        text: button.buttonMap.msgConfirm,
        actions: [{
          label: utilities.messages.form.confirmLabel(),
          run: () => {
            sender()
          }
        }]
      }]
      return dispatch(setMessages(
        cdProject,
        cdScreen,
        messages
      ))
    }
    sender()

    return {
      type: 'EXECUTE_BUTTON',
      cdProject,
      cdScreen,
      btnKey
    }
  }
}

export const setShowWindow = (cdProject: string, cdScreen: string, showWindow: any) => {
  return {
    type: 'SET_SHOW_WINDOW',
    cdProject,
    cdScreen,
    showWindow
  }
}


export const showWindow = (cdProject: string, cdScreen: string, showWindow: IUnumShowWindow, inputs: any, avancedComponentsInputs: any) => {
  return (dispatch: any, getState: any) => {
    const state = getState()
    const screens: { [id: string]: IScreen; } = state.screens
    const screenIndex = Object.keys(screens).findIndex((item) => {
      const isSameProject = screens[item].cdProject === cdProject
      const isSameScreen = screens[item].cdScreen === cdScreen
      return isSameProject && isSameScreen
    })
    const screen = screens[screenIndex]
    dispatch(onMountedScreen(screen.cdSolution, showWindow.cdProject, showWindow.cdScreen)).then(() => {
      dispatch(appendComponents(showWindow.cdProject, showWindow.cdScreen, {
        ...inputs,
        ...avancedComponentsInputs
      }))
      dispatch(setShowWindow(cdProject, cdScreen, showWindow))
    })

  }
}

export const appendComponents = (cdProject: string, cdScreen: string, components: any) => {
  return (dispatch: any, getState: any) => {
    const state = getState()
    const screens: { [id: string]: IScreen; } = state.screens
    const screenIndex = Object.keys(screens).findIndex((item) => {
      const isSameProject = screens[item].cdProject === cdProject
      const isSameScreen = screens[item].cdScreen === cdScreen
      return isSameProject && isSameScreen
    })
    let screen = {
      ...screens[screenIndex]
    }
    Object.keys(components).forEach((key: string) => {
      if (screen.components[key]) {
        if (screen.components[key].properties.input) {
          screen.components[key].value = components[key].value
        }
      } else {
        screen.components[key] = components[key]
      }
    })
    dispatch(updateComponents(cdProject, cdScreen, screen.components))
  }
}