import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonIcon,
  IonText
} from '@ionic/react'
import { ISolution, IState } from '../../utilities/types'
import {
  albumsOutline,
  menu
} from 'ionicons/icons';
import { menuController } from '@ionic/core/components'
import { menuItemsByUserAndSolution, setCurrentSolution } from '../../store/unumApp/actions'
import './PageLobby.scss'

const Page: React.FC = (props: any) => {
  const [solutions, setSolutions] = useState<{ [id: string]: ISolution }>(props.solutions)

  useEffect(() => {
    setSolutions(props.solutions)
  }, [props.solutions])

  const handleSolutionSelected = (cdSolution: string) => {
    if (!cdSolution) {
      return
    }
    props.dispatch(menuItemsByUserAndSolution(cdSolution)).then(() => {
      props.dispatch(setCurrentSolution(cdSolution))
      menuController.open()
    })
  }

  const handleOpenMenu = () => {
    menuController.open()
  }

  return (
    <IonPage>
      <IonContent className="unum-font-size-normal">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-start px-3">
            <IonIcon
              onClick={handleOpenMenu}
              icon={menu} 
              color="light" 
              size="large" 
              style={{zIndex: '1'}} 
            />
          </div>
          <div
            style={{
              width: '100vw',
              height: '15vw'
            }}
            className="d-flex justify-content-center position-relative mb-5"
          >
            <div className="unumCircle position-absolute" />
            <div className="d-flex align-items-end pb-2">
              <img
                src={process.env.PUBLIC_URL + '/assets/icon/whiteLogo.png'}
                style={{
                  width: '2em',
                  height: 'auto',
                  zIndex: 1
                }}
                alt="unum"
              />
            </div>
          </div>
          <IonList>
            {
              Object.keys(solutions).map((solutionKey: string, index: number) => {
                const solution: ISolution = solutions[solutionKey]
                if (solution?.unumSolution?.dsSolution) {
                  return (
                    <IonItem
                      key={index}
                      onClick={() => {
                        handleSolutionSelected(solution.unumSolution?.cdSolution)
                      }}
                    >
                      <IonIcon icon={albumsOutline} className="px-3" size="small" />
                      <IonText>{solution?.unumSolution?.dsSolution}</IonText>
                    </IonItem>
                  )
                }
                return null
              })
            }
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    solutions: state.solutions
  }
}

export default connect(mapStateToProps)(Page);
