import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IState, IUnumGrid, IScreen } from '../../../../../utilities/types'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';


const Workcontext = (props: any) => {
  const [properties, setProperties] = useState<IUnumGrid>(props.componentState.properties || {})

  useEffect(() => {
    setProperties(props.componentState.properties)
  }, [props.componentState.properties])
  
  return (
    <IonCard
      id={properties.key}
      className="mx-0">
      <IonCardHeader className="bg-transparent">
        <IonCardTitle className="font-weight-bold unum-font-size-normal">{properties.label}</IonCardTitle>
        <hr className="mb-0" />
      </IonCardHeader>
      <IonCardContent className="px-0 bg-transparent unum-font-size-normal">
        <div className="d-flex flex-column">
          <span>Workcontext Component</span>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen
    return isSameProject && isSameScreen
  })
  const screen: IScreen = screens[screenIndex] || {}
  const components = screen.components || {}
  return {
    componentState: components[ownProps.info.key] || {}
  }
}

export default connect(mapStateToProps)(Workcontext)