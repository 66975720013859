
import { IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import { Accordion, Card } from 'react-bootstrap';
import UnumComponent from '../../UnumComponent'
import { completeComponentAction, hideButtons, showButtons } from '../../../../../store/unumApp/actions/index'
import { connect, useDispatch } from 'react-redux';
import { IScreen, IState, IUnumSolution, IUnumTab } from '../../../../../utilities/types';
import CustomToggle from './CustomToggle'
import { SET_TAB_INDEX_COMPONENT_ACTION } from '../../../../../store/unumApp/utilities/containersUtils';


const Tabs = (props: any) => {
  
  const [properties, setProperties] = useState<any>(props.properties)
  const defaultActiveKey = `${props.info.key}-0`
  const [currentTabEvent, setCurrentTabEvent] = useState<any>(defaultActiveKey)
  const dispatch = useDispatch()
  const [primaryColor, setPrimaryColor] = useState<string>('')
  //const [secundaryColor, setSecundaryColor] = useState<string>('')

  useEffect(() => {
    const unumSolution: IUnumSolution = props.unumSolution
    setPrimaryColor(unumSolution.cdPrincipalColor || '')
    //setSecundaryColor(unumSolution.cdSecundaryColor || '#fff')
  }, [props.unumSolution])
  
  
  useEffect(() => {
    setProperties(props.properties)
  }, [props.properties])

  useEffect(() => {
    const actions = props.actions
    if (actions && actions[SET_TAB_INDEX_COMPONENT_ACTION] && actions[SET_TAB_INDEX_COMPONENT_ACTION].active) {
      setCurrentTabEvent(`${props.info.key}-${actions[SET_TAB_INDEX_COMPONENT_ACTION].data}`)
      dispatch(completeComponentAction(props.cdProject, props.cdScreen, props.info.key, SET_TAB_INDEX_COMPONENT_ACTION))
    }
  }, [props.actions, props.info.key, props.cdProject, props.cdScreen, dispatch])

  useEffect(() => {
    const aux = currentTabEvent.split('-')
    const currentIndex = aux[aux.length - 1]
    const currentTab: IUnumTab = properties.components[currentIndex]
    if(currentTab.showButtonTabs === false){
      dispatch(hideButtons(props.cdProject, props.cdScreen))
    }else if(Array.isArray(currentTab.buttons)) {
      dispatch(showButtons(props.cdProject, props.cdScreen, currentTab.buttons))
    }
  }, [dispatch, currentTabEvent, props.cdProject, props.cdScreen, properties.components])

  const handleOnTabSelect = (eventKey:any) => {
    if (eventKey) {
      setCurrentTabEvent(eventKey);
    }  }
  
  if (properties.hidden) return null
  return (
    <Accordion 
      id={properties.key}
      activeKey={currentTabEvent}
      defaultActiveKey={defaultActiveKey}
      onSelect={handleOnTabSelect}
    >
      {
        Array.isArray(properties.components)?(
          properties.components.map((tab: IUnumTab, tabIndex: any) => {
            if (tab.hidden) return null
            return (
                <Card key={tabIndex} className={`${tab.disabled?'bg-disabled':'bg-transparent'}`}>
                  <Card.Header>
                    <CustomToggle 
                      disabled={tab.disabled}
                      eventKey={`${props.info.key}-${tabIndex.toString()}`}
                    >
                      <IonText 
                        style={{
                          color: primaryColor
                        }}
                        className="font-weight-bold unum-font-size-normal">{tab.label}</IonText>
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={`${props.info.key}-${tabIndex.toString()}`}>
                    <Card.Body 
                      className="px-0"
                    >
                      {
                        Array.isArray(tab.components)?
                        (tab.components.map((item: any, index: any) => {
                          return (
                            <div key={index}>
                              <UnumComponent
                                level={props.level + 1}
                                info={item}
                                cdProject={props.cdProject}
                                cdScreen={props.cdScreen}
                              />
                            </div>
                          )
                        })):null
                      }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
            )
          })
        ):null
      }
    </Accordion>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen
    return isSameProject && isSameScreen
  })
  const screen:IScreen = screens[screenIndex] || {}
  const comp = screen.components[ownProps.info.key] || {}
  const properties = comp.properties || {}
  const actions = comp.actions || []
  const solution = state.solutions[state.app.currentSolution]
  return {
    properties,
    actions,
    unumSolution: solution?.unumSolution || {}
  }
}

export default connect(mapStateToProps)(Tabs)


