import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  executeGetQueryResult,
  checkGridRow,
  setComponentData,
  completeComponentAction,
} from "../../../../../store/unumApp/actions/index";
import GridConfig from "./GridConfig";
import {
  refreshOutline,
  constructOutline,
  chevronBackOutline,
  chevronForwardOutline,
} from "ionicons/icons";
import {
  IState,
  IUnumGrid,
  IScreen,
  IUnumGridColumnFormat,
  IUnumSolution,
} from "../../../../../utilities/types";
import utilities from "../../../../../utilities";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonInput,
  IonProgressBar,
  IonText,
} from "@ionic/react";
import DefaultCard from "./DefaultCard";
import {
  REFRESH_COMPONENT_ACTION,
  SET_IS_LOADING_COMPONENT_ACTION,
} from "../../../../../store/unumApp/utilities/avancedComponentsUtils";
import ComponentLoading from "../../generals/ComponentLoading";

const defaultRows = 3;
const Grid = (props: any) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const [properties, setProperties] = useState<IUnumGrid>(
    props.componentState.properties || {}
  );
  const [data, setData] = useState<Array<any>>(props.componentState.data || []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [configVisibility, setConfVisibility] = useState<boolean>(false);
  const [columnIndexFilter, setColumnIndexFilter] = useState<number>(-1);
  const [filterDirecton, setFilterDirection] = useState<boolean>(false);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    rows: defaultRows,
  });

  const [primaryColor, setPrimaryColor] = useState<string>("");
  //const [secundaryColor, setSecundaryColor] = useState<string>('')
  const secundaryColor = "#fff";

  useEffect(() => {
    const unumSolution: IUnumSolution = props.unumSolution;
    setPrimaryColor(unumSolution.cdPrincipalColor || "");
    //setSecundaryColor(unumSolution.cdSecundaryColor || '#fff')
  }, [props.unumSolution]);

  useEffect(() => {
    const listener = (ev: any) => {
      ev.detail.register(10, (processNextHandler: any) => {
        if (configVisibility) {
          setConfVisibility(false);
        } else {
          processNextHandler();
        }
      });
    };
    document.addEventListener("ionBackButton", listener);
    return () => {
      document.removeEventListener("ionBackButton", listener);
    };
  }, [configVisibility]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      executeGetQueryResult(
        props.cdProject,
        props.cdScreen,
        properties.key,
        properties.query,
        properties.input,
        properties.type
      )
    );
  }, [dispatch, properties, props.cdProject, props.cdScreen]);

  useEffect(() => {
    const actions = props.componentState.actions;
    if (
      actions &&
      actions[REFRESH_COMPONENT_ACTION] &&
      actions[REFRESH_COMPONENT_ACTION].active
    ) {
      setSearch("");
      setConfVisibility(false);
      setColumnIndexFilter(-1);
      setFilterDirection(false);
      setPagination({
        current: 1,
        rows: defaultRows,
      });
      dispatch(
        checkGridRow(
          props.cdProject,
          props.cdScreen,
          props.info.key,
          false,
          -1,
          false,
          true
        )
      );
      dispatch(
        completeComponentAction(
          props.cdProject,
          props.cdScreen,
          props.info.key,
          REFRESH_COMPONENT_ACTION
        )
      );
    } else if (
      actions &&
      actions[SET_IS_LOADING_COMPONENT_ACTION] &&
      actions[SET_IS_LOADING_COMPONENT_ACTION].active
    ) {
      setIsLoading(!!actions[SET_IS_LOADING_COMPONENT_ACTION].data);
      dispatch(
        completeComponentAction(
          props.cdProject,
          props.cdScreen,
          props.info.key,
          SET_IS_LOADING_COMPONENT_ACTION
        )
      );
    }
  }, [
    props.componentState.actions,
    props.info.key,
    props.cdProject,
    props.cdScreen,
    dispatch,
  ]);

  useEffect(() => {
    setProperties(props.componentState.properties || {});
  }, [props.componentState.properties]);

  useEffect(() => {
    if (Array.isArray(props.componentState.data)) {
      setData(props.componentState.data);
      setIsLoading(false);
    }
  }, [props.componentState.data]);

  const handleRefreshData = () => {
    setProperties(props.componentState.properties || {});
    setSearch("");
    setIsLoading(true);
    setConfVisibility(false);
    setColumnIndexFilter(-1);
    setFilterDirection(false);
    setPagination({
      current: 1,
      rows: defaultRows,
    });
    dispatch(
      executeGetQueryResult(
        props.cdProject,
        props.cdScreen,
        properties.key,
        properties.query,
        properties.input,
        properties.type
      )
    );
  };

  const handleConfigApply = (config: any) => {
    const thereIsChange =
      pagination.rows !== config.rows ||
      config.filterDirecton !== filterDirecton ||
      config.columnIndexFilter !== columnIndexFilter ||
      properties.columns !== config.columns;
    setProperties({
      ...properties,
      columns: config.columns,
    });
    setConfVisibility(false);
    setPagination({
      ...pagination,
      current: thereIsChange ? 1 : pagination.current,
      rows: config.rows,
    });
    if (config.columnIndexFilter === -1) return;
    const column: IUnumGridColumnFormat =
      properties.columns[config.columnIndexFilter];
    let isString = false;
    const dataAux = Array.isArray(data)
      ? data.map((rowState: any) => {
          if (typeof rowState.data[column.data] === "string") {
            isString = true;
          }
          return {
            ...rowState,
            data: {
              ...rowState.data,
              [column.data]:
                rowState.data[column.data] === undefined
                  ? ""
                  : rowState.data[column.data],
            },
          };
        })
      : [];
    setColumnIndexFilter(config.columnIndexFilter);
    setFilterDirection(config.filterDirecton);
    const newData = utilities.arrSort(dataAux, [
      {
        attr: `data.${column.data}`,
        asc: isString ? !config.filterDirecton : !!config.filterDirecton,
      },
    ]);
    dispatch(
      setComponentData(props.cdProject, props.cdScreen, properties.key, newData)
    );
  };

  const handlePreviusPage = () => {
    const newPage = pagination.current - 1 <= 0 ? 1 : pagination.current - 1;
    setPagination({
      ...pagination,
      current: newPage,
    });
  };

  const handleRefreshPage = () => {
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleNextPage = (currentLength: number) => {
    const newPage = pagination.current + 1;
    const aux = currentLength / pagination.rows;
    const lastPage = Number.isInteger(aux) ? aux : aux + 1;
    setPagination({
      ...pagination,
      current: newPage <= lastPage ? newPage : newPage - 1,
    });
  };

  const handleChangeChecked = (val: boolean, rowId: number) => {
    const dataIndex = data.findIndex((item: any) => {
      return item.id === rowId;
    });
    dispatch(
      checkGridRow(
        props.cdProject,
        props.cdScreen,
        properties.key,
        val,
        rowId,
        data[dataIndex].isChecked
      )
    );
  };

  const cardsRender = Array.isArray(data)
    ? data
        .filter((rowState: any) => {
          if (search !== "") {
            const isVisible = Object.keys(rowState.data).reduce(
              (result: boolean, itemKey: any) => {
                if (result) return result;
                if (
                  properties.columns.findIndex(
                    (item) => item.data === itemKey && item.mask.type === "img"
                  ) >= 0
                ) {
                  return result;
                }
                return (
                  rowState.data[itemKey]
                    .toString()
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) >= 0
                );
              },
              false
            );
            return isVisible;
          }
          return true;
        })
        .map((rowState: any, rowIndex: any) => {
          const firstRow = (pagination.current - 1) * pagination.rows;
          const lastRow = firstRow + pagination.rows - 1;
          if (firstRow > rowIndex || rowIndex > lastRow) return null;
          return (
            <DefaultCard
              key={rowIndex}
              data={rowState.data}
              isChecked={rowState.isChecked}
              columns={properties.columns}
              mobileconf={properties.mobileconf || []}
              onChangeChecked={(val: boolean) =>
                handleChangeChecked(val, rowState.id)
              }
              primaryColor={primaryColor}
              secundaryColor={secundaryColor}
            />
          );
        })
    : [];

  return (
    <IonCard id={properties.key} className="mx-0 position-relative">
      <IonCardHeader className="bg-transparent">
        <IonCardTitle className="font-weight-bold unum-font-size-normal">
          {properties.label}
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="px-0 bg-transparent position-unset">
        <div className="d-flex flex-column">
          <div className="d-flex px-3">
            <IonButton
              style={{
                backgroundColor: primaryColor,
                "--background": primaryColor,
                color: secundaryColor,
              }}
              onClick={handleRefreshData}
            >
              <IonIcon icon={refreshOutline} />
            </IonButton>
            {properties.showSettingsMobile ? (
              <IonButton
                style={{
                  backgroundColor: primaryColor,
                  "--background": primaryColor,
                  color: secundaryColor,
                }}
                onClick={() => {
                  setConfVisibility(true);
                }}
              >
                <IonIcon icon={constructOutline} />
              </IonButton>
            ) : null}
            <IonInput
              className="border unum-font-size-small"
              type="text"
              placeholder="Buscar"
              value={search}
              onIonChange={(e) => {
                setSearch(e.detail.value!);
                handleRefreshPage();
              }}
            />
          </div>
          {isLoading ? (
            <div>
              <IonProgressBar type="indeterminate"></IonProgressBar>
              <ComponentLoading />
            </div>
          ) : null}
        </div>
        {cardsRender.length ? (
          cardsRender
        ) : !isLoading ? (
          <div className="text-center py-5">No se encontraron resultados</div>
        ) : null}
        <div className="d-flex justify-content-center">
          <IonButton
            color="light"
            onClick={() => {
              handlePreviusPage();
            }}
          >
            <IonIcon
              style={{
                color: primaryColor,
              }}
              icon={chevronBackOutline}
            />
          </IonButton>
          <IonButton
            style={{
              backgroundColor: primaryColor,
              "--background": primaryColor,
              color: secundaryColor,
            }}
          >
            <IonText>{pagination.current}</IonText>
          </IonButton>
          <IonButton
            color="light"
            onClick={() => {
              handleNextPage(cardsRender.length);
            }}
          >
            <IonIcon
              style={{
                color: primaryColor,
              }}
              icon={chevronForwardOutline}
            />
          </IonButton>
        </div>
      </IonCardContent>
      {configVisibility ? (
        <GridConfig
          onHide={() => {
            setConfVisibility(false);
          }}
          onConfigApply={handleConfigApply}
          title={properties.label}
          columns={properties.columns}
          columnIndexFilter={columnIndexFilter}
          filterDirecton={filterDirecton}
          defaultRows={defaultRows}
          rows={pagination.rows}
          primaryColor={primaryColor}
          secundaryColor={secundaryColor}
        />
      ) : null}
    </IonCard>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens;
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject;
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen;
    return isSameProject && isSameScreen;
  });
  const screen: IScreen = screens[screenIndex] || {};
  const components = screen.components || {};
  const solution = state.solutions[state.app.currentSolution];
  return {
    componentState: components[ownProps.info.key] || {},
    unumSolution: solution?.unumSolution || {},
  };
};

export default connect(mapStateToProps)(Grid);
