import React/*, { useEffect, useState }*/ from 'react';

import Select from './avanced/select/Select'
import BasicInput from './basics/BasicInput'
import Panel from './design/Panel'
import Columns from './design/Columns'
import Column from './design/Column'
import Tabs from './design/tabs/Tabs'
import Fieldset from './design/Fieldset'
import Grid from './avanced/grid/Grid'
import Chart from './avanced/chart/Chart'
import Colorpiker from './avanced/colorpicker/Colorpicker'
import Timeline from './avanced/timeline/Timeline'
import Viewer from './avanced/viewer/Viewer'
import Workcontext from './avanced/workcontext/Workcontext'
import File from './avanced/File'
import Image from './basics/Image'
import Navigator from './avanced/navigator/Navigator'

import { BASIC_INPUT_TYPES } from '../../../store/unumApp/utilities/basicInputUtils'
import {
  SELECT_COMPONENT_TYPE,
  GRID_COMPONENT_TYPE,
  CHART_COMPONENT_TYPE,
  COLORPICKER_COMPONENT_TYPE,
  VIEWER_COMPONENT_TYPE,
  TIMELINE_COMPONENT_TYPE,
  WORKCONTEXT_COMPONENT_TYPE,
  FILE_COMPONENT_TYPE,
  IMAGE_COMPONENT_TYPE,
  NAVIGATOR_COMPONENT_TYPE
} from '../../../store/unumApp/utilities/avancedComponentsUtils'
import { COLUMNS_COMPONENT_TYPE, COLUMN_COMPONENT_TYPE, FIELDSET_COMPONENT_TYPE, PANEL_COMPONENT_TYPE, TABS_COMPONENT_TYPE } from '../../../store/unumApp/utilities/containersUtils';

const getComponent = (type: string, info: any, cdProject: string, cdScreen: string, level: number/*, noRenderComplexComponent:boolean=false*/) => {
  /*const complexComponents = ['tabs']
  if (noRenderComplexComponent && complexComponents.includes(type)) {
    return null
  }*/
  if (BASIC_INPUT_TYPES.includes(type)) {
    return (
      <BasicInput
        info={info}
        cdProject={cdProject}
        cdScreen={cdScreen}
      />
    )
  }
  switch (type) {
    case SELECT_COMPONENT_TYPE:
      return (
        <Select
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
    case FILE_COMPONENT_TYPE:
      return (
        <File 
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
    case PANEL_COMPONENT_TYPE:
      return (
        <Panel
          level={level}
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
    case COLUMNS_COMPONENT_TYPE:
      return (
        <Columns
          level={level}
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
    case COLUMN_COMPONENT_TYPE:
      return (
        <Column
          level={level}
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
    case TABS_COMPONENT_TYPE:
      return (
        <Tabs
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
    case FIELDSET_COMPONENT_TYPE:
      return (
        <Fieldset
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
    case GRID_COMPONENT_TYPE:
      return (
        <Grid
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
    case CHART_COMPONENT_TYPE:
      return (
        <Chart
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
    case COLORPICKER_COMPONENT_TYPE:
      return (
        <Colorpiker
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
    case VIEWER_COMPONENT_TYPE:
      return (
        <Viewer
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
    case TIMELINE_COMPONENT_TYPE:
      return (
        <Timeline
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
    case WORKCONTEXT_COMPONENT_TYPE:
      return (
        <Workcontext
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
      )
      case IMAGE_COMPONENT_TYPE:
        return(
          <Image
          info={info}
          cdProject={cdProject}
          cdScreen={cdScreen}
        />
        )
        case NAVIGATOR_COMPONENT_TYPE:
          return(
            <Navigator
            info={info}
            cdProject={cdProject}
            cdScreen={cdScreen}
          />
          )
    default:
      return null
  }
}

const UnumComponent = (props: any) => {
  /*const [noRenderComplexComponent, setNoRenderComplexComponent] = useState<boolean>(props.noRenderComplexComponent || false)

  useEffect(() => {
    setNoRenderComplexComponent(props.noRenderComplexComponent)
  }, [props.noRenderComplexComponent])*/

  return (
    <>
      {
        getComponent(props.info.type, props.info, props.cdProject, props.cdScreen, props.level/*, noRenderComplexComponent*/)
      }
    </>
  );
};

export default UnumComponent;

