
import { IonIcon } from '@ionic/react';
import React, { useContext } from 'react';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';
import { caretForwardOutline, caretDownOutline } from 'ionicons/icons';

export default function CustomToggle(props: any) {
    const { children, eventKey } = props
    const currentEventKey = useContext(AccordionContext)
    const isCurrentEventKey = currentEventKey === eventKey

    const decoratedOnClick = useAccordionButton(eventKey)

    const handleOnClick = (e: any) => {
        if (props.disabled || isCurrentEventKey) return
        decoratedOnClick(e)
    }

    return (
        <div className="d-flex justify-content-" onClick={handleOnClick}>
            {children}
            <IonIcon
                className="ml-auto"
                icon={isCurrentEventKey ? caretDownOutline : caretForwardOutline}
            />
        </div>
    );
}