
export const PANEL_COMPONENT_TYPE: string = 'panel'
export const COLUMNS_COMPONENT_TYPE: string = 'columns'
export const COLUMN_COMPONENT_TYPE: string = 'column'
export const TABS_COMPONENT_TYPE: string = 'tabs'
export const FIELDSET_COMPONENT_TYPE: string = 'fieldset'

export const SET_TAB_INDEX_COMPONENT_ACTION: string = 'setTabIndex'

export const CONTAINER_TYPES:Array<string> = [
    PANEL_COMPONENT_TYPE,
    COLUMNS_COMPONENT_TYPE,
    COLUMN_COMPONENT_TYPE,
    TABS_COMPONENT_TYPE,
    FIELDSET_COMPONENT_TYPE
]
