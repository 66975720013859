import React, { useEffect, useState } from 'react'
import { IonHeader, IonToolbar, IonMenuButton, IonButtons, IonTitle, IonIcon } from '@ionic/react';
import {
  menuOutline
 } from 'ionicons/icons';
import { IUnumSolution } from '../../utilities/types';


const HeaderUnun = (props:any) => {
  const [bgColor, setBgColor] = useState<string>('')

  useEffect(() => {
    const unumSolution: IUnumSolution = props.unumSolution
    setBgColor(unumSolution.cdPrincipalColor || '')
  }, [props.unumSolution])

  return (
    <IonHeader
      style={{
        backgroundColor: bgColor
      }}
      className={`${bgColor?bgColor:"bg-unum-primary"}`}
    >
      <IonToolbar 
        className={`bg-transparent`}
      >
        <IonButtons slot="start">
          <IonMenuButton>
            <div className="custom-icon d-flex align-items-center m-0 p-1">
              <IonIcon 
                style={{
                  color: '#fff'
                }}
                icon={menuOutline} 
                size="large" />
            </div>
          </IonMenuButton>
        </IonButtons>
        <IonTitle
          className="unum-font-size-normal"
          style={{
            color: '#fff'
          }}
        >
          { props.title }
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  )
}

export default HeaderUnun