import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { completeComponentAction, executeGetQueryResult } from '../../../../../store/unumApp/actions/index'
import { IState, IScreen, IChart, IUnumChart, IUnumSolution } from '../../../../../utilities/types'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonProgressBar } from '@ionic/react';
import { Chart as GoogleChart } from "react-google-charts";
import { unumChartTypeToGoogleType, createConfigChart, REFRESH_COMPONENT_ACTION, SET_IS_LOADING_COMPONENT_ACTION, DOUNT_CHART_TYPE, PIE3D_CHART_TYPE } from '../../../../../store/unumApp/utilities/avancedComponentsUtils';
import ComponentLoading from '../../generals/ComponentLoading';

interface IProps {
  cdProject: string,
  cdScreen: string,
  componentState: IChart,
  unumSolution: IUnumSolution,
  info: any
}

const Chart = (props: IProps) => {
  const dispatch = useDispatch()
  const [properties, setProperties] = useState<IUnumChart>(props.componentState.properties || {})
  const [data, setData] = useState<Array<any>>(props.componentState.data || [])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const actions: any = props.componentState.actions
    if (actions && actions[REFRESH_COMPONENT_ACTION] && actions[REFRESH_COMPONENT_ACTION].active) {
      setData([])
      setIsLoading(false)
      dispatch(completeComponentAction(props.cdProject, props.cdScreen, props.info.key, REFRESH_COMPONENT_ACTION))
    } else if (actions && actions[SET_IS_LOADING_COMPONENT_ACTION] && actions[SET_IS_LOADING_COMPONENT_ACTION].active) {
      setIsLoading(!!actions[SET_IS_LOADING_COMPONENT_ACTION].data)
      dispatch(completeComponentAction(props.cdProject, props.cdScreen, props.info.key, SET_IS_LOADING_COMPONENT_ACTION))
    }
  }, [props.componentState.actions, props.info.key, props.cdProject, props.cdScreen, dispatch])

  useEffect(() => {
    setProperties(props.componentState.properties || {})
  }, [props.componentState.properties])

  useEffect(() => {
    if (Array.isArray(props.componentState.data)) {
      setData(props.componentState.data)
      setIsLoading(false)
    }
  }, [props.componentState.data])

  useEffect(() => {
    if (!properties.showMobile) return
    setIsLoading(true)
    dispatch(executeGetQueryResult(props.cdProject, props.cdScreen, properties.key, properties.query, properties.input, properties.type))
  }, [dispatch, properties.key, properties.query, properties.input, properties.type, props.cdProject, props.cdScreen, properties.showMobile])

  const emptyDataMessage = <div className="text-center py-5">No se encontraron datos para graficar.</div>

  const googleRender = () => {
    if (data.length === 0) {
      return emptyDataMessage
    }

    return (
      createConfigChart(properties, data).map((item, index) => {
        const chartType = unumChartTypeToGoogleType(item.chartType)
        const isPie = ['donut', 'pie', 'pie3D'].includes(item.chartType)
        const options = {
          legend: { position: properties.group || isPie ? 'bottom' : 'none' },
          pieHole: item.chartType === DOUNT_CHART_TYPE ? 0.4 : undefined,
          is3D: item.chartType === PIE3D_CHART_TYPE,
          series: item.colors,
          slices: properties.group ? {} : item.colors,
          chartArea: { width: '75%' }, // 100% para leer el nombre del estado completo
          hAxis: {
            textPosition: 'out', 
            slantedText: true
          },
          theme: 'material'
        }
        return (
          <GoogleChart
            key={index}
            chartType={chartType || item.chartType}
            data={item.dataChart}
            options={options}
            width={375}
            height={150}
          />
        )
      })
    )
  }

  if (!properties.showMobile) return null

  return (
    <IonCard
      id={properties.key}
      className="mx-0 position-relative">
      <IonCardHeader className="bg-transparent">
        <IonCardTitle className="font-weight-bold unum-font-size-normal">{properties.title}</IonCardTitle>
        <hr className="mb-0" />
      </IonCardHeader>
      <IonCardContent className="px-0 bg-transparent position-unset">
        {
          isLoading ?
            (
              <div>
                <IonProgressBar type="indeterminate"></IonProgressBar>
                <ComponentLoading />
              </div>
            ) : null
        }
        {
          googleRender()
        }
      </IonCardContent>
    </IonCard>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen
    return isSameProject && isSameScreen
  })
  const screen: IScreen = screens[screenIndex] || {}
  const components = screen.components || {}
  const solution = state.solutions[state.app.currentSolution]
  return {
    componentState: components[ownProps.info.key] || {},
    unumSolution: solution?.unumSolution || {}
  }
}

export default connect(mapStateToProps)(Chart)