import { forIn, groupBy, map, reduce } from 'lodash'
import { IDataItemGrid, IGrid, IUnumChart, IUnumChartSettings, IUnumFile, IUnumSelect, IUnumSelectDataValue } from '../../../utilities/types'

export const SELECT_COMPONENT_TYPE: string = 'select'
export const GRID_COMPONENT_TYPE: string = 'grid'
export const CHART_COMPONENT_TYPE: string = 'chart'
export const COLORPICKER_COMPONENT_TYPE: string = 'colorpicker'
export const VIEWER_COMPONENT_TYPE: string = 'viewer'
export const TIMELINE_COMPONENT_TYPE: string = 'timeline'
export const WORKCONTEXT_COMPONENT_TYPE: string = 'workcontext'
export const FILE_COMPONENT_TYPE: string = 'file'
export const IMAGE_COMPONENT_TYPE: string = 'image'
export const NAVIGATOR_COMPONENT_TYPE: string = 'navigator'

export const REFRESH_COMPONENT_ACTION: string = 'refreshComponent'
export const SET_IS_LOADING_COMPONENT_ACTION: string = 'setIsLoadingComponentAction'


export const AVANCED_COMPONENTS_TYPES: Array<string> = [
  FILE_COMPONENT_TYPE,
  GRID_COMPONENT_TYPE,
  SELECT_COMPONENT_TYPE,
  CHART_COMPONENT_TYPE,
  COLORPICKER_COMPONENT_TYPE,
  VIEWER_COMPONENT_TYPE,
  TIMELINE_COMPONENT_TYPE,
  WORKCONTEXT_COMPONENT_TYPE,
  IMAGE_COMPONENT_TYPE,
  NAVIGATOR_COMPONENT_TYPE
]

export const GRID_SELECTSTYLE_SINGLE: string = 'single'

export const COLOR_HEX_FORMAT: string = 'hex'
export const COLOR_RGB_FORMAT: string = 'rgb'

export const COLUMN_CHART_TYPE: string = 'column'
export const LINE_CHART_TYPE: string = 'line'
export const BAR_CHART_TYPE: string = 'bar'
export const PIE_CHART_TYPE: string = 'pie'
export const PIE3D_CHART_TYPE: string = 'pie3D'
export const AREA_CHART_TYPE: string = 'area'
export const CANDLESTICK_CHART_TYPE: string = 'candlestick'
export const DOUNT_CHART_TYPE: string = 'donut'
export const SCATTER_CHART_TYPE: string = 'scatter'
export const BUBBLE_CHART_TYPE: string = 'bubble'
export const GAUGE_CHART_TYPE: string = 'gauge'
export const STACK_BARS_CHART_TYPE: string = 'stackBars'
export const STACK_BARS_COLUMN_CHART_TYPE: string = 'stackBarsColumn'

export const CHART_GOOGLE_LIBRARY: string = 'google'
export const CHART_CHARTJS_LIBRARY: string = 'chartjs'

export const googleChartTypes = {
  [COLUMN_CHART_TYPE]: "ColumnChart",
  [LINE_CHART_TYPE]: "LineChart",
  [BAR_CHART_TYPE]: "BarChart",
  [PIE_CHART_TYPE]: "PieChart",
  [PIE3D_CHART_TYPE]: "PieChart",
  [AREA_CHART_TYPE]: "AreaChart",
  [BUBBLE_CHART_TYPE]: "BubbleChart",
  [GAUGE_CHART_TYPE]: "Gauge",
  [SCATTER_CHART_TYPE]: "ScatterChart",
  [CANDLESTICK_CHART_TYPE]: "CandlestickChart",
  [DOUNT_CHART_TYPE]: "PieChart"
}

export const CHART_TYPES: Array<string> = [
  COLUMN_CHART_TYPE,
  LINE_CHART_TYPE,
  BAR_CHART_TYPE,
  PIE_CHART_TYPE,
  PIE3D_CHART_TYPE,
  AREA_CHART_TYPE,
  CANDLESTICK_CHART_TYPE,
  DOUNT_CHART_TYPE,
  SCATTER_CHART_TYPE,
  BUBBLE_CHART_TYPE,
  GAUGE_CHART_TYPE,
  STACK_BARS_CHART_TYPE,
  STACK_BARS_COLUMN_CHART_TYPE,
]

export function groupingAndTransposeIsRequiredByChartType(chartType: string) {
  if (chartType === googleChartTypes[COLUMN_CHART_TYPE] ||
    chartType === googleChartTypes[LINE_CHART_TYPE] ||
    chartType === googleChartTypes[BAR_CHART_TYPE] ||
    chartType === googleChartTypes[PIE_CHART_TYPE] ||
    chartType === googleChartTypes[AREA_CHART_TYPE] ||
    chartType === googleChartTypes[GAUGE_CHART_TYPE] ||
    chartType === googleChartTypes[DOUNT_CHART_TYPE]
  ) {
    return true
  }
  return false
}

export function getErrorStyle(properties: any) {
  if (properties.type === SELECT_COMPONENT_TYPE) {
    return {
      input: {
        border: 'solid thin red',
      }
    }
  }
  return {

  }
}

export function getGridDataState(data: Array<any>) {
  if (!Array.isArray(data)) {
    return []
  }
  return data.map((item: any, index: number) => {
    return {
      data: item,
      isChecked: false,
      id: index
    }
  })
}

export function getChartLabels(setting: IUnumChartSettings) {
  return setting.setting.map(item => {
    return item.title
  })
}

export function getChartColors(setting: IUnumChartSettings, defaultColor: string) {
  return setting.setting.map(item => {
    return item.color ? item.color : defaultColor
  })
}

export function groupChartData(dataValues: Array<any>) {

  if (!Array.isArray(dataValues[0])) {
    return dataValues.map((val: any) => {
      let value = val
      if (!Number.isNaN(Number(value))) {
        value = Number.parseFloat(val)
      }
      return 0
    })
  }
  return dataValues.reduce((result: Array<any>, row: Array<any>) => {
    let res: Array<any> = []
    row.forEach((val: any, index: number) => {
      let sum = result[index]
      let v = val
      if (!Number.isNaN(Number(sum))) {
        sum = Number.parseFloat(sum)
      } else {
        sum = 0
      }
      if (!Number.isNaN(Number(v))) {
        v = Number.parseFloat(v)
      } else {
        v = 0
      }
      res.push(sum + v)
    })
    return res
  }, dataValues)
}

export const createConfigChart = (chartParams: IUnumChart, dataParam: Array<any>) => {
  const { charts, group } = chartParams;
  const summ = (a: any, b: any) => {
    const number = Number.parseFloat(b);
    return Number.isNaN(number) ? a : a + number
  };
  if (group) {
    return map(charts, ({ eje, chartType, setting, legendPosition, operation }) => {
      const groupByEje = groupBy(dataParam, eje);
      const dataChart = [];
      forIn(groupByEje, (v, k: any) => {
        const addRow = map(setting, (conf) => {
          switch (operation) {
            case 'count':
              return v.length
            case 'average':
              return reduce(v, (a, b) => summ(a, b[conf.value]), 0) / v.length
            default:
              return reduce(v, (a, b) => summ(a, b[conf.value]), 0)
          }
        })       
         addRow.unshift(k)
        dataChart.push(addRow)
      })
      const titles = map(setting, 'title')
      const colors = map(setting, (conf) => ({ color: conf.color }))
      titles.unshift('')
      dataChart.unshift(titles)
      return { chartType, dataChart, colors, legendPosition }
    })
  }
  return map(charts, ({ chartType, setting, legendPosition }) => {
    const dataChart = [];
    const colors = map(setting, (conf) => {
      const dta = [conf.title, reduce(map(dataParam, conf.value), summ, 0), conf.color]
      dataChart.push(dta)
      return { color: conf.color }
    })
    dataChart.unshift(['', '', { role: 'style' }])
    return { chartType, dataChart, colors, legendPosition }
  })
}

/*export function getChartChartjsLibraryParams(chartParams: IUnumChart, dataParam: Array<any>, setting: IUnumChartSettings) {
  let dataValues:any = dataParam.map(dataItem => {
    return setting.setting.map(serieItem => {
      return dataItem[serieItem.value] === undefined?0:dataItem[serieItem.value]
    })
  })
  if (chartParams.groupData) {
    dataValues = groupChartData(dataValues)
  }
  if (chartParams.library === CHART_CHARTJS_LIBRARY) {
    const backgroundColor = getChartColors(setting)
    if (!chartParams.groupData) {
      dataValues = dataValues.map((item: Array<any>) => {
        return {
          data: item,
          backgroundColor: backgroundColor,
          borderWidth: 1
        }
      })
    } else {
      dataValues = [{
        data: dataValues,
        backgroundColor: backgroundColor,
        borderWidth: 1
      }]
    }
  }
  return dataValues
}
*/
export function formatSelectDataValues(dataParam: Array<IUnumSelectDataValue>) {
  if (!Array.isArray(dataParam)) {
    return []
  }
  return dataParam.map(item => {
    let val = item.value
    if (!Number.isNaN(Number(val))) {
      val = Number.parseFloat(val)
    }
    return {
      ...item,
      value: val
    }
  })
}

export function getAvancedComponentDataState(unumParams: any, data: any) {
  if (unumParams.type === GRID_COMPONENT_TYPE) {
    return getGridDataState(data)
  }
  return data
}

export function unumChartTypeToGoogleType(charType: any) {
  return googleChartTypes[charType]
}

export function getAvancedComponentState(unumProps: any, defaultConfiguration: any) {
  return {
    properties: unumProps,
    data: [],
    actions: {}
  }
}

export function getSelectState(unumProps: IUnumSelect) {
  const dataValues = formatSelectDataValues(unumProps.data.values)
  return {
    properties: {
      ...unumProps,
      data: {
        values: dataValues
      }
    },
    value: unumProps.defaultValue,
    errorStyle: getErrorStyle(unumProps),
    initialStyle: {},
    isInitial: true,
    isValid: true,
    actions: {},
    data: []
  }
}

export function getFileState(unumProps: IUnumFile) {
  return {
    properties: unumProps,
    value: unumProps.defaultValue,
    valueState: unumProps.convertPdf ? [] : [],
    errorStyle: getErrorStyle(unumProps),
    initialStyle: {},
    isInitial: true,
    isValid: true,
    actions: {},
    data: []
  }
}

export function getAvancedComponentInputState(unumProps: any, defaultConfiguration: any) {
  if (unumProps.type === SELECT_COMPONENT_TYPE) {
    return getSelectState(unumProps)
  } else if (unumProps.type === FILE_COMPONENT_TYPE) {
    return getFileState(unumProps)
  }
  return {
    properties: unumProps,
    value: unumProps.defaultValue,
    errorStyle: getErrorStyle(unumProps),
    initialStyle: {},
    isInitial: true,
    isValid: true,
    actions: {},
    data: []
  }
}


export function getInputAvancedComponentsAndInputValues(avancedComponents: { [id: string]: any; }): { value: any, components: any } {
  let res: any = {
    value: {
      grids: {}
    },
    components: {

    }
  }
  Object.keys(avancedComponents).forEach((key: string) => {
    if (avancedComponents[key]?.properties?.type === GRID_COMPONENT_TYPE) {
      const gridState: IGrid = avancedComponents[key]
      if (gridState.properties.selectStyle !== GRID_SELECTSTYLE_SINGLE) {
        res.value.grids[gridState.properties.key] = []
        res.components[gridState.properties.key] = gridState
        if (Array.isArray(gridState.data)) {
          gridState.data.forEach((datState: IDataItemGrid) => {
            if (datState.isChecked) {
              res.value.grids[gridState.properties.key].push(datState.data)
            }
          })
        }
      }
    }
  })
  return res
}