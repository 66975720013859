import {
  IGrid,
  IScreen,
  IState,
  IUnumGridColumnFormat,
} from "../../../../utilities/types";
import { getInputsValues } from "../../utilities";
import {
  getAvancedComponentDataState,
  GRID_SELECTSTYLE_SINGLE,
  SET_IS_LOADING_COMPONENT_ACTION,
} from "../../utilities/avancedComponentsUtils";
import { getFormattedValue } from "../../utilities/basicInputUtils";
import { executeButton, updateComponents } from "./generalComponentsActions";
import {
  addComponentAction,
  refreshOthersApply,
} from "./transversalComponentsActions";

export const setComponentData = (
  cdProject: string,
  cdScreen: string,
  key: string,
  data: any
) => {
  return {
    type: "SET_COMPONENT_DATA",
    cdProject,
    cdScreen,
    key,
    data: data,
  };
};

export const checkGridRow = (
  cdProject: string,
  cdScreen: string,
  gridKey: string,
  val: boolean,
  rowId: number,
  isChecked: boolean,
  isReset: boolean = false
) => {
  return (dispatch: any, getState: any) => {
    const state: IState = getState();
    const screens: { [id: string]: IScreen } = state.screens;
    const screenIndex = Object.keys(screens).findIndex((item) => {
      const isSameProject = screens[item].cdProject === cdProject;
      const isSameScreen = screens[item].cdScreen === cdScreen;
      return isSameProject && isSameScreen;
    });
    const screen = screens[screenIndex];
    const grid: IGrid = screen.components[gridKey];
    let indexRemoveCheck = -1,
      indexCurrentItem = -1;
    if (val && grid.properties.selectStyle === GRID_SELECTSTYLE_SINGLE) {
      indexRemoveCheck = grid.data.findIndex((element: any) => {
        return element.isChecked;
      });
    }
    let newData: Array<any> = [];
    if (!isReset) {
      newData = grid.data.map((element: any, index: any) => {
        if (indexRemoveCheck !== -1 && indexRemoveCheck === index) {
          return {
            ...element,
            isChecked: false,
          };
        }
        if (element.id === rowId) {
          indexCurrentItem = index;
          return {
            ...element,
            isChecked: val,
          };
        }
        return element;
      });
      dispatch(setComponentData(cdProject, cdScreen, gridKey, newData));
    }
    if (indexCurrentItem !== -1) {
      let inputs: any = {};
      grid.properties.columns.forEach((element: IUnumGridColumnFormat) => {
        const mapWith = element.mapWith.split(",");
        mapWith.forEach((keyForMap: string) => {
          if (keyForMap) {
            if (screen.components[keyForMap]) {
              const newVal = getFormattedValue(
                newData[indexCurrentItem].data[element.data],
                screen.components[keyForMap].properties
              );
              inputs[keyForMap] = {
                ...screen.components[keyForMap],
                value: isChecked ? "" : newVal,
                isValid: true,
              };
            } else {
              const newVal = newData[indexCurrentItem].data[element.data];
              inputs[keyForMap] = {
                properties: {
                  input: true,
                  key: keyForMap,
                },
                actions: {},
                value: isChecked ? "" : newVal,
                isValid: true,
              };
            }
          }
        });
      });
      dispatch(updateComponents(cdProject, cdScreen, inputs));
    } else if (isReset) {
      let inputs: any = {};
      grid.properties.columns.forEach((element: IUnumGridColumnFormat) => {
        const mapWith = element.mapWith.split(",");
        mapWith.forEach((keyForMap: string) => {
          if (keyForMap) {
            if (screen.components[keyForMap]) {
              inputs[keyForMap] = {
                ...screen.components[keyForMap],
                value: "",
                isValid: true,
              };
            } else {
              inputs[keyForMap] = {
                properties: {
                  input: true,
                  key: keyForMap,
                },
                actions: {},
                value: "",
                isValid: true,
              };
            }
          }
        });
      });
      dispatch(updateComponents(cdProject, cdScreen, inputs));
    }
    //onTriggerChange
    const onTriggerChange = grid.properties.onTriggerChange || "";
    if (onTriggerChange && val) {
      dispatch(executeButton(cdProject, cdScreen, onTriggerChange));
    }
    //Refresh others
    const refreshOthers = grid.properties.refreshOthers || [];
    if (refreshOthers.length > 0) {
      dispatch(refreshOthersApply(cdProject, cdScreen, refreshOthers));
    }
  };
};

export const executeGetQueryResult = (
  cdProject: string,
  cdScreen: string,
  keyAvancedComponent: string,
  idQuery: any,
  isInput: boolean = false,
  componentType: string
) => {
  return (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      const state = getState();
      const providerServices = state.providerServices;
      const screens: { [id: string]: IScreen } = state.screens;
      const screenIndex = Object.keys(screens).findIndex((item) => {
        const isSameProject = screens[item].cdProject === cdProject;
        const isSameScreen = screens[item].cdScreen === cdScreen;
        return isSameProject && isSameScreen;
      });
      const inputs = getInputsValues(screens[screenIndex].components);
      const avancedComponent =
        screens[screenIndex].components[keyAvancedComponent];
      if (avancedComponent) {
        dispatch(
          addComponentAction(
            cdProject,
            cdScreen,
            keyAvancedComponent,
            SET_IS_LOADING_COMPONENT_ACTION,
            true
          )
        );
        try {
          const res = await providerServices.postModel(
            "/query/getQueryResult",
            {
              cdProject,
              cdScreen,
              grid: keyAvancedComponent,
              idQuery,
              ip: "",
              params: JSON.stringify(inputs),
            }
          );
          const initialDataState = getAvancedComponentDataState(
            avancedComponent.properties,
            res.data?.data
          );

          dispatch(
            setComponentData(
              cdProject,
              cdScreen,
              keyAvancedComponent,
              initialDataState
            )
          );
        } catch (error) {
          console.log(error);
          dispatch(
            setComponentData(cdProject, cdScreen, keyAvancedComponent, [])
          );
        }
        dispatch(
          addComponentAction(
            cdProject,
            cdScreen,
            keyAvancedComponent,
            SET_IS_LOADING_COMPONENT_ACTION,
            false
          )
        );
      }
    });
  };
};
