import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import Modal from "../../basics/Modal";
import CircleIcon from "@mui/icons-material/Circle";
import LabelIcon from "@mui/icons-material/Label";
import { connect, useDispatch } from "react-redux";
import { IState, IScreen } from "../../../../../utilities/types";
import {} from "../../../../../store/unumApp/utilities/avancedComponentsUtils";
import { getInputsValues } from "../../../../../store/unumApp/utilities/index";
import { addComponentAction } from "../../../../../store/unumApp/actions/screenComponentsActions/transversalComponentsActions";
import { SET_IS_LOADING_COMPONENT_ACTION } from "../../../../../store/unumApp/utilities/avancedComponentsUtils";
import {
  replace,
  find,
  each,
  split,
  isEmpty,
  union,
  clone,
  unionBy,
  orderBy,
  map,
  flatten,
  reduce,
  uniq,
  indexOf,
  camelCase,
  isEqual,
  filter,
} from "lodash";

interface CustomTreeItemProps {
  nodeId: string; // Agrega la propiedad nodeId
  label: string;
  isFinal: boolean;
  [key: string]: any;
}

const CustomTreeItem: React.FC<CustomTreeItemProps> = (props) => {
  const { label, isFinal, ...otherProps } = props;
  const icon = isFinal ? <CircleIcon /> : null; // Agrega el icono CircleIcon si es un elemento final

  return <TreeItem label={label} icon={icon} {...otherProps} />;
};

export const setComponentData = (
  cdProject: string,
  cdScreen: string,
  key: string,
  data: any
) => {
  return {
    type: "SET_COMPONENT_DATA",
    cdProject,
    cdScreen,
    key,
    data: data,
  };
};
export const getQueryResult = {
  sufix: "/query/getQueryResult",
  method: "POST",
  payload: {},
};

const TreeComponent = (props: any) => {
  const dispatch = useDispatch();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [selectedUrl, setSelectedUrl] = useState<string | null>(null);
  const [, forceUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cdNode, setCdNode] = useState<string | null>(null);
  const [indexItems, setIndexItems] = useState<any[] | null>(null);
  const [slider, setSlider] = useState<any[]>([]);
  const [properties, setProperties] = useState<any>(props.properties);
  const [sources, setSources] = useState([]);
  const [items, setItems] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [queryNum, setQueryNum] = useState(0);
  const [queries, setQueries] = useState({} || props.properties.queries);
  const [queriesCopy, setCopyQueries] = useState({});
  const [open, setOpen] = useState(false);
  const [numb, setNumb] = useState(0);
  const [buttons, setButtons] = useState(null);
  const [itemsTree, setItemsTree] = useState({});
  const [tab, addTab] = useState({});
  const [dataRender, setDataRender] = useState([]);
  const comp = props.comp.properties;
  const { queryData, querySettings } = comp;
  const itemConfig = useCallback(() => {
    return {
      key: comp.queryValue,
      value: comp.querySetting,
      nodeTitle: comp.nodeTitle,
      nodePath: comp.nodePath,
      displayed: comp.displayed,
      downloadButton: comp.downloadButton,
      openFirstItem: comp.openFirstItem,
      visibleProp: comp.visibleProp,
      columnOrder: comp.columnOrder,
      nodeOrder: comp.nodeOrder,
      urlType: comp.urlType,
      urlToShow: comp.urlToShow,
      urlToDownload: comp.urlToDownload,
      clickButton: comp.clickButton,
      queryButtons: comp.queryButtons,
      renderData: comp.renderData,
    };
  }, []);

  const setIcon = useCallback((renderData: any, row: any) => {
    const { column, operator, value, icon } = renderData;
    switch (operator) {
      case "includes":
        return row[column].includes(value) ? icon : false;
      case "===":
        return row[column] === value ? icon : false;
      case "!==":
        return row[column] !== value ? icon : false;
      case ">":
        return row[column] > value ? icon : false;
      case "<":
        return row[column] < value ? icon : false;
      default:
        return false;
    }
  }, []);
  const findItemNested = useCallback(
    (arr: any, itemId: any, nestingKey: any = "items") => {
      return arr.reduce((a: any, item: any) => {
        if (a) return a;
        if (item.cdNode === itemId) return item;
        if (item[nestingKey])
          return findItemNested(item[nestingKey], itemId, nestingKey);
        return null;
      }, null);
    },
    []
  );

  const querieValues = {};
  let queryFetchNum = 0;
  const queriesArray = (data: any, idQuery: any) => {
    queryFetchNum = queryFetchNum + 1;
    setQueryNum(queryFetchNum);
    const item = find(queryData, { query: idQuery });
    const { key } = find(querySettings, { key: item.value }) || itemConfig();

    const { columnOrder, nodeOrder } =
      find(querySettings, { key: item.value }) || itemConfig();
    const newData = map(data, (value) => ({
      ...value,
      idQuery: idQuery,
      columnOrder,
      nodeOrder,
      key,
    }));
    Object.assign(querieValues, { [idQuery]: newData });

    setQueries(querieValues);
  };
  const executeGetQueryResult = (
    cdProject: string,
    cdScreen: string,
    keyAvancedComponent: string,
    idQuery: any,
    isInput: boolean = false,
    componentType: string
  ) => {
    return (dispatch: any, getState: any) => {
      return new Promise(async (resolve, reject) => {
        const state = getState();
        const providerServices = state.providerServices;
        const screens: { [id: string]: IScreen } = state.screens;
        const screenIndex = Object.keys(screens).findIndex((item) => {
          const isSameProject = screens[item].cdProject === cdProject;
          const isSameScreen = screens[item].cdScreen === cdScreen;
          return isSameProject && isSameScreen;
        });
        const inputs = getInputsValues(screens[screenIndex].components);
        const avancedComponent =
          screens[screenIndex].components[keyAvancedComponent];
        if (avancedComponent) {
          dispatch(
            addComponentAction(
              cdProject,
              cdScreen,
              keyAvancedComponent,
              SET_IS_LOADING_COMPONENT_ACTION,
              true
            )
          );
          try {
            const res = await providerServices.postModel(
              "/query/getQueryResult",
              {
                cdProject,
                cdScreen,
                grid: keyAvancedComponent,
                idQuery,
                ip: "",
                params: JSON.stringify(inputs),
              }
            );
            queriesArray(res.data.data, idQuery);
          } catch (error) {
            console.log(error);
          }
        }
      });
    };
  };

  useEffect(() => {
    if (isEmpty(sources)) return;
    setItems(sources);
    setSources([]);
  }, [sources, expanded, queryNum]);

  useEffect(() => {
    const { queryData } = comp;
    if (!queries || queryData.length !== queryNum) return;
    if (isEqual(queries, queriesCopy)) return;
    setCopyQueries(queries);
    const { querySettings, openFirstItem, oderConfig } = comp;
    const isQNC = oderConfig === "queryNodeColumn";
    // const isNC = oderConfig === 'nodeColumn';
    const isNQC = oderConfig === "nodeQueryColumn";

    const newOrderData = flatten(
      reduce(
        map(queryData, "query"),
        (result: any, value: any, index: any) => {
          if (queries[value]) {
            const newData = map(queries[value], (val: any) => ({
              ...val,
              queryOrder: index + 1,
            }));
            const nodeOrder = uniq(map(newData, "nodeOrder")) as string[];
            const columnOrder = uniq(map(newData, "columnOrder")) as string[];
            const orderData = orderBy(newData, union(nodeOrder, columnOrder), [
              "asc",
            ]);
            result.push(orderData);
          }
          return result;
        },
        []
      )
    );
    const queriesButtons = reduce(
      union(querySettings, [itemConfig()]),
      (result: any, value: any) => {
        if (value.queryButtons)
          each(value.queryButtons, (vl) => result.push(vl));
        return result;
      },
      []
    );
    if (!isEmpty(queriesButtons)) setButtons(queriesButtons);
    console.log("queries", queries);

    const datasource2 = isNQC
      ? orderBy(newOrderData, ["queryOrder"], ["asc"])
      : newOrderData;
    each(datasource2, (value: any, idx: any) => {
      const item = find(queryData, { query: value.idQuery });

      const dataConfig = find(querySettings, { key: item.value }) || {};
      const setting = isEmpty(dataConfig.value) ? itemConfig() : dataConfig;
      const {
        nodePath,
        nodeTitle,
        urlToShow,
        urlToDownload,
        displayed,
        visibleProp,
        renderData,
      } = setting;
      const splitPath = filter(
        split(value[nodePath], /,|\\|[/]/),
        (i) => !isEmpty(i)
      );
      each(splitPath, (val: any, index: any) => {
        setSources((state: any) => {
          const isItem = splitPath.length === index + 1;
          const cdNodeItem = `${replace(
            value[nodeTitle],
            " ",
            "_"
          )}_${index}_${idx}`;
          const cdNodeParent = `${replace(camelCase(val), " ", "_")}_${index}`;
          const cdNodeParentFirst = `${replace(
            camelCase(val),
            " ",
            "_"
          )}_${index}`;
          const cdNodes = map(state, "cdNode");
          const indexNode = indexOf(cdNodes, cdNodeParent);
          const isNode =
            isQNC && indexNode === -1 ? false : indexNode + 1 < cdNodes.length;
          const findItem =
            find(state, { cdNode: cdNodeParentFirst }) ||
            find(state, { cdNode: cdNodeParent });
          const isIcon = reduce(
            map(renderData, (rData) => setIcon(rData, value)),
            (prev, curr) => curr || prev,
            false
          );
          const newNode = {
            setting,
            icon: isIcon || "",
            values: value,
            cdNode: cdNodeItem,
            visible: visibleProp,
            url: value[urlToShow],
            urlDownload: value[urlToDownload],
            label: value[nodeTitle],
          };

          if (isItem)
            setItemsTree((st: any) => unionBy(st, [newNode], "cdNode"));

          if (isItem && openFirstItem) {
            setOpen((opn) => {
              if (!opn) addTab(newNode);
              return true;
            });
          }

          if (index > 0) {
            const parentIdFirst = `${camelCase(splitPath[index - 1])}_${
              index - 1
            }`;
            const parentId = `${camelCase(splitPath[index - 1])}_${index - 1}`;
            const findItemNed =
              findItemNested(state, parentId) ||
              findItemNested(state, parentIdFirst);
            console.log("state", state);
            console.log("parentId", parentId);

            const findItemTwo = find(findItemNed.items, {
              cdNode: cdNodeParent,
            });
            if (!findItemTwo) {
              setExpanded((expa: any) =>
                displayed ? union([cdNodeParent], expa) : clone(expa)
              );
              findItemNed.items.push({
                cdNode: cdNodeParent,
                label: val,
                items: isItem ? [newNode] : [],
              });
            } else if (isItem) {
              findItemTwo.items.push(newNode);
            }
          } else if (isItem && findItem) {
            findItem.items.push(newNode);
          } else if (index === 0 && !findItem) {
            setExpanded((expa: any) =>
              displayed
                ? union([isNode ? cdNodeParentFirst : cdNodeParent], expa)
                : clone(expa)
            );

            return [
              ...state,
              {
                cdNode: isNode ? cdNodeParentFirst : cdNodeParent,
                label: val,
                items: isItem ? [newNode] : [],
              },
            ];
          }

          return state;
        });
      });
    });
  }, [
    queries,
    comp,
    numb,
    findItemNested,
    itemConfig,
    setItemsTree,
    addTab,
    setIcon,
    setButtons,
    queriesCopy,
    queryNum,
  ]);
  const newArrayData = {};

  useEffect(() => {
    if (properties.queries) {
      // setIsLoading(true);
      properties.queries.forEach((querieMap: Number) => {
        dispatch(
          executeGetQueryResult(
            props.cdProject,
            props.cdScreen,
            properties.key,
            querieMap,
            properties.input,
            properties.type
          )
        );
      });
    }
  }, [
    dispatch,
    properties.key,
    properties.query,
    properties.input,
    properties.type,
    props.cdProject,
    props.cdScreen,
  ]);

  const deleteFromSlide = (array: any, objeto: any) => {
    const indice = array.indexOf(objeto);
    if (indice !== -1) {
      return setSlider(array.slice(0, indice).concat(array.slice(indice + 1)));
    }
    return array;
  };

  const eliminarObjeto = (index: any) => {
    deleteFromSlide(slider, slider[index]);
  };

  const emptySlider = () => {
    setSlider([]);
  };
  const handleTreeItemClick = (
    event: any,
    url: any,
    cdNode: any,
    item: any
  ) => {
    event.preventDefault();
    setCdNode(cdNode);
    setSelectedUrl(url);
    forceUpdate((prevValue) => !prevValue); // valor para forzar la actualización
    setIndexItems(dataRender.filter((j) => JSON.stringify(j).includes(cdNode)));
    if (item.values) {
      setShowModal(true);
      const slideIndex = slider.findIndex(
        (obj) => JSON.stringify(obj) === JSON.stringify(item)
      );
      if (slideIndex !== -1) {
        setCurrentSlideIndex(slideIndex);
      } else {
        setCurrentSlideIndex(slider.length);
        setSlider([...slider, item]);
      }
    }
  };
  const renderTreeItems = (items: any) => {
    return items.map((item: any) => (
      <CustomTreeItem
        key={item.cdNode}
        nodeId={item.cdNode}
        style={{ fontFamily: "Roboto" }}
        label={item.label}
        isFinal={!Array.isArray(item.items)} // Para ver si es un documento de subcarpetas
        onClick={(event: any) =>
          handleTreeItemClick(event, item.url, item.cdNode, item)
        }
      >
        {Array.isArray(item.items) ? renderTreeItems(item.items) : null}
      </CustomTreeItem>
    ));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <TreeView
        defaultCollapseIcon={<LabelIcon />}
        defaultExpandIcon={<LabelIcon />}
      >
        {renderTreeItems(items)}
      </TreeView>

      {showModal === true ? (
        selectedUrl &&
        cdNode && (
          <Modal
            selectedUrl={selectedUrl}
            indexItems={indexItems}
            label={cdNode}
            slider={slider}
            emptySlider={emptySlider}
            eliminarObjeto={eliminarObjeto}
            handleTreeItemClick={handleTreeItemClick}
          ></Modal>
        )
      ) : (
        <> </>
      )}
    </Box>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens;
  const screenIndex = Object.keys(screens).findIndex((item) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject;
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen;
    return isSameProject && isSameScreen;
  });
  const screen = screens[screenIndex] || {};
  const comp = screen.components[ownProps.info.key] || {};
  const properties = comp.properties || {};
  return {
    properties,
    comp,
    state,
  };
};

export default connect(mapStateToProps)(TreeComponent);
