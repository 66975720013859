import React, { useState, useEffect } from 'react';
import {
  expandOutline
} from 'ionicons/icons';
import { IUnumGridColumnFormat } from '../../../../../utilities/types'
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCheckbox, IonIcon } from '@ionic/react';
import { Modal } from 'react-bootstrap';
import MaskViewer from './MaskViewer'
import grayLogoSRC from '../../../../../assets/grayLogo.png'

const useSyncState = (variable: any) => {
  const [response, setResponse] = useState<any>()
  useEffect(() => {
    setResponse(variable)
  }, [variable])
  return response
}

const DefaultCard = (props: any) => {
  const [data, setData] = useState<any>(props.data)
  const [columns, setColumns] = useState<Array<IUnumGridColumnFormat>>(props.columns || [])
  const [modalIsOpen, setModalIsOpen] = useState<any>(false)
  const [isChecked, setIsChecked] = useState<boolean>(!!props.isChecked)
  const primaryColor = useSyncState(props.primaryColor)
  const secundaryColor = useSyncState(props.secundaryColor)

  const handleSetIsChecked = (val: boolean) => {
    if (val === isChecked) return
    props.onChangeChecked(val)
  }
  useEffect(() => {
    const listener = (ev: any) => {
      ev.detail.register(10, (processNextHandler: any) => {
        if (modalIsOpen) {
          setModalIsOpen(false)
        } else {
          processNextHandler()
        }
      });
    }
    document.addEventListener('ionBackButton', listener);
    return () => {
      document.removeEventListener('ionBackButton', listener)
    }
  }, [modalIsOpen])

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  useEffect(() => {
    setColumns(props.columns)
  }, [props.columns])

  useEffect(() => {
    setIsChecked(props.isChecked)
  }, [props.isChecked])

  const printRow = (mobileConfIndex: any) => {
    const auxPrint = (classAux: any) => {
      return (item: string, index: any) => {
        const colIndex = columns.findIndex((col: IUnumGridColumnFormat) => {
          return col.data === item
        })
        return (<div key={index} className={classAux}>
          <MaskViewer
            key={index}
            data={data[item]}
            mask={columns[colIndex]?.mask || {}}
            title={columns[colIndex]?.title || ''}
          />
        </div>)
      }
    }
    return (
      <span className="w-100 d-flex" style={{justifyContent: 'space-between'}}>
        <span className="mr-auto d-flex">
          {
            Array.isArray(props.mobileconf[mobileConfIndex].lineLeft) ?
              props.mobileconf[mobileConfIndex].lineLeft.map(auxPrint('pr-2')) : null
          }
        </span>
        <span className="ml-auto d-flex">
          {
            Array.isArray(props.mobileconf[mobileConfIndex].lineRight) ?
              props.mobileconf[mobileConfIndex].lineRight.map(auxPrint('pl-2')) : null
          }
        </span>
      </span>
    )
  }
  return (
    <IonCard>
      <IonCardHeader className="d-flex pb-0 position-relative unum-font-size-small">
        <div
          style={{
            top: 0,
            right: 0
          }}
          className="position-absolute"
        >
          <IonCheckbox
            style={{
              opacity: 'unset',
              '--background-checked': primaryColor,
              '--border-color-checked': primaryColor
            }}
            checked={isChecked}
            slot="end"
            disabled
            onClick={() => { handleSetIsChecked(!isChecked) }}
          />
        </div>
        <div onClick={() => { setModalIsOpen(true) }} className="d-flex justify-content-center pr-2">
          <IonIcon icon={expandOutline} size="large" />
        </div>
        <div className="d-flex flex-column w-100" onClick={() => { handleSetIsChecked(!isChecked) }}>
          <span className="font-weight-bold">
            {
              props.mobileconf && props.mobileconf[0] ? (
                printRow(0)
              ) : null
            }
          </span>
          {
            props.mobileconf && props.mobileconf[1] ? (
              printRow(1)
            ) : null
          }
        </div>
      </IonCardHeader>
      <IonCardContent className="unum-font-size-small">
        <div onClick={() => { handleSetIsChecked(!isChecked) }}>
          {
            Array.isArray(props.mobileconf) ? (
              props.mobileconf.map((item: string, index: number) => {
                if (index <= 1) return null
                return (
                  <div key={index}>
                    {
                      printRow(index)
                    }
                  </div>
                )
              })
            ) : null

          }
        </div>
      </IonCardContent>
      <Modal
        show={modalIsOpen}
        onHide={() => { setModalIsOpen(false) }}
      >
        <Modal.Header closeButton>

          <img
            src={grayLogoSRC}
            style={{
              width: '3em',
              height: 'auto',
              zIndex: 1
            }}
            alt="unum"
          />
        </Modal.Header>
        <Modal.Body className="unum-font-size-small">
          <div>
            {
              Array.isArray(columns) ? (
                columns.map((columnFormat: IUnumGridColumnFormat, colIndex: any) => {
                  if (!columnFormat.visible || data[columnFormat.data] === '') return null
                  return (
                    <div key={colIndex} className="d-flex border-bottom py-2">
                      <span
                        style={{
                          maxWidth: '40%'
                        }}
                        className="my-0 mr-auto font-weight-bold">{`${columnFormat.title}${columnFormat.title !== '' ? ':' : ''}`}</span>
                      <span
                        style={{
                          maxWidth: '60%'
                        }}
                        className="ml-auto text-justify"
                      >
                        <MaskViewer
                          data={data[columnFormat.data]}
                          mask={columns[colIndex]?.mask}
                        />
                      </span>
                    </div>
                  )
                })
              ) : null
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <IonButton
            onClick={() => { setModalIsOpen(false) }}
            style={{
              '--background': primaryColor,
              '--color': secundaryColor
            }}
          >
            Cerrar
          </IonButton>
        </Modal.Footer>
      </Modal>
    </IonCard>
  )
};

export default DefaultCard