import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from 'react-redux';

class LoggedRoute extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    
    this.state = {
      isLogged: this.props.state.providerServices.isLogged()
    }
  }

  render() {
    const { component: Component, ...props } = this.props

    return (
      <Route
        {...props}
        render={props => (
          this.state.isLogged ?
            <Component {...props} /> :
            <Redirect to='/login' />
        )}
      />
    )
  }
}

const mapStateToProps = (state:any) => {
  return {
      state
  }
}

export default connect(mapStateToProps)(LoggedRoute);