import {
  IonList,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  /*IonItem,
  IonButton*/
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { connect/*, useDispatch*/ } from 'react-redux';
//import { hideButton } from '../../../../store/unumApp/actions';
import { /*IButton,*/ IScreen, IState, IUnumPanel/*, IUnumSolution*/ } from '../../../../utilities/types';
import UnumComponent from '../UnumComponent'

const Panel = (props: any) => {
  const [properties, setProperties] = useState<IUnumPanel>(props.properties)
  //const [buttons, setButtons] = useState<Array<IButton>>(props.buttons)
  //const dispatch = useDispatch()
  //const [primaryColor, setPrimaryColor] = useState<string>('')
  //const [color, setColor] = useState<string>('')

  /*useEffect(() => {
    const unumSolution: IUnumSolution = props.unumSolution
    setPrimaryColor(unumSolution.cdPrincipalColor || '')
    //setColor(unumSolution.cdSecundaryColor || '#fff')
  }, [props.unumSolution])*/
  
  /*useEffect(() => {
    if(Array.isArray(properties.showbuttons)) {
      properties.showbuttons.forEach(buttonId => {
        dispatch(hideButton(props.cdProject, props.cdScreen, buttonId))
      });
    }
  }, [dispatch, props.cdProject, props.cdScreen, properties.showbuttons])*/

  useEffect(() => {
    setProperties(props.properties)
  }, [props.properties])

  /*useEffect(() => {
    setButtons(props.buttons)
  }, [props.buttons])*/

  if (properties.hidden) {
    return null
  }
  return (
    <IonCard 
      id={properties.key}
      className="mx-0">
      {
        properties.title || properties.label?(
          <IonCardHeader className="bg-transparent">
            <IonCardTitle className="font-weight-bold unum-font-size-normal">
              {properties.title || properties.label}
            </IonCardTitle>
            <hr className="mb-0" />
          </IonCardHeader>
        ):null
      }
      <IonCardContent className="px-0 bg-transparent">
        <IonList className="py-0 bg-transparent">
          {
            Array.isArray(properties.components)?(
            properties.components.map((item: any, index: any) => {
              return (
                <UnumComponent
                  key={index}
                  level={props.level + 1}
                  info={item}
                  cdProject={props.cdProject}
                  cdScreen={props.cdScreen}
                />
              )
            })):null
          }
          {
            /*Array.isArray(properties.showbuttons)?(
              <IonItem>
                {
                  properties.showbuttons.map((buttonId:any) => {
                    if(Array.isArray(buttons)){
                      const i = buttons.findIndex((btn => btn.properties.idButton === buttonId))
                      if (i >= 0) {
                        return (
                          <IonButton 
                            key={buttonId} 
                            style={{ 
                              '--background': primaryColor, 
                              background: primaryColor 
                            }}>
                              { buttons[i].properties.buttonMap.label }
                            </IonButton>
                        )
                      }
                    }
                    return null
                  })
                }
              </IonItem>
            ):null*/
          }
          
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen
    return isSameProject && isSameScreen
  })
  const screen:IScreen = screens[screenIndex] || {}
  const comp = screen.components[ownProps.info.key] || {}
  const properties = comp.properties || {}
  const buttons = screen.buttons
  const solution = state.solutions[state.app.currentSolution]
  return {
    properties,
    buttons,
    unumSolution: solution?.unumSolution || {}
  }
}

export default connect(mapStateToProps)(Panel)

