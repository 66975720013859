

export default {
  theme: {//CONFIGURACIONES GLOBALES
    colors: {
      primary: {
        light: '#95377A',
        main: '#6F2A5B',
        dark: '#4A1C3C',
        contrastText: '#fff'
      },
      secondary: {
        light: '#4BBDA1',
        main: '#3A9B83',
        dark: '#2D7563',
        contrastText: '#fff'
      },
      warning: {
        light: '#C68864',
        main: '#B46C44',
        dark: '#8E5637',
        contrastText: '#fff'
      },
      info: {
        light: '#31949B',
        main: '#266E73',
        dark: '#1A494C',
        contrastText: '#fff'
      },
      success: {
        light: '#319B3C',
        main: '#26732E',
        dark: '#1A4C1F',
        contrastText: '#fff'
      },
    },
    text: {//DICCIONARIO DE ESTILO DE TEXTOS (TÍTULO/CONTENIDO/INPUTS/ETC)
      default: {
        font: 'Arial',
        size: '15px',
        color: '#000'
      },
      header: {
        font: 'Impact, Charcoal, sans-serif',
        size: '35px'
      }
    },
  },
  components: {//CONFIGURACIONES ESPECÍFICAS
    header: {
      backgroundColor: '#6F2A5B',
      color: '#fff',
      textId: 'header'
    },
    menu: {
      backgroundColor: '#6F2A5B',
      color: '#fff'
    },
    inputs: {//CONFIGURACIÓN DE LOS COMPONENTES DE ENTRADA
      default: {//CONFIGURACIÓN POR DEFECTO DE CADA COMPONENTE
        default: {//PROPIEDADES QUE APLICAN PARA TODOS (prioridad más baja)
          debounce: null
        },
        createReport: {
          imgWidth: '300'
        },
        date: {
          autoComplete: 'off',
          inputMode: 'none'
        },
        email: {
          autoComplete: 'email',
          inputMode: 'email'
        },
        number: {
          autoComplete: 'off',
          thousandsSeparator: '.',
          decimalSymbol: ',',
          decimalLimit: 2
        },
        password: {
          autoComplete: 'off',
          inputMode: 'text'
        },
        search: {
          autoComplete: 'on',
          inputMode: 'text'
        },
        tel: {
          autoComplete: 'off',
          inputMode: 'decimal'
        },
        text: {
          labelPosition: 'top',
          debounce: null,
          autoComplete: 'on',
          inputMode: 'text'
        },
        time: {
          autoComplete: 'off',
          inputMode: 'decimal',
          textId: 'header'
        },
        url: {
          autoComplete: 'url',
          inputMode: 'url'
        }
      },
      //COMPONENTES PERSONALIZADOS
      textfield: {//TextFields PERSONALIZADOS
        sigla: {
          inputType: 'text',
          autoComplete: 'off',
          autoCapitalize: 'on',
          validate: {
            pattern: '^[A-Z]*$',
            patternMessage: 'Solo puedes ingresar una sigla',
            maxLength: '20',
            minLength: '0',
            required: true
          },
          debounce: null,
          labelPosition: 'top'
        }
      },
      textarea: {
        default: {
          editor: '',
          labelPosition: 'top'
        }
      },
      number: {
        default: {
          inputType: 'number',
          autoComplete: 'off',
          inputMode: 'text',
          labelPosition: 'top',
          thousandsSeparator: '.',
          decimalSymbol: ',',
          decimalLimit: 2
        }
      },
      datetime: {
        default: {
          format: 'YYYY MM DD',
          labelPosition: 'top'
        }
      },
      checkbox: {
        default: {
          defaultValue: false,
          labelPosition: 'right'
        }
      },
    }
  }
}