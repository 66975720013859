import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  IonItem,
  IonLabel,
  IonNote,
  IonImg,
  IonIcon
} from '@ionic/react';
import {
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css'
import {
  informationCircleOutline
} from 'ionicons/icons';
import { inputChange, executeButton, refreshOthersApply, completeComponentAction } from '../../../../store/unumApp/actions/index'
import { IState, IBasicInputProperties } from '../../../../utilities/types'
import utilities from '../../../../utilities'
import { SHOW_VALIDATION_ERROR_ACTION, SET_FOCUS_ACTION } from '../../../../store/unumApp/utilities/.';
import { getAutonumericParams, getInputMaskParams } from '../../../../store/unumApp/utilities/basicInputUtils';



function getValue(stateValue: any, properties: any) {
  let res = stateValue
  if (properties.type === 'checkbox') {
    if (properties.valueOn === res || res === true) {
      res = true
    } else {
      res = false
    }
  }
  if (res === undefined || res === null) {
    res = ''
  }
  return res
}

const BasicInput = (props: any) => {
  //console.log(props.inputState);
  let dispatch = useDispatch()
  const [properties, setProperties] = useState<IBasicInputProperties>(props.inputState.properties || {})
  const [errorStyle, setErrorStyle] = useState<any>(props.inputState.errorStyle || {})
  const [initialStyle, setInitialStyle] = useState<any>(props.inputState.initialStyle || {})
  const [inputStyle, setInputStyle] = useState<IBasicInputProperties>(props.inputState.inputStyle || {})
  const [style, setStyle] = useState<any>(props.inputState.initialStyle || {})
  let valx: any = getValue(props.inputState.value, props.inputState.properties)
  const [value, setValue] = useState<any>(valx)
  const [message, setMessage] = useState<string>('')
  const [isAppliedMask, setIsAppiledMask] = useState<boolean>(false)
  let inputRef = useRef<any>(null)
  let textareaRef = useRef<any>(null)
  let richTextareaRef = useRef<any>(null)
  let datatimeRef = useRef<any>(null)

  useEffect(() => {
    const actions = props.inputState.actions
    if (actions && actions[SHOW_VALIDATION_ERROR_ACTION] && actions[SHOW_VALIDATION_ERROR_ACTION].active) {
      setStyle(errorStyle)
      setMessage(actions[SHOW_VALIDATION_ERROR_ACTION].data)
      dispatch(completeComponentAction(props.cdProject, props.cdScreen, props.info.key, SHOW_VALIDATION_ERROR_ACTION))
    } else if (actions && actions[SET_FOCUS_ACTION] && actions[SET_FOCUS_ACTION].active) {
      inputRef.current?.setFocus()
      textareaRef.current?.setFocus()
      richTextareaRef.current?.focus()
      datatimeRef.current?.click()
      dispatch(completeComponentAction(props.cdProject, props.cdScreen, props.info.key, SET_FOCUS_ACTION))
    }
  }, [props.inputState.actions, props.info.key, props.cdProject, props.cdScreen,
    dispatch, errorStyle, inputRef, textareaRef, richTextareaRef, datatimeRef])

  useEffect(() => {
    if (props.inputState.inputStyle) {
      setInputStyle(props.inputState.inputStyle)
    }
  }, [props.inputState.inputStyle])

  useEffect(() => {
    if (props.inputState.errorStyle) {
      setErrorStyle(props.inputState.errorStyle)
    }
  }, [props.inputState.errorStyle])

  useEffect(() => {
    if (props.inputState.initialStyle) {
      setInitialStyle(props.inputState.initialStyle)
    }
  }, [props.inputState.initialStyle])

  useEffect(() => {
    setProperties(props.inputState.properties || {})
  }, [props.inputState.properties])

  useEffect(() => {
    let val: any = getValue(props.inputState.value, properties) // QUE VALOR HAY PARA EL CAMPO
    setValue(val)
    if (properties.validateOn === "change" && !props.inputState.isInitial) {
      const validationError = utilities.validate(properties, val)
      if (validationError) {
        setMessage(validationError)
        setStyle(errorStyle)
      } else {
        setMessage('')
        setStyle(initialStyle)
      }
    }
  }, [props.inputState.value, errorStyle, initialStyle, properties, props.inputState.isInitial])

  useEffect(() => {
    // Configuracion de mascara del input
    if ((properties.inputMask || properties.type === 'number') && !isAppliedMask) {
      if (properties.type === 'number') {
        inputRef.current.getInputElement().then((res: any) => {
          new utilities.AutoNumeric(res, '', getAutonumericParams(properties))
        })
      } else {
        utilities.Inputmask(getInputMaskParams(properties)).mask(`#${properties.key}`)
      }
      setIsAppiledMask(true)
    }
  }, [properties, isAppliedMask])

  const handleInputChange = (val: any) => {
    //const isUseInputMask = properties.inputType === 'text' && properties.inputMask
    // Transformaciones de la entrada basicas
    let inputValue = val, isValid = true
    if (properties.inputType === 'text' && val) {
      if (properties.autoCapitalize === 'on') {
        inputValue = val.toString().toUpperCase()
      }
    }
    if (inputValue.toString() === value.toString()) {
      return
    }

    //Validacion
    if (properties.validateOn === "submit" && style === errorStyle) {
      setStyle(initialStyle)
      setMessage('')
      //setShowMessage(false)
    }
    if (properties.validateOn === "change") {
      const validationError = utilities.validate(properties, inputValue)
      if (validationError) {
        setMessage(validationError)
        setStyle(errorStyle)
        isValid = false
      } else {
        setMessage('')
        setStyle(initialStyle)
        isValid = true
        //setShowMessage(false)
      }
    }
    //Seteo de valor
    setValue(inputValue)
    props.dispatch(
      inputChange(
        props.cdProject,
        props.cdScreen,
        properties.key,
        inputValue,
        isValid
      )
    )
    //onTriggerChange
    const onTriggerChange = properties.onTriggerChange || ''
    if (onTriggerChange) {
      props.dispatch(executeButton(
        props.cdProject,
        props.cdScreen,
        onTriggerChange
      ))
    }
  }

  const handleRefreshOthersApply = () => {
    const refreshOthers = properties.refreshOthers || []
    if (refreshOthers.length > 0) {
      props.dispatch(refreshOthersApply(
        props.cdProject,
        props.cdScreen,
        refreshOthers
      ))
    }
  }

  const renderInput = (properties: IBasicInputProperties, style: any, value: any, handleInputChange: any) => {
    switch (properties.type) {
      default:
        const imageStyleContainer = {
          display: 'flex',
          alignItems: 'center',
          border: 'thin solid rgb(179, 179, 179)',
          color: 'rgb(102, 102, 102)',
        }
        return (
          <div
            style={properties.type === 'image' ?
              {
                ...imageStyleContainer,
                ...style
              }
              : {}}
          >
            {
              properties.type === 'image' ?
                (
                  <span>
                    {props.inputState.isInitial || value === '' ? null : properties.prefixuNm}
                  </span>
                ) : null
            }
            {value.length > 0 ? <IonImg
              id={properties.key}
              ref={inputRef}
              style={{
                ...style,
                ...inputStyle,
                color: 'primary',
                backgroundColor: 'primary',
                height: '50vw',
                width: '50vw',
                objectFit: 'contain',
              }} className={`${properties.type === 'image' ? 'border-0' : ''}`}
              src={`data:image/jpeg;base64,${value}`}
              placeholder={properties.placeholder}
              onBlur={() => {
                handleRefreshOthersApply()
              }}

            /> : <></>}

          </div>

        )
    }
  }

  const popover = (
    <Popover id={`popover-${properties.key}`}>
      <Popover.Body>
        {properties.tooltip}
      </Popover.Body>
    </Popover>
  );

  return (
    <IonItem lines="none" className="mb-2">
      {
        !properties.hidden ? (
          <div
            className="d-flex w-100 flex-column justify-content-center"
          >
            <div
              className={`
                  d-flex
                  ${properties.labelPosition === 'left' ? 'justify-content-end' : ''} 
                  ${properties.labelPosition === 'top' ? 'flex-column' : ''} 
                  ${properties.labelPosition === 'bottom' ? 'flex-column' : ''}
                `}
              style={style.text}
            >
              <span
                className={`d-flex
                  ${properties.labelPosition === 'top' ? 'mw-100' : ''} 
                  ${properties.labelPosition === 'left' ? 'pr-2' : ''} 
                  ${properties.labelPosition === 'left-left' ? 'pr-2' : ''} 
                  ${properties.labelPosition === 'left-right' ? 'pr-2' : ''} 
                  ${properties.labelPosition === 'right' ? 'pl-2 order-1' : ''} 
                  ${properties.labelPosition === 'right-right' ? 'pl-2 order-1' : ''}
                  ${properties.labelPosition === 'right-left' ? 'pl-2 order-1' : ''}
                  ${properties.labelPosition === 'bottom' ? 'order-1 mw-100' : ''}
                  `}
              >
                <IonLabel
                  className="d-flex align-items-center white-space-normal w-100"
                  style={style.text}
                >
                  <span className="unum-font-size-normal">{`${properties.label} ${properties.validate?.required ? '*' : ''}`}</span>
                </IonLabel>
                {
                  properties.tooltip ? (
                    <div style={{ zIndex: 10 }} className="px-1 d-flex align-items-center">
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={popover}
                        rootClose={true}
                      >
                        <div className="d-flex align-items-center">
                          <IonIcon icon={informationCircleOutline} size="small" />
                        </div>
                      </OverlayTrigger>
                    </div>
                  ) : null
                }
              </span>
              <div className="unum-font-size-small">
                {
                  renderInput(properties, style.input, value, handleInputChange)
                }
              </div>

            </div>
            {
              !!message ? (
                <IonNote className="unum-font-size-normal">{message}</IonNote>
              ) : null
            }
          </div>
        ) : null
      }
    </IonItem>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen
    return isSameProject && isSameScreen
  })
  const screen = screens[screenIndex] || {}
  const components = screen.components || {}
  const def = {
    properties: {},
    value: '',
    errorStyle: {},
    initialStyle: {},
    isInitial: true,
    isValid: true,
    actions: {},
    inputStyle: {}
  }
  const inputState = components[ownProps.info.key] || {}
  const solution = state.solutions[state.app.currentSolution]
  return {
    inputState: inputState !== undefined ? inputState : def,
    unumSolution: solution?.unumSolution || {}
  }
}

export default connect(mapStateToProps)(BasicInput)