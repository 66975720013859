import { IonInput, IonItem, IonList, IonText } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

const ModalSelect = (props: any) => {
  const [title, setTitle] = useState<any>(props.title)
  const [value, setValue] = useState<any>(props.value)
  const [options, setOptions] = useState<any>(props.options)
  const [search, setSearch] = useState<string>('')
  //const [primaryColor, setPrimaryColor] = useState<any>(undefined)
 
  /*useEffect(() => {
    if (props.primaryColor) {
      setPrimaryColor(props.primaryColor || '')
      //setSecundaryColor(unumSolution.cdSecundaryColor || '#fff')
    }
  }, [props.primaryColor])*/

  useEffect(() => {
    setOptions(props.options)
  }, [props.options])

  useEffect(() => {
    setTitle(props.title)
  }, [props.title])

  const handleHide = () => {
    props.onHide()
  }

  const optionsRender = (
    Array.isArray(options) ? (
      options.filter((opt: any) => {
        if (search !== '') {
          const label = opt.label || ''
          const isVisible = label.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0
          return isVisible
        }
        return true
      }).map((opt: any, optIndex: any) => {
        return (
          <IonItem 
            key={optIndex} 
            onClick={e => {
              setValue(opt.value)
              props.onChange(opt.value)
              handleHide()
            }}
            className={`${value === opt.value?'selectComponentItemFocus':undefined}`}
          >
            <IonText className="unum-font-size-small">{opt.label}</IonText>
          </IonItem>
        )
      })
    ) : []
  )

  return (
    <Modal
      show={true}
      onHide={handleHide}
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex flex-column unum-font-size-normal font-weight-bold">
          <span>{title}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-3">
        <IonList>
          <IonItem lines="none">
            <IonInput
              className="border unum-font-size-small"
              type="text"
              placeholder="Buscar"
              value={search}
              onIonChange={e => {
                setSearch(e.detail.value!)
              }}
            />
          </IonItem>
          { optionsRender }
        </IonList>
      </Modal.Body>
    </Modal>
  )
}

export default ModalSelect