import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IState, IScreen, IUnumViewer, IUnumSolution } from '../../../../../utilities/types'
import { linkOutline } from 'ionicons/icons';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonImg, IonSlide, IonSlides, IonText } from '@ionic/react';
import { Accordion, Card } from 'react-bootstrap';
import CustomToggle from '../../design/tabs/CustomToggle';
import { Capacitor } from '@capacitor/core';
import { FileOpener } from '@ionic-native/file-opener';
import { File } from '@ionic-native/file';
import mimeTypesLibrary from 'mime-types'
import utilities from '../../../../../utilities';
import { Browser } from '@capacitor/browser';


const Viewer = (props: any) => {
  const [properties, setProperties] = useState<IUnumViewer>(props.componentState.properties || {})
  const [data, setData] = useState<Array<any>>(props.componentState.data || [])
  const defaultActiveKey = `${props.info.key}-0`
  const [primaryColor, setPrimaryColor] = useState<string>('')
  //const [secundaryColor, setSecundaryColor] = useState<string>('')


  useEffect(() => {
    const unumSolution: IUnumSolution = props.unumSolution
    setPrimaryColor(unumSolution.cdPrincipalColor || '')
    //setSecundaryColor(unumSolution.cdSecundaryColor || '#fff')
  }, [props.unumSolution])

  useEffect(() => {
    if (Array.isArray(props.componentState.data)) {
      setData(props.componentState.data)
    }
  }, [props.componentState.data])

  useEffect(() => {
    setProperties(props.componentState.properties)
  }, [props.componentState.properties])

  const handleOpenBrowser = async (url: string) => {

    if (Capacitor.getPlatform() === "web") {
      try {
        await Browser.open({ url });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const a = await fetch(url, {
          mode: 'cors'
        })
        const blob = await a.blob()
        const base64 = await utilities.getBase64(blob)
        const mimeType = base64.substring(base64.indexOf(":") + 1, base64.indexOf(";"));
        const extencion = mimeTypesLibrary.extension(mimeType)
        const nameFile = `${Date.now()}.${extencion}`
        await File.writeFile(File.dataDirectory, nameFile, blob, { replace: true })
        await FileOpener.open(File.dataDirectory + nameFile, mimeType)

      } catch (error) {
        try {
          await Browser.open({ url });
        } catch (e) {
          alert(JSON.stringify(e))
        }
        alert(JSON.stringify(error))
      }

    }

  }


  return (
    <IonCard
      id={properties.key}
      className="mx-0">
      <IonCardHeader className="bg-transparent">
        <IonCardTitle className="font-weight-bold">{properties.label}</IonCardTitle>
        <hr className="mb-0" />
      </IonCardHeader>
      <IonCardContent className="px-0 bg-transparent">
        <div className="d-flex flex-column">
          <Accordion
            defaultActiveKey={defaultActiveKey}
          >
            {
              Array.isArray(data) ? (
                data.map((item: any, tabIndex: number) => {
                  return (
                    <Card key={tabIndex}>
                      <Card.Header>
                        <CustomToggle
                          eventKey={`${props.info.key}-${tabIndex.toString()}`}
                        >
                          <IonText className="font-weight-bold unum-font-size-normal">{item.title}</IonText>
                        </CustomToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={`${props.info.key}-${tabIndex.toString()}`}>
                        <Card.Body
                          className="px-0"
                        >
                          <IonSlides
                            style={{
                              '--bullet-background-active': primaryColor
                            }}
                            pager={true}
                            options={{
                              initialSlide: 1,
                              speed: 400
                            }}
                          >
                            {
                              Array.isArray(item.urls) ? (
                                item.urls.map((source: any, sourceIndex: number) => {
                                  return (
                                    <IonSlide
                                      key={sourceIndex}
                                      className="d-flex flex-column pb-5"
                                    >
                                      {
                                        item.type === 'iframe' ? (
                                          <IonButton
                                            style={{
                                              '--background': primaryColor
                                            }}
                                            onClick={() => { handleOpenBrowser(source.url) }}
                                          >
                                            <IonIcon
                                              icon={linkOutline}
                                              className="mr-2"
                                            />
                                            <IonText>{source.title || 'Link'}</IonText>
                                          </IonButton>
                                        ) : item.type === 'image' ? (
                                          <IonImg src={source.url} alt={source.title} />
                                        ) : null
                                      }
                                    </IonSlide>
                                  )
                                })
                              ) : null
                            }
                          </IonSlides>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )
                })
              ) : null
            }
          </Accordion>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen
    return isSameProject && isSameScreen
  })
  const screen: IScreen = screens[screenIndex] || {}
  const components = screen.components || {}
  const solution = state.solutions[state.app.currentSolution]
  return {
    componentState: components[ownProps.info.key] || {},
    unumSolution: solution?.unumSolution || {}
  }
}

export default connect(mapStateToProps)(Viewer)