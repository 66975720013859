import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IState, IScreen, IUnumTimeline } from '../../../../../utilities/types'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import Timeline from 'react-time-line';

const events: Array<any> = [
  {ts: "2017-09-17T12:22:46.587Z", text: 'Logged in'},
  {ts: "2017-09-17T12:21:46.587Z", text: 'Clicked Home Page'},
  {ts: "2017-09-17T12:20:46.587Z", text: 'Edited Profile'},
  {ts: "2017-09-16T12:22:46.587Z", text: 'Registred'},
  {ts: "2017-09-16T12:21:46.587Z", text: 'Clicked Cart'},
  {ts: "2017-09-16T12:20:46.587Z", text: 'Clicked Checkout'},
];

const UnumTimeline = (props: any) => {
  const [properties, setProperties] = useState<IUnumTimeline>(props.componentState.properties || {})

  useEffect(() => {
    setProperties(props.componentState.properties)
  }, [props.componentState.properties])
  
  return (
    <IonCard
      id={properties.key}
      className="mx-0">
      <IonCardHeader className="bg-transparent">
        <IonCardTitle className="font-weight-bold unum-font-size-normal">{properties.title || properties.label}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="px-0 bg-transparent">
        <div className="d-flex flex-column unum-font-size-small">
          <Timeline items={events} />
        </div>
      </IonCardContent>
    </IonCard>
  );
};

const mapStateToProps = (state: IState, ownProps: any) => {
  const screens = state.screens
  const screenIndex: any = Object.keys(screens).findIndex((item: any) => {
    const isSameProject = screens[item].cdProject === ownProps.cdProject
    const isSameScreen = screens[item].cdScreen === ownProps.cdScreen
    return isSameProject && isSameScreen
  })
  const screen: IScreen = screens[screenIndex] || {}
  const components = screen.components || {}
  return {
    componentState: components[ownProps.info.key] || {}
  }
}

export default connect(mapStateToProps)(UnumTimeline)