import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import utilities from '../../../../../utilities';
import { Browser } from '@capacitor/browser';

const gridConf: any = {
  imageSize: {
    xl: '5em',
    lg: '4em',
    md: '3em',
    sm: '2em',
    xs: '1em'
  }
}

const MaskViewer = (props: any) => {
  const [data, setData] = useState<any>(props.data)
  const [mask, setMask] = useState<any>(props.mask)
  const [title, setTitle] = useState<any>(props.title)

  useEffect(() => {
    const newData = utilities.unumMaskApply(props.mask, props.data)
    setData(newData)
    setMask(props.mask)
  }, [props.data, props.mask])

  useEffect(() => {
    setTitle(props.title)
  }, [props.title])

  const handleOpenLink = async (e: React.MouseEvent) => {
    e.stopPropagation()
    await Browser.open({ url: data });
  }

  if (mask.type === 'img') {
    return (
      <img
        src={data}
        style={{
          width: gridConf.imageSize[mask.imgOptions.size]
        }}
        className="h-auto"
        alt={mask.imgOptions?.mimeType}
      />
    )
  } else if(mask.type === 'link') {
    const posfix = mask.icon.split(' ')[1]
    const iconParam = posfix.substring(posfix.indexOf('-') + 1, posfix.length)
    return (
      <FontAwesomeIcon 
        onClick={handleOpenLink}
        icon={iconParam}
        className="text-unum-primary"
      />
    )
  } else if(mask.type === 'check') {
    const d = data === undefined || data === null?'':data
    const valueOn = mask.valueOn === undefined || mask.valueOn === null?'':mask.valueOn
    return (
      <div className="d-flex align-items-center">
        <label className="mr-1 my-0">{title}:</label>
        <input type="checkbox" checked={d.toString() === valueOn.toString() && valueOn !== ''} disabled/>
      </div>
    )
  } else if(mask.type === 'country') {
    const flag = utilities.getAlpha2Country(data)
    return flag ? <img alt={data} src={`https://www.countryflags.io/${flag}/flat/32.png`}/> : data
  }
  return <span dangerouslySetInnerHTML={{
    __html: data
  }}></span>
};

export default MaskViewer