import React, { useEffect, useState } from 'react'
import { IonIcon, IonFab, IonFabButton, IonFabList, IonLabel, IonList, IonItem } from '@ionic/react';
import {
  ellipsisVerticalOutline
} from 'ionicons/icons';
import { IButton, IUnumSolution } from '../../../utilities/types'


const Buttons = (props: any) => {
  //console.log(props);
  const [buttons, setButtons] = useState<Array<IButton>>(props.buttons || [])
  const [primaryColor, setPrimaryColor] = useState<string>('')
  const [activeFloatButtons, setActiveFloatButtons]=useState(false)
  //const [color, setColor] = useState<string>('')

  useEffect(() => {
    const unumSolution: IUnumSolution = props.unumSolution
    setPrimaryColor(unumSolution.cdPrincipalColor || '')
    //setColor(unumSolution.cdSecundaryColor || '#fff')
  }, [props.unumSolution])

  useEffect(() => {
    if (Array.isArray(props.buttons)) {
      setButtons(props.buttons)
    }
  }, [props.buttons])

  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed" activated={activeFloatButtons}
    >

      <IonFabButton 
        style={{
          '--background': primaryColor,
        }}

      >
        <IonIcon
          icon={ellipsisVerticalOutline} 
          style={{
            color: '#fff'
          }}  
        />
      </IonFabButton>
      <IonFabList 
        side="top" 
        className="unumButtonsContainer"
      >
        <IonList
          className="w-100 bg-transparent"
        >
        {
          Array.isArray(buttons)?(
            buttons.map((item: IButton, index: any) => {
              if(!item.isActive) return null
              return (
                <IonItem
                  key={index}
                  style={{
                    '--background': 'transparent'
                  }}
                  onClick={
                    () => {
                      props.onClick(index)
                      setActiveFloatButtons(false)
                    }
                    
                  }
                >
                  <IonLabel
                    style={{
                      color: primaryColor,
                      opacity: 1
                    }}
                    className="unum-font-size-normal font-weight-bold"
                  >{item.properties.buttonMap.label}</IonLabel>
                </IonItem>
              )
            })
          ):null
        }
        </IonList>
      </IonFabList>
    </IonFab>
  )
}

export default Buttons