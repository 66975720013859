import { IScreen, IState } from '../../../../utilities/types';
import { each, get, isBoolean, isNumber, find, trim } from 'lodash'
import { updateComponents } from './generalComponentsActions';

export const updateBasicInputStyle = (cdProject: string, cdScreen: string, componentKey: string, styles: any) => {
  return {
    type: 'UPDATE_BASIC_INPUT_STYLE',
    cdProject,
    cdScreen,
    key: componentKey,
    styles
  }
}

export const formatMapWith = (val: any, cdProject: string, cdScreen: string, inputState: any, properties: any) => {
  return (dispatch: any, getState: any) => {
    const state: IState = getState()
    const screens: { [id: string]: IScreen; } = state.screens
    const screenIndex = Object.keys(screens).findIndex((item) => {
      const isSameProject = screens[item].cdProject === cdProject
      const isSameScreen = screens[item].cdScreen === cdScreen
      return isSameProject && isSameScreen
    })
    const screen = screens[screenIndex]
    const choice = find(inputState.data, { [properties.fieldValue]: val });
    let inputs: any = {}
    each(properties.mapWith, (i) => {
      if (i.keyValue && i.keyProperty && choice) {
        const choiceValue = get(choice, i.keyValue);
        const keyProperty = trim(i.keyProperty);
        const dataValue = isBoolean(choiceValue) || isNumber(choiceValue) ? choiceValue : choiceValue || '';
        if (screen.components[keyProperty]) {
          inputs[i.keyProperty] = {
            ...screen.components[keyProperty],
            value: dataValue,
            isValid: true
          }
        } else {
          inputs[keyProperty] = {
            properties: {
              input: true,
              key: keyProperty
            },
            actions: {},
            value: dataValue,
            isValid: true
          }
        }
      }
    });
    dispatch(updateComponents(cdProject, cdScreen, inputs))
  }
}