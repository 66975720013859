import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonToggle,
  IonIcon,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import grayLogoSRC from "./../assets/grayLogo.png";
import "./PageLogin.scss";

const Page: React.FC = (props: any) => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [isRememberPassword, setRememberPassword] = useState(false);
  const [showAlert, setShowAlert] = useState<any>(false);
  const [alertMessage, setAlertMessage] = useState<any>("");

  useEffect(() => {
    props.state.providerServices
      .getRememberCredentials()
      .then((rememberCredentials: any) => {
        if (rememberCredentials) {
          setUser(rememberCredentials.username);
          setPassword(rememberCredentials.password);
          setRememberPassword(true);
        }
      });
  }, [props.state.providerServices]);

  const handleLogin = async () => {
    await props.state.providerServices.initializeRootAPI();
    /*     props.state.providerServices.login()
     */
    await props.state.providerServices
      .login(user, password, isRememberPassword)
      .catch((error: any) => {
        console.log(error);
        setAlertMessage(error.message);
        setShowAlert(true);
      });
  };

  return (
    <>
      <IonPage className="bg-body">
        <IonContent className="bg-transparent">
          <div className="d-flex flex-column h-100">
            <div className="d-flex justify-content-start px-3">
              <IonIcon color="light" size="large" style={{ zIndex: "1" }} />
            </div>
            <div
              style={{
                width: "100vw",
                height: "15vw",
              }}
              className="d-flex justify-content-center position-relative"
            >
              <div className="unumCircle position-absolute" />
              <div className="d-flex align-items-end pb-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icon/whiteLogo.png"}
                  style={{
                    width: "2em",
                    height: "auto",
                    zIndex: 1,
                  }}
                  alt="unum"
                />
              </div>
            </div>
            <div className="px-5 h-100 d-flex align-items-center">
              <IonList className="bg-transparent initTranspareceLogin">
                <IonItem className="bg-transparent">
                  <IonLabel
                    position="floating"
                    color="primary"
                    className="font-weight-bold"
                  >
                    Usuario
                  </IonLabel>
                  <IonInput
                    type="text"
                    color="primary"
                    value={user}
                    inputMode="text"
                    onIonChange={(e: any) => setUser(e.detail.value)}
                  />
                </IonItem>
                <IonItem className="bg-transparent">
                  <IonLabel
                    position="floating"
                    color="primary"
                    className="font-weight-bold"
                  >
                    Contraseña
                  </IonLabel>
                  <IonInput
                    type="password"
                    value={password}
                    onIonChange={(e: any) => setPassword(e.detail.value)}
                    color="primary"
                  />
                </IonItem>
                <IonItem
                  className="d-flex align-items-center my-4 bg-transparent"
                  lines="none"
                >
                  <IonToggle
                    checked={isRememberPassword}
                    onIonChange={(e) => setRememberPassword(e.detail.checked)}
                  />
                  <IonLabel color="primary">
                    ¿Desea guardar sus credenciales?
                  </IonLabel>
                </IonItem>
                <div className="d-flex justify-content-center">
                  <IonButton
                    onClick={handleLogin}
                    style={{
                      width: "fit-content",
                      minWidth: "50vw",
                    }}
                    className="unumButton h-auto"
                    expand="block"
                  >
                    <span className="py-2 font-size-large">Ingresar</span>
                  </IonButton>
                </div>
              </IonList>
            </div>
          </div>
          <Modal
            show={showAlert}
            onHide={() => {
              setShowAlert(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title className="d-flex align-items-center">
                <div style={{ width: "3em" }}>
                  <img src={grayLogoSRC} className="w-100 h-auto" alt="unum" />
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>{alertMessage}</span>
            </Modal.Body>
            <Modal.Footer>
              <IonButton
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                OK
              </IonButton>
            </Modal.Footer>
          </Modal>
        </IonContent>
      </IonPage>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    state,
  };
};

export default connect(mapStateToProps)(Page);
