import React, { useState, useEffect } from "react";
import { Slides } from "./Slides";
import {
  IonButtons,
  IonModal,
  IonContent,
  IonIcon,
} from "@ionic/react";
import { closeCircle } from "ionicons/icons";

function Modal({
  selectedUrl,
  indexItems,
  label,
  slider,
  emptySlider,
  handleTreeItemClick,
  eliminarObjeto
}) {
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    if (slider.length !== 0) {
      setIsOpen(true);
    }
  }, [handleTreeItemClick, slider]);

  return (
    <IonModal isOpen={isOpen}>
      <IonButtons slot="end">
        <IonIcon
          style={{ fontSize: "1.5rem" }}
          onClick={() => {
            setIsOpen(false);
          }}
          icon={closeCircle}
        >
          Cerrar
        </IonIcon>
      </IonButtons>
      <IonContent>
        <Slides
          indexItems={indexItems}
          selectedUrl={selectedUrl}
          label={label}
          slider={slider}
          emptySlider={emptySlider}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          eliminarObjeto={eliminarObjeto}
        />
      </IonContent>
    </IonModal>
  );
}

export default Modal;
